import React from 'react';
import { AmountIndicator, OrderProductDescription, OrderProductDetails, OrderProductItem, OrderProductListContainer, OrderProductName, OrderProductPrice } from '../styles';
import burger from '@src/assets/images/Mocks/Hamburguer-bag.png'
import { BagProductComplementAmount, BagProductComplementItem, BagProductComplementText } from '../../ShoppingBag/styles';
import { IOrderData } from '@src/interfaces/followOrderInterfaces';
import LoadingSpinner from '@components/LoadingSpinner';
import ImageBackground from "@components/ImageWithPlaceholder"
import { ConvertToBRL } from '@src/utils';
import { IShoppingBag } from '@src/interfaces/shoppingBagInterfaces';

interface IOrderProductListProps {
    products: IOrderData["products"]
}

const OrderProductList: React.FunctionComponent<IOrderProductListProps> = ({ products }) => {

    return (
        <OrderProductListContainer>
            {!products ?
                <LoadingSpinner />
                :
                products.map(product => {

                    const productValue = (product: IShoppingBag["products"][0]) => {

                        if(product.complements?.length === 0 || !product.complements) return ((product?.price || 0) * product.amount)

                        const baseValueProduct = product.ignoreBaseValue ? 0 : product.price || 0
                        const complementsValue = product.complements.reduce((totalComplement: number, complement: any) => {

                            if (complement.totalizer === "MAX") {
                                const optionBiggerMontant = [...complement.options].sort((a, b) => (a.value * a.amount) - (b.value * b.amount)).reverse()[0]
                                return totalComplement + (optionBiggerMontant.value * optionBiggerMontant.amount)
                            }
                
                            if (complement.totalizer === "SUM") {
                
                                return totalComplement + complement.options.reduce((totalOption: number, option: any) => totalOption + (option.value * option.amount), 0)
                            }

                            if(complement.totalizer === "MEAN") {
                                const totalOptions = complement.options.reduce((totalOption: number, option: any) => totalOption + (option.value * option.amount), 0)
                                return totalComplement + (totalOptions / complement.options.length)
                            }
                        }, 0)

                        return baseValueProduct + complementsValue
                    }

                    return (
                        <OrderProductItem key={product.id}>
                            <OrderProductDetails>
                                <ImageBackground
                                    style={{
                                        width: 48,
                                        height: 48,
                                        borderRadius: 10,
                                        marginRight: 20
                                    }}
                                    placeholder={burger}
                                    image={product.picture}
                                />
                                <OrderProductDescription>
                                    <OrderProductName>{product.name}</OrderProductName>
                                    <OrderProductPrice>{ConvertToBRL(productValue(product))}</OrderProductPrice>
                                    {product.complements.map(item => item.options.map((option: any) => {
                                        return (
                                            <BagProductComplementItem>
                                                <BagProductComplementAmount>
                                                    {option.amount}
                                                </BagProductComplementAmount>
                                                <BagProductComplementText>
                                                    {option.name}
                                                </BagProductComplementText>
                                            </BagProductComplementItem>
                                        )
                                    }))}
                                </OrderProductDescription>
                            </OrderProductDetails>
                            <AmountIndicator>x{product.amount}</AmountIndicator>
                        </OrderProductItem>

                    )
                })
            }
        </OrderProductListContainer>
    )
};

export default OrderProductList;
