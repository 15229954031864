import styled from "styled-components/native";
import { IStyledFlex } from "./Views";

interface IButton extends IStyledFlex {


}

export const ButtonOpacity = styled.TouchableOpacity<IStyledFlex>`
  /* flex */
  ${(props: IStyledFlex) => props.flex && `flex: ${props.flex};`}
  flex-wrap: ${(props: IStyledFlex) => props.flexWrap || "nowrap"};
  flex-direction: ${(props: IStyledFlex) => props.flexDirection || "row"};
  ${(props: IStyledFlex) => props.row && "flex-direction: row;"}
  ${(props: IStyledFlex) => props.column && "flex-direction: column;"}

  /* alignments */
  align-self: ${(props: IStyledFlex) => props.alignSelf || "auto"};
  ${(props: IStyledFlex) =>
    props.center && "align-items: center; justify-content: center;"};
  ${(props: IStyledFlex) => props.wrap && "flex-wrap: wrap;"}
  ${(props: IStyledFlex) => props.alignCenter && "align-items: center;"}
  ${(props: IStyledFlex) => props.justifyCenter && "justify-content: center;"}
  ${(props: IStyledFlex) => props.justifyEnd && "justify-content: flex-end;"}
  ${(props: IStyledFlex) =>
    props.justifyBetween && "justify-content: space-between;"}
  ${(props: IStyledFlex) =>
    props.justifyAround && "justify-content: space-around;"}
  ${(props: IStyledFlex) =>
    props.justifyEvenly && "justify-content: space-evenly;"}


  /* margin */
  ${(props: IStyledFlex) => props.m && `margin: ${props.m}px;`}
  ${(props: IStyledFlex) => props.mr && `margin-right: ${props.mr}px;`}
  ${(props: IStyledFlex) => props.ml && `margin-left: ${props.ml}px;`}
  ${(props: IStyledFlex) => props.mt && `margin-top: ${props.mt}px;`}
  ${(props: IStyledFlex) => props.mb && `margin-bottom: ${props.mb}px;`}

  ${(props: IStyledFlex) =>
    props.mx && `margin-right: ${props.mx}px; margin-left: ${props.mx}px;`};
  ${(props: IStyledFlex) =>
    props.my && `margin-top: ${props.my}px; margin-bottom: ${props.my}px;`};

  /* padding */
  ${(props: IStyledFlex) => props.p && `padding: ${props.p}px;`}
  ${(props: IStyledFlex) => props.pl && `padding-left: ${props.pl}px;`};
  ${(props: IStyledFlex) => props.pr && `padding-right: ${props.pr}px;`};
  ${(props: IStyledFlex) => props.pt && `padding-top: ${props.pt}px;`};
  ${(props: IStyledFlex) => props.pb && `padding-bottom: ${props.pb}px;`};

  ${(props: IStyledFlex) =>
    props.px && `padding-right: ${props.px}px; padding-left: ${props.px}px;`};
  ${(props: IStyledFlex) =>
    props.py && `padding-top: ${props.py}px; padding-bottom: ${props.py}px;`};

  /* measures */
  width: ${(props: IStyledFlex) => props.width || "auto"};
  height: ${(props: IStyledFlex) => props.height || "auto"};
  ${(props: IStyledFlex) =>
    props.size && `width: ${props.size}; height: ${props.size};`};

  /* colors */
  background-color: ${(props: IStyledFlex) => props.bg || "transparent"};

  /* borders */
  ${(props: IStyledFlex) => {
    const border = props.bRight?.split(" ");
    return (
      border &&
      `border-right-width: ${border[0]}px; border-right-color: ${border[1]};`
    );
  }}

  ${(props: IStyledFlex) => {
    const border = props.bTop?.split(" ");
    return (
      border &&
      `border-top-width: ${border[0]}; border-top-color: ${border[1]};`
    );
  }}

  ${(props: IStyledFlex) => {
    const border = props.bBottom?.split(" ");
    return (
      border &&
      `border-bottom-width: ${border[0]}; border-bottom-color: ${border[1]};`
    );
  }}

  ${(props: IStyledFlex) => {
    const border = props.bLeft?.split(" ");
    return (
      border &&
      `border-left-width: ${border[0]}; border-left-color: ${border[1]};`
    );
  }}

  ${(props: IStyledFlex) => props.br && `border-radius: ${props.br}px;`}
`;
