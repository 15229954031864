import React, { useContext, useEffect } from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { ShoppingCartContext } from '../../contexts/shoppingCartContext';
import { StoreContext } from '../../contexts/storeContext';

interface IButtonBackForHomeProps {
}

const ButtonBackForHome: React.FunctionComponent<IButtonBackForHomeProps> = (props) => {

    const { storeData } = useContext(StoreContext)
    const navigation = useNavigation<any>()
    const { dispatchShoppingCart } = useContext(ShoppingCartContext)

    useEffect(() => {
        return () => {
            dispatchShoppingCart({ type: "SHOPPING_BAG/CLEAN", data: null })
        }
    }, [])

    const handleBackForHome = () => {
        if(Platform.OS === "web"){
            window.location.href = `/${storeData.storeSlug}`
        }else {
            navigation.replace("home")
        }
    }

    return (
        <TouchableOpacity onPress={handleBackForHome} style={{ marginRight: 50 }}>
            <Ionicons name="arrow-back" size={24} color="black" />
        </TouchableOpacity>
    )
};

export default ButtonBackForHome;
