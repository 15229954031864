import { Entypo } from '@expo/vector-icons';
import { Link } from '@react-navigation/native';
import React, { useCallback, useContext } from 'react';
import ImageWithPlaceholder from '@components/ImageWithPlaceholder';
import {
    OrderTypeTakeOutContainer,
    StoreAdressTakeout,
    StoreInfoTakeOut,
    StoreNameTakeOut,
    TakeOutChangeAddress,
    TakeOutChangeAddressImage,
    TakeOutChangeAddressText
} from '../styles';
import { generalMask } from '@components/FormComponents/InputMask';
import { ShoppingCartContext } from '@contexts/shoppingCartContext';
import { StoreContext } from '@contexts/storeContext';

import Establishment from "@src/assets/images/General/establishment.png"
import SmallIcon from "@src/assets/images/Mocks/small-icon.png"
import { useTheme } from 'styled-components/native';

export interface IOrderType {
    removeOrderType: () => void
    addressError: { hasError: boolean, message: string },
    setAddressError: (error: { hasError: boolean, message: string }) => void
}

const OrderTypeTakeOut: React.FC<IOrderType> = ({ removeOrderType }) => {

    const { storeData } = useContext(StoreContext)
    const { shoppingCart } = useContext(ShoppingCartContext)
    const { colors: { primary } } = useTheme()

    const FormattedStoreAddress = useCallback(() => {
        return (
            `${storeData?.streetAddress || ""}, ${storeData?.numberAddress || ""} - ${storeData?.neighborhoodAddress || ""}, ` +
            `${storeData?.city || ""} - ${storeData?.state || ""}, ${generalMask(storeData.postalCode, '99999-999')}, ${storeData.country || "Brasil"}`
        )
    }, [storeData])

    return (
        <OrderTypeTakeOutContainer>

            <TakeOutChangeAddress
                onPress={removeOrderType}
            >
                <TakeOutChangeAddressImage
                    resizeMode='contain'
                    source={Establishment}
                    style={{ tintColor: primary }}
                />
                <TakeOutChangeAddressText>
                    {
                        shoppingCart.orderType === "TAKEOUT" ?
                            "Retirar no estabelecimento"
                            :
                            "Consumir no local"
                    }

                </TakeOutChangeAddressText>
                <Entypo name="chevron-thin-down" size={16} color={primary} />
            </TakeOutChangeAddress>

            <StoreInfoTakeOut>
                <ImageWithPlaceholder
                    style={{
                        width: 48,
                        height: 48,
                        borderRadius: 10,
                    }}
                    image={storeData.logo}
                    placeholder={SmallIcon}
                />
                <StoreNameTakeOut></StoreNameTakeOut>
                <StoreAdressTakeout>{storeData.city ? FormattedStoreAddress() : "Informações de endereço indisponíveis"}</StoreAdressTakeout>
            </StoreInfoTakeOut>

            {!storeData?.latitude ?
                <></>
                :
                <Link style={{
                    color: 'rgb(5, 52, 89)',
                    fontFamily: 'Inter-SemiBold',
                    fontSize: 14,
                    marginTop: 30
                    //@ts-ignore
                }} to={{ screen: 'establishment-map-screen' }}>
                    Ver no mapa
                </Link>

            }

        </OrderTypeTakeOutContainer>
    )
};

export default OrderTypeTakeOut;
