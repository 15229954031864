import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  ProductDetailsDescriptionText,
  ProductDetailsInfoContainer,
  ProductDetailsNameText,
  ProductDetailsPriceText,
} from './styles';
import placeholder from '@src/assets/images/Mocks/Hamburguer-bag.png'
import ComplementList from './components/ComplementList';
import ProductHandleController from './components/ProductHandleController';
import { useNavigation } from '@react-navigation/native';
import ImageWithPlaceholder from '@components/ImageWithPlaceholder';
import { ShoppingCartContext } from '@contexts/shoppingCartContext';
import { IProductComplementsStore } from '@src/interfaces/shoppingBagInterfaces';
import { StoreContext } from '@contexts/storeContext';
import SafeAreaScrollView from '@components/SafeAreaScrollView';
import { ConvertToBRL } from '@src/utils';
import AddNotes from './components/AddNotes';
import FooterButton from '@components/LargeButton';

const ProductDetails: React.FC = (props: any) => {

  const { storeData } = useContext(StoreContext)
  const { dispatchShoppingCart } = useContext(ShoppingCartContext)

  const { productId, categoryId } = props.route.params
  const navigation = useNavigation()

  const [amount, setAmount] = useState<number>(1)
  const [complementsOnProduct, setComplementsSelected] = useState<Array<IProductComplementsStore>>([])
  const [description, setDescription] = useState<string>('')

  const product = useCallback(() =>
    storeData.category
      // data
      .find(x => x.id === categoryId)?.products
      .find(x => x.id === productId)
    , [productId, categoryId])

  const productValue = useCallback(() => {

    const productBaseValue = product()?.ignoreBaseValue ? 0 : product()?.price || 0;

    const complementsValue = complementsOnProduct.reduce((total, item) => {

      switch (item.complement.totalizer) {
        case "MAX": {
          const expensivestOption = [...item.complement?.options]
            ?.sort((a, b) => (a?.amount * a?.value) - (b?.amount * b?.value))
            ?.reverse()[0] || 0
          return total + (expensivestOption.value * expensivestOption.amount)
        }
        case "SUM": {
          return total + item.complement.options
            .reduce((totalOption, option) => totalOption + (option.value * option.amount), 0)
        }
        case "MEAN": {
          return total + (item.complement.options
            .reduce((totalOption, option) => totalOption + (option.value * option.amount), 0) / item.complement.options.length)
        }
        default: return total
      }
    }, 0)


    return (productBaseValue + complementsValue)
  }, [complementsOnProduct])


  const handleAddProductToBag = () => {
    
    if (amount === 0) return false

    const data = { ...product(), amount, complementsOnProduct: complementsOnProduct.map(item => item.complement), observations: description }

    dispatchShoppingCart({
      type: 'ADD_PRODUCT/PRODUCT_PAGE',
      data: {
        ...product(),
        price: product()?.ignoreBaseValue ? 0 : product()?.price || 0,
        amount,
        complementsOnProduct: complementsOnProduct.map(item => item.complement),
        observations: description
      }
    })

    console.log(data)
    navigation.goBack()
  }

  // useEffect(() => {
  //   console.log(product())
  // }, [product()])

  const checkHaveComplementsMin = () => {

    if (amount === 0) return false

    const check = product()?.complementsOnProduct.map(complement => {
      const index = complementsOnProduct.findIndex(compOnProd => compOnProd.complement.id === complement.id)

      return complement.min === 0 ? true : index === -1 ? false : complementsOnProduct[index]
        .complement.options.reduce((prevOpt, curOpt) => prevOpt + curOpt.amount, 0) >= complement.min
    })

    if (!check) return false

    return check.every(value => value)
  }

  return (
    <>
      <SafeAreaScrollView>
        <ProductDetailsInfoContainer>
          <ImageWithPlaceholder
            style={{
              width: 160,
              height: 160,
              borderRadius: 10,
              marginBottom: 30
            }}
            image={product()?.picture}
            placeholder={placeholder}
          />
          <ProductDetailsNameText>
            {product()?.name}
          </ProductDetailsNameText>
          <ProductDetailsDescriptionText>{product()?.description}</ProductDetailsDescriptionText>
          <ProductDetailsPriceText>{ConvertToBRL(product()?.price || 0)}</ProductDetailsPriceText>
        </ProductDetailsInfoContainer>

        <ComplementList
          complementsOnProduct={complementsOnProduct}
          setComplementsSelected={setComplementsSelected}
          complements={product()?.complementsOnProduct || []}
        />
        <AddNotes setDescription={setDescription} />
      </SafeAreaScrollView>

      <ProductHandleController
        complementsOnProduct={complementsOnProduct}
        amount={amount}
        setAmount={setAmount}
        product={product()}
        productValue={productValue()}
      />

      <FooterButton
        disabled={!checkHaveComplementsMin()}
        onPress={handleAddProductToBag}
        style={{ margin: 0 }}
        title='Adicionar à sacola'
      />
    </>
  )
};

export default ProductDetails;
