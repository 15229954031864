import React, { useContext, useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import {
  AddNewAddressButton,
  AddNewAddressText,
  AddressDescriptionContainer,
  AddressDescriptionText,
  AddressListItem,
  AddressOptionsContainer,
  AddressTitle,
  AddressTypeIcon,
  RemoveAddressButton
} from './styles';
import { Feather, Ionicons } from '@expo/vector-icons';
import { ShoppingCartContext } from '@contexts/shoppingCartContext';
import { StoreContext } from '@contexts/storeContext';
import { UserContext } from '@contexts/userContext';
import { HomeStackParamList } from 'types';
import SafeAreaScrollView from '@components/SafeAreaScrollView';
import SweetAlert from '@components/SweetAlert';
import FooterButton from '@components/LargeButton';
import { IPointsMap } from '../DeliveryAddressMap';
import GeoPoint from 'geopoint';

export interface IAddress {
  id: number
  streetName: string,
  streetNumber: string,
  neighborhood: string,
  city: string,
  state: string,
  reference: string,
  complement: string,
  latitude: number,
  longitude: number
}

const AddressList: React.FC = (props) => {

  const { dispatchShoppingCart, shoppingCart } = useContext(ShoppingCartContext)
  const { storeData, setDeliveryFee } = useContext(StoreContext)
  const { recentAddress, setRecentAddress, refresh } = useContext(UserContext)

  const [selectedAddress, setSelectedAddress] = useState<number>(shoppingCart?.orderInfo?.address?.latitude || 0)
  const [addressError, setAddressError] = useState<{ errorMessage: string, hasError: boolean }>({ hasError: false, errorMessage: '' })

  const navigation = useNavigation<NavigationProp<HomeStackParamList>>()

  const handleDispatchAddress = () => {
    if (!selectedAddress) return

    const dataPut = recentAddress.find(x => x.id === selectedAddress)
    dispatchShoppingCart({
      type: 'ADD_ADDRESS',
      data: dataPut
    })
    dispatchShoppingCart({
      type: 'SELECT_ORDER_TYPE',
      data: "DELIVERY"
    })
    navigation.navigate('shoppingbag')
  }

  const filterAddressWithLongitude = () => {

    if (storeData.deliveryType !== "DISTANCE") return

    const filterAddress = recentAddress.filter(x => x.latitude && x.longitude)
    setRecentAddress(filterAddress)
  }

  const checkDistance = (address: IAddress) => {

    if (!storeData.latitude || !storeData.longitude) return

    const point1 = new GeoPoint(address.latitude, address.longitude)
    const point2 = new GeoPoint(storeData.latitude, storeData.longitude)

    const distance = point1.distanceTo(point2, true)

    const inRange = storeData.distance.filter(x => x.distance >= distance)

    if (!inRange.length) {
      setAddressError({
        hasError: true, errorMessage: `A loja não realiza entregas a essa distância
        \nDistância máxima permitida: ${[...storeData.distance].sort((a, b) => b.distance - a.distance)[0].distance} km
        \nVocê está a ${distance.toFixed(2)} km de distância da loja`
      })
      return
    }

    const fee = inRange.sort((a, b) => a.distance - b.distance)[0]?.value

    setDeliveryFee(fee)
    setAddressError({ hasError: false, errorMessage: '' })
  }

  const checkNeighborhood = (address: IAddress) => {

    if (!storeData.neighborhood) return
    // const availiableNeighboorhood = storeData.neighborhood.filter(x => x.name === address.neighborhood)
    const availiableNeighboorhood = storeData.neighborhood.filter(x => x.name?.toLowerCase() === address.neighborhood?.toLowerCase())

    if (!availiableNeighboorhood.length) {
      setAddressError({
        hasError: true, errorMessage: `A loja não realiza entregas nesse bairro
        \nBairros disponíveis: ${storeData.neighborhood.map(x => x.name).join(', ')}`
      })
      return
    }

    const fee = storeData.neighborhood.find(x => x.name === address.neighborhood)?.value as number

    setDeliveryFee(fee)
    setAddressError({ hasError: false, errorMessage: '' })
  }

  const calculateDeliveryFee = (itemId: number) => {

    setSelectedAddress(itemId)
    setAddressError({ hasError: false, errorMessage: '' })
    const address = recentAddress.find(item => item.id === itemId) as IAddress
    if (!address) return setAddressError({ hasError: true, errorMessage: '' })

    switch (storeData.deliveryType) {
      case "DISTANCE":
        checkDistance(address)
        break;
      case "NEIGHBORHOOD": checkNeighborhood(address)
        break;
      default: break;
    }
  }

  useEffect(() => {
    if (recentAddress.length <= 0) {
      switch (storeData.deliveryType) {
        case "DISTANCE": {
          navigation.navigate("delivery-address-screen")
          break
        }
        default: {
          navigation.navigate('add-new-address', {} as IPointsMap)
        }
      }
    }
  }, [recentAddress])

  useEffect(() => {
    if (storeData.deliveryType === "DISTANCE") {
      filterAddressWithLongitude()
      calculateDeliveryFee(selectedAddress)
    }
  }, [refresh, selectedAddress])

  useEffect(() => {
    calculateDeliveryFee(selectedAddress)
    filterAddressWithLongitude()
  }, [])

  const handleDeleteAddress = async (addressId: number) => {
    const address = recentAddress.filter((x) => x.id !== addressId)
    setRecentAddress(address)
    try {
      AsyncStorage.setItem('recent_address', JSON.stringify(address))
    } catch {

    }
  }

  const handleNavigateToAddressScreen = () => {
    if (storeData.deliveryType === "NEIGHBORHOOD") {
      navigation.navigate('add-new-address', {} as IPointsMap)
    }

    navigation.navigate("delivery-address-screen")
  }

  return (
    <>

      <SafeAreaScrollView>
        {recentAddress.map((item) => {
          const hasStreetNumber = item.streetNumber !== undefined
          const streetNumber = `, ${item.streetNumber}`
          const addressPermitted = storeData.neighborhood
            ?.find(x => x.name.toLocaleLowerCase() === item.neighborhood.toLocaleLowerCase())

          if (storeData.deliveryType === "NEIGHBORHOOD" && !addressPermitted) return null

          return (
            <AddressListItem
              onPress={() => calculateDeliveryFee(item.id)}
              key={item.id}
              selected={selectedAddress === item.id}
            >
              <AddressTypeIcon>
                <Ionicons name="ios-location-outline" size={24} color="black" />
              </AddressTypeIcon>
              <AddressDescriptionContainer>
                <AddressTitle>
                  {`${item.streetName}${hasStreetNumber ? streetNumber : ''}`}
                </AddressTitle>
                <AddressDescriptionText>{item.neighborhood}</AddressDescriptionText>
                <AddressDescriptionText>{`${item.city} | ${item.state}`}</AddressDescriptionText>
                {(item?.complement?.length || ''.length) > 0 &&
                  <AddressDescriptionText>{item?.complement}</AddressDescriptionText>
                }
              </AddressDescriptionContainer>
              <AddressOptionsContainer>
                <RemoveAddressButton>
                  <SweetAlert
                    title='Você quer apagar esse endereço?'
                    text='Isso irá remover permanentemente o endereço da sua lista de endereços salvos'
                    icon="question"
                    buttons={
                      [
                        {
                          text: "Cancelar",
                          type: 'cancel',
                          onPress: () => 0
                        },
                        {
                          text: "Apagar",
                          type: 'confirm',
                          onPress: () => handleDeleteAddress(item.id)
                        }
                      ]
                    }>
                    <Feather name="trash-2" size={24} color="black" />
                  </SweetAlert>
                </RemoveAddressButton>
              </AddressOptionsContainer>
            </AddressListItem>
          )
        }
        )}

        <AddNewAddressButton
          onPress={handleNavigateToAddressScreen}
        >
          <AddNewAddressText>
            Adicionar endereço
          </AddNewAddressText>
        </AddNewAddressButton>

      </SafeAreaScrollView>


      <FooterButton
        disabled={!recentAddress || !selectedAddress || addressError.hasError}
        errorMessage={addressError.errorMessage}
        onPress={handleDispatchAddress}
        title='Usar esse endereço'
      />
    </>
  )
};

export default AddressList;
