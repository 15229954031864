import produce from "immer";
import React, { createContext, Reducer, useCallback, useEffect, useReducer } from "react";
import { IShoppingBag } from "../../interfaces/shoppingBagInterfaces";
import AsyncStorage from "@react-native-async-storage/async-storage";

interface IShoppingCartContext {
    dispatchShoppingCart: (props: { type: any, data: any }) => any,
    shoppingCart: IShoppingBag,
    totalValue: number,
}

const initialValue = {
    products: [],
    orderInfo: {
        deliveredBy: 'STORE',
        address: {},
        mode: "DEFAULT"
    },
    orderType: "",
    payments: [],
    salesChannel: "UPANDA",
    orderTiming: "IMMEDIATE",
    customerName: '',
    customerPhone: ''
}

const ShoppingCartContext = createContext({} as IShoppingCartContext)

const reducer: any = (state: IShoppingBag, action: any) => {

    return produce(state, (draft: IShoppingBag) => {

        switch (action.type) {

            case 'ADD_PRODUCT/PRODUCT_PAGE': {
                draft.products.push(action.data)
                break
            }

            case "INCREMENT_PRODUCT": {
                const product = draft.products.find(x => x.id === action.data.id)

                if (!product) {
                    draft.products.push({ ...action.data, amount: 1 })
                    return

                }

                product.amount = product.amount + 1

                break
            }
            case "DECREMENT_PRODUCT": {
                const product = draft.products.find(x => x.id === action.data.id)

                if (!product) return

                product.amount = product.amount - 1

                if (product.amount === 0) {
                    draft.products = draft.products.filter(x => x.id !== action.data.id)
                }

                break
            }
            case "SHOPPING_BAG/REMOVE_PRODUCT": {
                draft.products = draft.products.filter(x => x.id !== action.data)
                break
            }
            case "SHOPPING_BAG/CLEAN": {
                draft.products = []
                draft.orderType = "" as any
                break
            }
            case "ADD_ADDRESS": {
                draft.orderInfo.address = action.data
                break
            }
            case "SELECT_ORDER_TYPE": {
                draft.orderType = action.data
                break
            }
            case "SET_ORDER_INFO": {
                draft.orderInfo = action.data
            }
            case "ADD_CONTACT_DATA": {
                draft.customerPhone = action.data.phone
                draft.customerName = action.data.name
                break
            }
            case "SELECT_PAYMENT_METHOD": {
                draft.payments = [action.data]
                break
            }

        }
    })

}

const ShoppingCartProvider: React.FC = ({ children }) => {

    const [shoppingCart, dispatchShoppingCart] = useReducer<Reducer<any, any>>(reducer, initialValue)

    const getInitialShoppingCar = async () => {
        try {
            const data = await AsyncStorage.getItem('recent_address')
            if (!data) return
            dispatchShoppingCart({ type: "ADD_ADDRESS", data: JSON.parse(data)[0] })
        } catch {

        }
    }

    const totalValue = shoppingCart.products.reduce((total: number, product: any) => {

        if (product.complementsOnProduct.length === 0) return total + (product.price * product.amount)

        const baseValueProduct = product.ignoreBaseValue ? 0 : product.price
        const complementsValue = product.complementsOnProduct.reduce((totalComplement: number, complement: any) => {
            switch (complement.totalizer) {
                case "MAX":
                    const optionBiggerMontant = [...complement.options].sort((a, b) => (a.value * a.amount) - (b.value * b.amount)).reverse()[0]
                    return totalComplement + (optionBiggerMontant.value * optionBiggerMontant.amount)
                case "SUM":
                    return totalComplement + complement.options.reduce((totalOption: number, option: any) => totalOption + (option.value * option.amount), 0)
                case "MEAN":
                    const totalOptions = complement.options.reduce((totalOption: number, option: any) => totalOption + (option.value * option.amount), 0)
                    return totalComplement + (totalOptions / complement.options.length)
                default:
                    return totalComplement
            }

        }, 0)

        return total + ((baseValueProduct + complementsValue) * product.amount)
    }, 0)

    useEffect(() => {
        getInitialShoppingCar()
    }, [])

    return (
        <ShoppingCartContext.Provider
            value={{
                dispatchShoppingCart,
                shoppingCart,
                totalValue,
            }}
        >
            {children}
        </ShoppingCartContext.Provider>
    )
}

export { ShoppingCartContext, ShoppingCartProvider }