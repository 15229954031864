import FooterButton from "@components/LargeButton";
import { ShoppingCartContext } from "@contexts/shoppingCartContext";
import React, { Dispatch, SetStateAction, useContext } from "react";
import { StyleSheet, View } from "react-native";
import { TextInput } from "react-native";

interface ITableModalProps {
    setIsVisible: Dispatch<SetStateAction<boolean>>
}

const TableModal: React.FC<ITableModalProps> = ({ setIsVisible }) => {

    const { dispatchShoppingCart } = useContext(ShoppingCartContext)
    const [table, setTable] = React.useState<string | null>(null);

    const handleSelectTable = () => {
        dispatchShoppingCart({
            type: 'SET_ORDER_INFO',
            data: {
                table: table || undefined,
            }
        })

        dispatchShoppingCart({
            type: 'SELECT_ORDER_TYPE',
            data: "EATIN"
        })

        setIsVisible(false)
    }

    return (
        <View>
            <TextInput
                placeholder="(Opcional)"
                onChangeText={(text) => setTable(text)}
                style={styles.input}
            />

            <FooterButton
                title="Confirmar"
                onPress={handleSelectTable}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    input: {
        borderWidth: 1,
        borderRadius: 5,
        borderColor: "rgb(206, 206, 206)",
        padding: 10,
        marginTop: 20
    }
})

export default TableModal