import React, { useContext, useEffect, useRef, useState } from "react";
import { FlatList, Text } from "react-native";
import StoreSimple from "./components/StoreSimple";
import MarketPlaceHeader from "./components/MarketPlaceHeader";
import { MarketplaceContext } from "@contexts/marketPlaceContext";
import LoadingSpinner from "@components/LoadingSpinner";
import { Flex } from "@src/styles";

export const MarketPlace: React.FC = () => {
  const { filteredData } = useContext(MarketplaceContext);
  const [loading, setLoading] = useState<boolean>(true);
  const timeOut = useRef<any>();

  const renderEmptyComponent = () => {
    if (loading) return <LoadingSpinner />;

    return <Text style={{ alignSelf: "center" }}>Nenhuma loja encontrada</Text>;
  };

  const renderItem = ({ item }: any) => {
    return <StoreSimple item={item} />;
  };

  useEffect(() => {
    clearTimeout(timeOut.current);
    setLoading(true);
    timeOut.current = setTimeout(() => {
      setLoading(false);
    }, 400);
  }, [filteredData]);

  return (
    <Flex flex={1} column>
      <MarketPlaceHeader />
      <FlatList
        ListEmptyComponent={renderEmptyComponent}
        data={filteredData}
        renderItem={renderItem}
        style={{ flex: 1, height: "100%", backgroundColor: "white" }}
        showsVerticalScrollIndicator={false}
      />
      {/* <BagProduct target={'home'} /> */}
    </Flex>
  );
};
