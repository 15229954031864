import { AntDesign } from '@expo/vector-icons';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { ButtonCloseModal, CenteredViewModal, ModalBackGround, ModalBody, ModalContainer, ModalHeader, ModalTitle, ModalView } from './styles';

interface IModalProps {
    title: string,
    component: JSX.Element
    onPress?: () => void
    style?: any
    isCancelable?: boolean
    setIsVisible?: Dispatch<SetStateAction<boolean>>
    isVisible?: boolean
    modalStyle?: any
    onClose?: () => void
}

const Modal: React.FunctionComponent<IModalProps> = ({ children, component, ...props }) => {

    const [visible, setVisible] = useState<boolean>(false)

    const open = () => {
        setVisible(true)
        if (props.setIsVisible)
            props.setIsVisible(true)
    }

    const close = () => {
        setVisible(false)
        if (props.onClose) {
            props.onClose()
        }
        if (props.setIsVisible) {
            props.setIsVisible(false)
        }
    }

    const onPress = () => {
        if (props.onPress) {
            props.onPress()
        }

        open()
    }

    return (
        <>
            <TouchableOpacity style={props?.style || {}} onPress={onPress}>
                {children}
            </TouchableOpacity>

            <ModalView
                visible={props.isVisible !== undefined ? props.isVisible : visible}
                transparent
                animationType='fade'
            >
                <CenteredViewModal>
                    <ModalBackGround onPress={close} />
                    <ModalContainer style={props?.modalStyle || {}}>
                        <ModalHeader>
                            <ModalTitle>{props.title}</ModalTitle>
                            <ButtonCloseModal onPress={close}>
                                <AntDesign name='close' color='#000' size={20} />
                            </ButtonCloseModal>
                        </ModalHeader>
                        <ModalBody>
                            {component}
                        </ModalBody>
                    </ModalContainer>
                </CenteredViewModal>
            </ModalView>

        </>
    )
};

export default Modal;
