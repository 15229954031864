import React from 'react';
import { StyleProp, ViewProps, ViewStyle } from 'react-native';
import { ScrollContainer, ScrollView } from './styles';

export interface ISafeAreaScrollViewProps {
    backgroundColor?: string;
    contentContainerStyles?: any;
    style?: ViewStyle;
}

const SafeAreaScrollView: React.FunctionComponent<ISafeAreaScrollViewProps> = ({ children, ...props }) => {
    return (
        <ScrollContainer>
            <ScrollView
                showsVerticalScrollIndicator={false}
                {...props}
            >
                {children}
            </ScrollView>
        </ScrollContainer>
    )
};

export default SafeAreaScrollView;
