import { IMarketplaceContext, MarketplaceContext } from "@contexts/marketPlaceContext";
import { AntDesign } from "@expo/vector-icons";
import { Flex } from "@src/styles";
import React, { useContext, useEffect, useState } from "react";
import { FlatList } from "react-native";
import StoreSimple from "../Marketplace/components/StoreSimple";
import {
  CleanSearchText,
  InputSearchStore,
  CleanSearchButton,
  SubTitleSearchScreen,
  SearchList,
} from "./styles";

const SearchStoreScreen: React.FC = () => {
  const [search, setSearch] = useState<string>("");
  const { filteredData } = useContext(MarketplaceContext);
  const [exampleData, setExampleData] = useState<
    IMarketplaceContext["filteredData"]
  >([]);

  useEffect(() => {
    setExampleData(filteredData);
  }, []);

  useEffect(() => {
    if (!filteredData?.length) return;
    if (!search) setExampleData(filteredData);
    const newFilteredData = filteredData.filter((storeItem) => {
      return !storeItem.storeName.toUpperCase().indexOf(search.toUpperCase());
    });
    setExampleData(newFilteredData);
  }, [search]);

  return (
    <Flex column flex={1} bg="#fff">
      <Flex bg="#f1f1f1" alignCenter br={4} justifyBetween m={20}>
        <Flex row width="80%" alignCenter py={10} px={20}>
          <AntDesign name="search1" color="#F85628" size={16} />
          <InputSearchStore
            value={search}
            onChangeText={(e) => setSearch(e)}
            placeholder="Pesquise por lojas"
          />
        </Flex>
        <CleanSearchButton onPress={() => setSearch("")}>
          <CleanSearchText active={search.length >= 1}>Limpar</CleanSearchText>
        </CleanSearchButton>
      </Flex>
      {search.length >= 3 ? (
        <>
          <SubTitleSearchScreen>Você procura por</SubTitleSearchScreen>
          <FlatList
            data={exampleData}
            renderItem={({ item }) => {
              return <StoreSimple item={item} />;
            }}
          />
        </>
      ) : (
        <>
          <SubTitleSearchScreen>Pesquisar recentes</SubTitleSearchScreen>
        </>
      )}
    </Flex>
  );
};

export default SearchStoreScreen;
