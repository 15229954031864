
import React, { useRef } from 'react'
import convertToMoneyString from '../../utils'
import InputTextFormik from './InputTextFormik'

interface IProps {
    name: string
    placeholder?: string
    onChange?: any;
    keyboardType: string;
}

function formatMoney(e: any) {
    return convertToMoneyString(e.target.value)
}

function convertStringMoneyToNumber(value: any) {
    let v = String(value)
    if (v.length === 0) return ''
    if (v.length >= 3) {
        //only numbers
        v = v.replace(/[^0-9]/g, '')
        //add a in the end
        v = v.slice(0, v.length - 2) + '.' + v.slice(v.length - 2)
        return parseFloat(v)
    }
    return parseFloat(v)


}

function stringToMoney(value: any) {
    if (value.length >= 3) {
        //only numbers
        value = value.replace(/[^0-9]/g, '')
        //add a in the end
        value = value.slice(0, value.length - 2) + '.' + value.slice(value.length - 2)
        return convertToMoneyString(parseFloat(value).toFixed(2))
    }

    return convertToMoneyString(value)
}



const InputMoney: React.FC<IProps> = ({ name, placeholder, keyboardType, ...props }) => {

    const money = useRef<any>()

    return (
        <InputTextFormik
            placeholder={placeholder}
            name={name}
            inputFunction={(e: any) => stringToMoney(money.current || parseFloat(e).toFixed(2))}
            keyboardType={keyboardType}
            // inputFunction={(e)=>{
            //     setValue(e.target.value)
            //     console.log('oidoasi',e.target.value)
            // }}
            outputFunction={(e: any) => {
                money.current = e

                money.current = money.current.replace(/[^0-9]/g, '')

                return convertStringMoneyToNumber(money.current)
            }}
        // {...props}
        />
    )
}

export default InputMoney