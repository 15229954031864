import { api } from "../config/api"

const orderService = {}

orderService.handleOrder = async (id, data) => {
    try {
        const result = (await api.post(`/orders/${id}`, data)).data
        return result    
    } catch (err) {

    }
}

orderService.getOrderData = async (storeId, orderId) => {
    try {
        const result = (await api.get(`/orders/${storeId}/order/${orderId}/`)).data
        return result    
    } catch (err) {

    }
}

orderService.getUserOrders = async (storeId, clientPhone) => {
    try {
        const result = (await api.get(`/orders/${storeId}/order/client/${clientPhone}/`)).data
        return result
    } catch (err) {

    }
}

export default orderService