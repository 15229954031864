import LoadingSpinner from '@components/LoadingSpinner';
import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import MapViewWeb from 'react-native-web-maps'

interface IMapComponentWebProps {
    initialPosition: Array<number>
    loading: boolean
}

const MapComponentWeb: React.FC<IMapComponentWebProps> = ({ initialPosition, children, loading, ...props }) => {

    if (loading) return null

    return (
        <MapViewWeb
            style={styles.map}
            loadingEnabled={initialPosition[0] === 0}
            initialRegion={{
                latitude: initialPosition[0],
                longitude: initialPosition[1],
                latitudeDelta: 0.014,
                longitudeDelta: 0.014,
            }}
            {...props}
        >
            {children}
        </MapViewWeb>
    )
};

export default MapComponentWeb;

const styles = StyleSheet.create({
    map: {
        width: '100%',
        height: '100%'
    }
})