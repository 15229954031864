import React from 'react';
import MapViewWeb from 'react-native-web-maps'
import PMarker from '../../../assets/images/others/pin-marker.png'
import { IMarkerProps } from '../Marker';

const MapMarkerWeb: React.FC<IMarkerProps> = ({ ...props }) => {

    return (
        <MapViewWeb.Marker
            icon={PMarker}
            coordinate={{
                latitude: props.latitude || -5.5175959135530945,
                longitude: props.longitude || -47.468954418945316
            }}
            {...props}
        >

        </MapViewWeb.Marker>
    )
};

export default MapMarkerWeb;
