import { Formik } from 'formik';
import React, { useContext, useRef } from 'react';
import { FormGroup, FormTitle } from '../AddNewAddress/styles';
import * as yup from 'yup'
import { View } from 'react-native';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ShoppingCartContext } from '@contexts/shoppingCartContext';
import { UserContext } from '@contexts/userContext';
import { HomeStackParamList } from 'types';
import SafeAreaScrollView from '@components/SafeAreaScrollView';
import InputTextFormik from '@components/FormComponents/InputTextFormik';
import Feedback from '@components/FormComponents/Feedback';
import InputMask, { removeSpecialCharacters } from '@components/FormComponents/InputMask';
import FooterButton from '@components/LargeButton';

interface IAddContactData {
    name: string,
    phone: string
}

const validationSchema = yup.object().shape({
    name: yup.string()
        .required('O nome é obrigatório')
        .min(6, 'Informe seu nome completo'),
    phone: yup.string()
        .required('Telefone para contato é obrigatório')
        .min(11, 'Informe um número de telefone válido')
})

const AddContactData: React.FC = () => {

    const { dispatchShoppingCart } = useContext(ShoppingCartContext)
    const { userName, phone } = useContext(UserContext)

    const formRef = useRef<any>()
    const navigation = useNavigation<NavigationProp<HomeStackParamList>>()

    const SelectPaymentMethod = async (data: IAddContactData) => {
        console.log("phone", removeSpecialCharacters(data.phone))

        await AsyncStorage.setItem('USER_DATA', JSON.stringify(data))
        dispatchShoppingCart({
            type: 'ADD_CONTACT_DATA',
            data: { name: data.name, phone: data.phone }
        })
        navigation.navigate('payment-method')
    }

    return (
        <View style={{ display: 'flex', flex: 1, backgroundColor: '#fff' }}>

            <SafeAreaScrollView
                style={{ padding: 20 }}
            >
                <Formik
                    initialValues={{
                        name: userName || '',
                        phone: phone || ''
                    }}
                    onSubmit={SelectPaymentMethod}
                    validationSchema={validationSchema}
                    innerRef={formRef}
                >
                    {({
                        errors,
                        touched,
                    }: any) => {
                        return (
                            <>
                                <FormGroup invalid={errors.name && touched.name}>
                                    <FormTitle>Qual o seu nome?</FormTitle>
                                    <InputTextFormik
                                        name='name'
                                        placeholder='EX: Ana Maria Silva'
                                    />
                                </FormGroup>
                                <Feedback name='name' />
                                <FormGroup invalid={errors.phone && touched.phone}>
                                    <FormTitle>Telefone para contato</FormTitle>
                                    <InputMask
                                        mask='(99) 99999-9999'
                                        name='phone'
                                        placeholder='(00) 90000-0000'
                                        keyboardType='numeric'
                                    />
                                </FormGroup>
                                <Feedback name='phone' />
                            </>
                        )
                    }}
                </Formik>
            </SafeAreaScrollView >

            <FooterButton
                title='Escolher forma de pagamento'
                onPress={() => formRef.current.submitForm()}
            />
        </View>

    )
};

export default AddContactData;
