import styled from "styled-components/native";
import { ISafeAreaScrollViewProps } from ".";

export const ScrollContainer = styled.SafeAreaView`
    display: flex;
    flex: 1;
`

export const ScrollView = styled.ScrollView<ISafeAreaScrollViewProps>`
    display: flex;
    flex: 1;
    background-color: ${props => props.backgroundColor || 'white'}
`