import React, { Dispatch, SetStateAction, useRef } from 'react';
import { AddNoteInput, ComplementItemContainer, ComplementTitleText } from '../styles';

const AddNotes: React.FC<{ setDescription: Dispatch<SetStateAction<string>> }> = ({ setDescription }) => {

  const timer = useRef<any>()

  const handleChangeDescription = (e: string) => {
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      setDescription(e)
    }, 300)
  }


  return (
    <ComplementItemContainer style={{ marginBottom: 50 }}>
      <ComplementTitleText>Alguma observação?</ComplementTitleText>
      <AddNoteInput
        onChangeText={(e) => handleChangeDescription(e)}
        placeholder='Adicione um comentário para o restaurante'
      />
    </ComplementItemContainer>

  )
};

export default AddNotes;
