import React, { createContext, useCallback, useEffect, useState } from "react";
import AsyncStorageLib from "@react-native-async-storage/async-storage";
import { IAddress } from "@src/screens/Home/AddressList";

interface IUserContext {
    recentAddress: Array<IAddress>;
    setRecentAddress: (address: Array<IAddress>) => void;
    userName?: string;
    setUserName: (name: string) => void;
    phone?: string;
    setPhone: (phone: string) => void;
    setRefresh: (refresh: boolean) => void;
    refresh: boolean;
}

const UserContext = createContext<IUserContext>({} as IUserContext);

const UserProvider: React.FC = ({ children }) => {

    const [recentAddress, setRecentAddress] = useState<Array<IAddress>>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [userName, setUserName] = useState<string>("");
    const [phone, setPhone] = useState<string>("");

    const handleGetRecentAddress = useCallback(async () => {
        try {
            const data = await AsyncStorageLib.getItem('recent_address')
            if (data === null) throw {}
            setRecentAddress(JSON.parse(data))
        } catch (e) {
            //Modal Alert
        }
    }, [refresh])

    const handleGetUserContactData = useCallback(async () => {
        try {
            const data = await AsyncStorageLib.getItem('USER_DATA')
            if (data === null) throw {}
            const { name, phone } = JSON.parse(data)
            setUserName(name)
            setPhone(phone)
        } catch (e) {
            //Modal Alert
        }
    }, [refresh])

    useEffect(() => {
        handleGetRecentAddress()
        handleGetUserContactData()
    }, [refresh])

    return (
        <UserContext.Provider
            value={{
                recentAddress,
                setRecentAddress,
                setRefresh,
                userName,
                setUserName,
                phone,
                setPhone,
                refresh
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

export { UserContext, UserProvider }