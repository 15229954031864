import { IOrderData } from '@src/interfaces/followOrderInterfaces';
import { ConvertToBRL } from '@src/utils';
import React from 'react';
import { PaymentPriceRow, PaymentPricesContainer, PaymentPriceTypeText, SubPricesText, TotalPriceText } from '../styles';

interface IPaymentPricesProps {
  total: IOrderData["total"]
}

const PaymentPrices: React.FC<IPaymentPricesProps> = ({ total }) => {
  return (
    <PaymentPricesContainer>
      <PaymentPriceRow>
        <PaymentPriceTypeText>SubTotal</PaymentPriceTypeText>
        <SubPricesText>{ConvertToBRL(total?.subTotal || 0)}</SubPricesText>
      </PaymentPriceRow>
      <PaymentPriceRow>
        <PaymentPriceTypeText>Entrega</PaymentPriceTypeText>
        <SubPricesText>{ConvertToBRL(total?.deliveryFee || 0)}</SubPricesText>
      </PaymentPriceRow>
      <PaymentPriceRow>
        <PaymentPriceTypeText>Total</PaymentPriceTypeText>
        <TotalPriceText>{ConvertToBRL(total?.orderAmount || 0)}</TotalPriceText>
      </PaymentPriceRow>
    </PaymentPricesContainer>

  )
};

export default PaymentPrices;
