import axios from "axios";
import { API_BASE_URL } from "../constants/Constants";
import { handleToast } from "../contexts/toastContext";

export const api = axios.create({
    baseURL: API_BASE_URL
})

api.interceptors.response.use((response) => response,
    (error) => {

        const notificationParam: {
            message: string,
            hasMessage?: boolean,
        } = {
            message: '',
        }


        switch (error?.response?.status) {
            case 400: {
                notificationParam.message = 'Falha ao executar a operação'
                notificationParam.hasMessage = true
                break
            }

            case 401: {
                notificationParam.message = 'Falha na autenticação'
                notificationParam.hasMessage = true
                break
            }

            case 404: {
                notificationParam.message = 'Não encontrado'
                notificationParam.hasMessage = true
                break
            }

            case 500: {
                notificationParam.message = 'Erro interno do servidor'
                notificationParam.hasMessage = true
                break
            }

            case 508: {
                notificationParam.message = 'Tempo esgotado'
                notificationParam.hasMessage = true
                break
            }

            default: {
                notificationParam.message = 'Falha na conexão'
                notificationParam.hasMessage = true
            }
        }

        if (notificationParam?.hasMessage) {
            handleToast(error?.response?.data?.message || notificationParam.message, {
                type: "danger",
                style: {
                    width: "100%",
                    justifyContent: "center",
                    marginBottom: 200
                }
            })
        }

        return Promise.reject(notificationParam)

    });

export default api