import * as React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { ToastProvider } from "react-native-toast-notifications";
import { MarkeplaceProvider } from "./marketPlaceContext";
import { ShoppingCartProvider } from "./shoppingCartContext";
import { UserProvider } from "./userContext";

interface IContextProviderProps { }

const ContextProvider: React.FunctionComponent<IContextProviderProps> = ({
  children,
  ...props
}) => {
  return (
    <SafeAreaProvider>
      <UserProvider>
        <MarkeplaceProvider>
          <ShoppingCartProvider>
            <ToastProvider>
              {children}
            </ToastProvider>
          </ShoppingCartProvider>
        </MarkeplaceProvider>
      </UserProvider>
    </SafeAreaProvider>

  );
};

export default ContextProvider;
