import React, { useState } from 'react';
import { Platform } from 'react-native';
import MapComponentNative from './components/MapComponentNative';
import MapComponentWeb from './components/MapComponentWeb';
import { MapViewContainer } from './styles';

export interface IMapComponent {
    onRegionChangeComplete?: (e: { latitude: number, longitude: number }) => void
    initialPosition: Array<number>
    loading: boolean
}

const MapComponent: React.FC<IMapComponent> = ({ children, initialPosition, ...props }) => {

    return (
        <MapViewContainer>
            {Platform.OS !== 'web' ?
                <MapComponentNative
                    initialPosition={initialPosition}
                    {...props}
                >
                    {children}
                </MapComponentNative>
                :
                <MapComponentWeb
                    initialPosition={initialPosition}
                    {...props}
                >
                    {children}
                </MapComponentWeb>
            }
        </MapViewContainer>
    )
};

export default MapComponent;

