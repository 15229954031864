import ImageWithPlaceholder from "@components/ImageWithPlaceholder"
import styled from "styled-components/native"

export const ProductDetailsInfoContainer = styled.View`
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    align-items: center;
`

export const ProductDetailsNameText = styled.Text`
    font-size: 16px;
    color: #3D4347;
    margin: 10px;
    font-family: 'Inter-SemiBold';
`

export const ProductDetailsDescriptionText = styled.Text`
    font-size: 12px;
    color: #000000;
    font-family: 'Inter-Regular';
    text-align: center;
`

export const ProductDetailsPriceText = styled.Text`
    font-size: 16px;
    color: #053459;
    margin: 15px;
    font-family: 'Inter-SemiBold';

`

export const ComplementListContainer = styled.View`
    display: flex;
`
export const ComplementItemContainer = styled.View`
    display: flex;
    padding: 20px;
    background-color: white;
    border: 1px solid rgb(240, 243, 245);
    border-radius: 10px;
    margin: 30px;
    margin-bottom: 0px;
    justify-content: space-between;
    align-items: flex-start;
`

export const ItemSeparator = styled.TouchableOpacity`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

export const ComplementTitleText = styled.Text`
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: rgb(45, 50, 54);
`
export const ComplementOptionsDescription = styled.Text`
    color: rgb(167, 176, 184);
    font-size: 10px;
    font-family: 'Inter-Regular';
`

export const OptionItemContainer = styled.View`
    width: 100%; 
    flex-direction: row;
    padding-top: 10px;
    margin-top: 5px; 
`

export const OptionImage = styled(ImageWithPlaceholder)`
    align-self: flex-start;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    margin-right: 10px;
`

export const OptionName = styled.Text`    
    color: rgb(83, 91, 97);
    font-size: 14px;
    font-family: 'Inter-Medium';
    flex-direction: row;
    font-weight: 550;
`

export const OptionDescription = styled.Text`
    color: rgb(167, 176, 184);
    font-size: 12px;
`

export const OptionQuantityChange = styled.View`
    flex: 1;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    flex-direction: column;
`

export const AddNoteInput = styled.TextInput`
    color: rgb(176, 174, 174);
    font-family: 'Inter-SemiBold';
    padding: 5px 0px;
    width: 100%;
`

export const ProductAmountHandleContainer = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    width: 100%;
    padding: 0px 25px;
`

export const ProductDetailsSubTotalContainer = styled.View`
    display: flex;
`
export const ProductDetailsSubTotalText = styled.Text`
    color: rgb(61, 67, 71);
    font-family: 'Inter-Bold';
    font-size: 20px;
`

export const OptionPrice = styled.Text`
    color: ${props => props.theme.colors.primary};
    font-family: 'Inter-Regular';
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: 600;
`