import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import { useTheme } from 'styled-components/native';

const LoadingSpinner: React.FC = () => {

  const { colors: { primary } } = useTheme()

  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <ActivityIndicator color={primary} size={35} />
    </View>
  )
};

export default LoadingSpinner;