import IStore from '@src/interfaces/storeInterface'
import React, { Dispatch, RefObject, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { FlatList, ScrollView } from 'react-native'
import { TabItem, TabItemText } from '../styles'

interface ISectionTab {
    sectionRef: RefObject<ScrollView | null>,
    data?: IStore["category"],
    atualSection: number
    setAtualSection: Dispatch<SetStateAction<number>>
    tabRef: RefObject<FlatList>
    scrollToSection: (index: any) => void
}

const SectionTabs: React.FC<ISectionTab> = ({ data, atualSection, tabRef, scrollToSection, setAtualSection }) => {

    useEffect(() => {
        if (!atualSection) return

        setAtualSection(atualSection)   

        tabRef.current?.scrollToIndex({
            index: atualSection,
            animated: true,
            viewPosition: 0
        })
    }, [atualSection])

    

    const renderItem = ({ index, item }: any) => {

        return (
            <TabItem onPress={() => scrollToSection(index)}>
                <TabItemText active={index === atualSection && true}>{item.name}</TabItemText>
            </TabItem>
        )
    }

    return useMemo(() => {
        return (
            <FlatList
                data={data}
                horizontal
                style={{
                    width: '100%',
                    backgroundColor: '#fff',
                    padding: 20,
                    paddingLeft: 10
                }}
                showsHorizontalScrollIndicator={false}
                renderItem={renderItem}
                ref={tabRef}
                onScrollToIndexFailed={info => {
                    const wait = new Promise(resolve => setTimeout(resolve, 500));
                    wait.then(() => {
                        tabRef.current?.scrollToIndex({ index: info.index, animated: true });
                    });
                }}
            />

        )

    }, [atualSection, data])

}

export default SectionTabs