import { ShoppingCartContext } from '@contexts/shoppingCartContext';
import { StoreContext } from '@contexts/storeContext';
import { ConvertToBRL } from '@src/utils';
import React, { useContext } from 'react';
import {
  BagProductAddContactContainer,
  BagProductSum,
  SubTotalText,
  SumText,
  TotalText
} from '../styles';

interface IBagProductAddContactProps {
  orderType: string
}

const BagProductAddContact: React.FC<IBagProductAddContactProps> = ({ orderType }) => {

  const { totalValue } = useContext(ShoppingCartContext)
  const { deliveryFee } = useContext(StoreContext)

  return (
    <BagProductAddContactContainer>
      <BagProductSum>
        <SumText>
          SubTotal
        </SumText>
        <SubTotalText>
          {ConvertToBRL(totalValue)}
        </SubTotalText>
      </BagProductSum>
      {orderType === 'DELIVERY' &&
        <BagProductSum>
          <SumText>
            Entrega
          </SumText>
          <SubTotalText>
            {ConvertToBRL(deliveryFee)}
          </SubTotalText>
        </BagProductSum>
      }

      <BagProductSum>
        <SumText>
          Total
        </SumText>
        <TotalText>
          {ConvertToBRL(totalValue + (orderType === "DELIVERY" ?  deliveryFee : 0))}
        </TotalText>
      </BagProductSum>
    </BagProductAddContactContainer>
  )
};

export default BagProductAddContact;
