import { api } from "../config/api"

const marketPlaceService = {}

marketPlaceService.getStores = async () => {

    try {
        const result = (await api.get('store/app/store/')).data        
        return result
    } catch (err) {

    }

}


export default marketPlaceService