import { IShoppingBag } from "../../interfaces/shoppingBagInterfaces";

export const formatProduct = (product: IShoppingBag["products"]) => {

    const productsFormatted: IShoppingBag["products"] = product.map(product => {

        console.log(product)

        const complements = product.complementsOnProduct?.map(item => ({
            idComplement: item.idComplementOnProduct,
            idComplementOnProduct: item.id,
            options: item.options.map(option => ({
                id: option.id,
                amount: option.amount
            }))
        }))

        return ({
            amount: product.amount,
            id: product.id,
            observations: product?.observations || '',
            complements
        })
    })

    return productsFormatted
}