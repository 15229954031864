import React from 'react';
import { Image } from 'react-native';
import { Marker } from 'react-native-maps';
import PMarker from '../../../assets/images/others/pin-marker.png'
import { IMarkerProps } from '../Marker';

const MapMarkerNative: React.FC<IMarkerProps> = ({ ...props }) => {

    return (
        <Marker
            style={{ width: 40, height: 40 }}
            // image={PMarker}
            coordinate={{
                latitude: props.latitude,
                longitude: props.longitude
            }}            
            {...props}
        >
            <Image
                resizeMode='contain'
                source={PMarker}
                style={{ width: 40, height: 40 }}
            />
        </Marker>
    )
};

export default MapMarkerNative;
