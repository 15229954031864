import styled from "styled-components/native";

interface ILargeButton {
  disabled?: boolean
  title?: string
  onPress: any
}

interface ILargeButtonContainer {
  backgroundColor?: string
}

export const LargeButtonContainer = styled.View<ILargeButtonContainer>`
    display: flex;
    background-color:white;
`

export const LargeButtonStyled = styled.TouchableOpacity<ILargeButton>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    align-self: center;
    background-color: ${props => props.disabled ? '#cbd2d6' : props.theme.colors.primary};
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
`

export const LargeButtonText = styled.Text`
    font-size: 13px;
    color: white;
    font-family: 'Inter-SemiBold';
    text-wrap: nowrap;
`