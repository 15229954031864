import { Formik } from 'formik';
import React, { useRef } from 'react';
import { View } from 'react-native';
import { FormGroup, FormTitle } from '../../AddNewAddress/styles';
import * as yup from 'yup';
import InputMoney from '@components/FormComponents/InputMoney';
import Feedback from '@components/FormComponents/Feedback';
import FooterButton from '@components/LargeButton';

interface IModalCashTradeoffProps {
    setModalVisibile: (visible: boolean) => void
    changeFor: number | null
    setChangeFor: (changeFor: number) => void
}



const ModalCashTradeoff: React.FC<IModalCashTradeoffProps> = ({ setModalVisibile, setChangeFor, ...props }) => {

    const formRef = useRef<any>()

    const validationSchema = yup.object().shape({
        tradeOffValue: yup.number().default(0).optional(),
    });

    const handleSubmit = async (data: any) => {
        setChangeFor(data.tradeOffValue)
        setModalVisibile(false)
    }

    return (
        <View>
            <Formik
                innerRef={formRef}
                validationSchema={validationSchema}
                initialValues={{
                    tradeOffValue: 0
                }}
                onSubmit={(data) => handleSubmit(data)}
            >
                {({ errors, touched }) => {
                    return (
                        <View>
                            <FormGroup
                                invalid={errors.tradeOffValue && touched.tradeOffValue}
                            >
                                <FormTitle>Troco pra quanto?</FormTitle>
                                <InputMoney
                                    name='tradeOffValue'
                                    placeholder='R$ 0,00'
                                    keyboardType={'numeric'}
                                />
                            </FormGroup>
                            <Feedback name='tradeOffValue' />
                        </View>
                    )
                }}
            </Formik>

            <FooterButton
                title='Finalizar'
                onPress={() => formRef.current.submitForm()}
                style={{ marginBottom: 0 }}
            />
        </View>
    )
};

export default ModalCashTradeoff;
