import styled from "styled-components/native";

interface IPaymentMethodCard {
    selected: boolean
}

export const Container = styled.View`
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    align-items: center;
`

export const TopPriceIndicator = styled.Text`
    color: rgb(5, 52, 89);
    font-size: 12px;
    font-family: 'Inter-SemiBold';
    padding: 8.5px 10px;
    background-color: rgb(245, 249, 252);
    margin-top: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
`

export const PaymentMethodGroupTitle = styled.Text`
    font-size: 16px;
    margin-bottom: 16px;
    font-family: 'Inter-SemiBold';
`

export const ContainerPaymentIndoor = styled.View`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0px;
    width: 100%;
`

export const ContainerPaymentInApp = styled.View`
display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0px;
    width: 100%;`

export const PaymentMethodContainer = styled.View`
    display: flex;
    width: 150px;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`
export const PaymentMethodImageContainer = styled.TouchableOpacity<IPaymentMethodCard>`
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: ${props => props.theme.colors.secondary};
    border-radius: 16px;
    border: 2px solid ${props => props.selected ? props.theme.colors.primary : 'rgb(255, 247, 245)'};
`

export const PaymentMethodImage = styled.Image`
    width: 32px;
    height: 24px;
`

export const PaymentMethodName = styled.Text`
    color: rgb(103, 111, 117);
    font-family: 'Inter-Regular';
    font-size: 14px;
    margin-top: 10px;
`