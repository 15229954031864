import React, { useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import {
    SwipeableHook,
    SwipeableIconStatus,
    SwipeableItemStatus,
    SwipeableStatusDescription,
    SwipeableStatusInfo,
    SwipeableStatusItemText,
    SwipeableStatusItemTitle,
    SwipeableStatusList,
    SwipeableStatusTitle
} from '../styles';
import PreparingIcon from '@src/assets/images/Icons/status-preparing.png'
import PendingIcon from '@src/assets/images/Icons/status-pending.png'
import ReadyIcon from '@src/assets/images/Icons/status-ready.png'
import CompletedIcon from '@src/assets/images/Icons/status-completed.png'
import DeliveryIcon from '@src/assets/images/Icons/status-delivery.png'
import RefusedIcon from '@src/assets/images/Icons/status-refused.png'
import { IOrderData } from '@src/interfaces/followOrderInterfaces';

interface ISwipeableBottomProps {
    status: IOrderData["status"]
    orderType: "DELIVERY" | "TAKEOUT"
}

const SwipeableBottom: React.FunctionComponent<ISwipeableBottomProps> = ({ status, orderType }) => {

    const [historyStatus, setHistoryStatus] = useState<Array<any>>([])

    const statusList = [
        {
            status: "CANCELLED",
            statusTitle: "Pedido cancelado",
            statusText: "O estabelecimento cancelou seu pedido",
            statusIcon: RefusedIcon
        },
        {
            status: "PENDING",
            statusTitle: "Aprovação pendente",
            statusText: "Seu pedido está aguardando aprovação",
            statusIcon: PendingIcon
        },
        {
            status: "PREPARING",
            statusTitle: "Pedido em andamento",
            statusText: "Seu pedido já está em produção",
            statusIcon: PreparingIcon
        },
        {
            status: "READY",
            statusTitle: "Pedido pronto",
            statusText: orderType === 'DELIVERY' ? "Seu pedido está pronto, aguardando entregador" : "Seu pedido está pronto, aguardando a retirada",
            statusIcon: ReadyIcon
        },
        {
            status: "DELIVERY",
            statusTitle: "Saiu para entrega",
            statusText: "Fique atento, seu pedido já está a caminho",
            statusIcon: DeliveryIcon
        },
        {
            status: "CONCLUDED",
            statusTitle: "Pedido entregue",
            statusText: "Obrigado por pedir com a gente!",
            statusIcon: CompletedIcon
        },
    ]

    useEffect(() => {
        setHistoryStatus(statusList.slice(status === 'CANCELLED' ? 0 : 1, statusList.findIndex(x => x.status === status) + 1).reverse())
    }, [status])

    return (
        <ScrollView style={{ backgroundColor: '#fff', flex: 1, width: '100%' }}>
            <SwipeableHook />
            <SwipeableStatusTitle>{statusList.find(x => x.status === status)?.statusTitle}</SwipeableStatusTitle>
            <SwipeableStatusDescription>
                {statusList.find(x => x.status === status)?.statusText}
            </SwipeableStatusDescription>
            <SwipeableStatusList>
                {historyStatus.map(item => {

                    if (orderType === 'TAKEOUT' && item.status === "DELIVERY") return null

                    return (
                        <SwipeableItemStatus key={item.status}>
                            <SwipeableIconStatus source={item.statusIcon} />
                            <SwipeableStatusInfo>
                                <SwipeableStatusItemTitle>
                                    {item.statusTitle}
                                </SwipeableStatusItemTitle>
                                <SwipeableStatusItemText numberOfLines={2}>
                                    {item.statusText}
                                </SwipeableStatusItemText>
                            </SwipeableStatusInfo>
                        </SwipeableItemStatus>
                    )
                })}
            </SwipeableStatusList>
        </ScrollView>
    )
};

export default SwipeableBottom;