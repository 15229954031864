import styled from "styled-components/native";

interface IStyledText {
    color?: string;
    fontSize?: number;
    fontWeight?: string;
    lineHeight?: number;
    align?: string;
    transform?: string;
    decoration?: string;
    fontFamily?: string;
}
export const TextView = styled.Text<IStyledText>`
    color: ${(props: IStyledText) => props.color || "black"};
    font-size: ${(props: IStyledText) => props.fontSize || 14}px;
    font-weight: ${(props: IStyledText) => props.fontWeight || "normal"};
    line-height: ${(props: IStyledText) => props.lineHeight || 24}px;
    text-align: ${(props: IStyledText) => props.align || "left"};
    text-transform: ${(props: IStyledText) => props.transform || "none"};
    text-decoration: ${(props: IStyledText) => props.decoration || "none"};
    font-family: ${props => props.fontFamily || "Inter-Regular"};
`
