import { Field } from 'formik';
import React from 'react';
import { TextInput } from 'react-native-gesture-handler';
import InputFormik from './InputTextFormik';

interface IInputMaskProps {
    name: string,
    mask: string,
    placeholder?: string,
    keyboardType?: string,
    autoCapitalize?: string
}

export const symbols: any = {
    '9': /\d/,
    'a': /[A-Za-z]/,
    '*': /[\dA-Za-z]/

}

export const removeSpecialCharacters = (value: string) => {

    return value.replace(/[^0-9]/g, '')
}

export const valueWithoutMask = (value: any, mask: any, addExtra = false) => {

    if (!value) return ''
    const newValue: Array<string> = []
    const maskArray = mask.split('')

    for (const [index, char] of maskArray.entries()) {
        if ((!symbols[char]) && (char !== value[index])) {
            newValue.push(value[index])
        }
        if (symbols[char]) {
            newValue.push(value[index])
        }
    }

    const extra_characters = value.slice(mask.split('').length)

    return removeSpecialCharacters(newValue.join('') + (addExtra ? extra_characters : ''))
}

export const generalMask = (value: any, mask: string) => {

    if (!value) return ''

    const formattedValue: any = valueWithoutMask(value, mask, true)
    const newValue: Array<string> = []
    let indexValue = 0

    for (const char of mask.split('')) {

        if (formattedValue[indexValue]) {
            if (!symbols[char]) {
                newValue.push(char)
            }
            if (symbols[char]) {

                const regex = symbols[char];
                let charValue = formattedValue[indexValue]
                while (charValue) {
                    const match = regex.test(formattedValue[indexValue])
                    if (match) {
                        newValue.push(formattedValue[indexValue])
                        indexValue += 1
                        break
                    }
                    indexValue += 1
                    charValue = formattedValue[indexValue]
                }
            }
        }
    }

    return newValue.join('')
}

const InputMask: React.FC<IInputMaskProps> = ({ name, mask, ...props }) => {

    return (
        <InputFormik
            name={name}
            mask={mask}
            inputFunction={(v) => generalMask(v, mask)}
            outputFunction={(v) => valueWithoutMask(v, mask)}
            {...props}
        />
    )
};

export default InputMask;
