import { TouchableOpacityProps } from "react-native";
import styled from "styled-components/native";
import { width } from "@src/utils";

interface IAddContactButton extends TouchableOpacityProps {
    disabled?: boolean
}

export const BagProductsContainer = styled.View`
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin-bottom: 60px;
`
export const BagProductItem = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
`
export const BagProductItemContent = styled.View`
    display: flex;
    flex-direction: row;
    
`
export const BagProductImage = styled.Image`
    height: 45px;
    width: 45px;
    border-radius: 12px;
    margin-right: 10px;
`
export const BagProductItemTextContainer = styled.View`
    display: flex;
    flex-direction: column;
`
export const BagProductItemName = styled.Text`
    font-size: 14px;
    font-family: 'Inter-SemiBold';
    margin-bottom: 10px;
    max-width: ${width - 100}px;
`

export const BagProductComplementsContainer = styled.View`
    flex-direction: column;
`

export const BagProductComplementItem = styled.View`
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
`

export const BagProductComplementAmount = styled.Text`
    color: rgb(61, 67, 71);
    font-size: 12px;
    padding: 4px 8px;
    background-color: rgb(247, 249, 250);
    border-radius: 6px;
    text-align: center;
    margin-right: 6px;
`

export const BagProductComplementText = styled.Text`
    color: rgb(103, 111, 117);
`

export const BagProductItemPriceText = styled.Text`
    font-size: 12px;
    font-family: 'Inter-SemiBold';
`
export const BagProductConfigContainer = styled.View`
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    align-items: center;
`
export const BagProductAmount = styled.View`
    padding: 5px;
    background-color: rgb(247, 249, 250);
    color:  rgb(61, 67, 71);
    border-radius: 4px;
    margin-bottom: 10px;
    font-family: 'Inter-Medium';
    font-size: 12px;
`
export const SelectDeliveryType = styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const DeliveryTypeTitle = styled.Text`
    font-size: 16px;
    font-family: 'Inter-Bold';
    text-align: center;

`
export const DeliveryTypes = styled.View`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    width: 100%;
`
export const DeliveryTypeButtonContainer = styled.View`
    align-items: center;
    margin-top: 50px;
    width: 150px;
`
export const DeliveryTypeText = styled.Text`
    font-size: ${width < 600 ? '12px' : '14px'};
    font-family: 'Inter-Regular';
    color: rgb(103, 111, 117);
    text-align: center;

`
export const DeliveryTypeButton = styled.TouchableOpacity`
    background-color: ${props => props.theme.colors.secondary};
    border-radius: 20px;
    padding: 20px;
    height: auto;
    margin-bottom: 20px;
`
export const DeliveryTypeImage = styled.Image`
    width: 38px;
    height: 32px;
`
export const BagProductAddContactContainer = styled.View`
    display: flex;
    flex-direction: column;
    margin-top: 60px;
`
export const BagProductSum = styled.View`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    padding-top: 0px;
    padding-bottom: 15px;
`
export const SumText = styled.Text`
    font-size: 14px;
    color: rgb(167, 176, 184);
    font-family: 'Inter-Regular';
`
export const SubTotalText = styled.Text`
    font-size: 14px;
    color: rgb(103, 111, 117);
    font-family: 'Inter-Medium';
`
export const TotalText = styled.Text`
    color: rgb(61, 67, 71);
    font-size: 20px;
    font-family: 'Inter-Bold';
`
export const OrderTypeTakeOutContainer = styled.View`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    align-items: center;
`
export const TakeOutChangeAddress = styled.TouchableOpacity`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: #FFF7F5;
    border-radius: 10px;
    padding: 10px 20px;
`
export const TakeOutChangeAddressText = styled.Text`
    color: ${props => props.theme.colors.primary};
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
`
export const TakeOutChangeAddressImage = styled.Image`
    width: 13px;
    height: 13px;
`
export const StoreInfoTakeOut = styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
`
export const StoreImageTakeOut = styled.Image`
    width: 48px;
    height: 48px;
`
export const StoreNameTakeOut = styled.Text`
    font-size: 14px;
    color: rgb(45, 50, 54);
    font-family: 'Inter-Bold';
    margin-top: 10px;
    margin-bottom: 5px;
`
export const StoreAdressTakeout = styled.Text`
    font-size: 12px;
    color: rgb(103, 111, 117);
    font-family: 'Inter-Regular';
    max-width: 70%;
    text-align: center;
`