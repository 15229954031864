import styled from "styled-components/native";

interface IFilterIconText {
  selected: boolean
}

export const HeaderImage = styled.Image`
  height: 50px;
  width: 120px;
  margin-left: 25px;
`

export const TabContainer = styled.View`
  background-color: white;
  padding-left: 10px;
`
export const TabItem = styled.View`
  padding: 3px 10px;
  margin: 5px;
  margin-left: 0px;
  margin-top: 0px;
  border-radius: 15px;
  border-width: 1px;
  border-color: #0000003e;
  align-items: center;
  justify-content: center;
`

export const StoreDescription = styled.Text`
  font-family: 'Inter-Regular';
  font-size: 10px;
  color: #676f75;
  margin-top: 6px;
`

export const SearchButton = styled.TouchableOpacity`
  margin-right: 20px;
`
export const FilterContainerIconText = styled.View`
  align-items: center;
  width: 50%;
  height: 50%;
  padding: 5px;
  margin-top: 10px;
`

export const FilterIcon = styled.TouchableOpacity<IFilterIconText>`
  border-radius: 1000px;
  background-color: ${props => props.selected ? "#f8552831" : "#fff"};
  border: 1px solid ${props => props.selected ? "transparent" : "#676f7538"};
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  padding: 10px;
  margin-bottom: 5px;
`
export const FilterText = styled.Text<IFilterIconText>`
  color: ${props => props.selected ? "#F85628" : "#909192ad"};
  font-family: "Inter-Regular";

`