import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  ContainerPaymentIndoor,
  PaymentMethodContainer,
  PaymentMethodGroupTitle,
  PaymentMethodImage,
  PaymentMethodImageContainer,
  PaymentMethodName,
  TopPriceIndicator
} from './styles';

import cash from '@src/assets/images/General/cash.png'
import card from '@src/assets/images/General/card.png'
import Pix from '@src/assets/images/General/pix.png'

import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Image, Platform, Text, View } from 'react-native';
import ModalCashTradeoff from './components/ModalCashTradeoff';
import noPayments from '@src/assets/images/General/no-payments.svg';
import ModalPix from './components/ModalPix';
import { ShoppingCartContext } from '@contexts/shoppingCartContext';
import { StoreContext } from '@contexts/storeContext';
import { formatProduct } from '@contexts/shoppingCartContext/format';
import { HomeStackParamList } from 'types';
import orderService from '@src/services/orderService';
import { ConvertToBRL } from '@src/utils';
import Modal from '@components/Modal';
import FooterButton from '@components/LargeButton';
import SafeAreaScrollView from '@components/SafeAreaScrollView';
import { useTheme } from 'styled-components/native';

const PaymentMethod: React.FC = () => {

  const { dispatchShoppingCart, shoppingCart, totalValue } = useContext(ShoppingCartContext)
  const { storeData, deliveryFee } = useContext(StoreContext)
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [changeFor, setChangeFor] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const navigation = useNavigation<NavigationProp<HomeStackParamList>>()
  const { colors: { primary, secondary } } = useTheme()

  const handleSelectPaymentMethod = (item: any) => {

    if (item.method === "CASH") setModalVisible(true)

    const dataPut = {
      currency: "BRL",
      method: item.method,
      value: totalValue + deliveryFee,
      type: item.type,
    }

    dispatchShoppingCart({ type: "SELECT_PAYMENT_METHOD", data: dataPut })
  }

  useEffect(() => {
    dispatchShoppingCart({ type: "SELECT_PAYMENT_METHOD", data: null })
  }, [])

  const handlePay = useCallback(async () => {

    setLoading(true)

    const dataPost =
    {
      ...shoppingCart,
      products: formatProduct(shoppingCart.products),
      orderInfo: {
        address: shoppingCart.orderType !== "DELIVERY" ? undefined : { ...shoppingCart.orderInfo.address, id: undefined },
        deliveredBy: shoppingCart.orderType === "DELIVERY" ? "STORE" : undefined,
        mode: shoppingCart.orderType === "DELIVERY" ? undefined : "DEFAULT",
        table: shoppingCart.orderType === "EATIN" ? shoppingCart.orderInfo.table : undefined,
      },
      payments: [
        {
          ...shoppingCart.payments[0],
          changeFor: shoppingCart.payments[0].method === "CASH" ? changeFor : undefined
        }
      ]
    }

    const result = await orderService.handleOrder(storeData.id, dataPost)
    if (!result) return setLoading(false)

    if (Platform.OS === "web") {
      window.location.href = window.location.href + `?order=${result.id}`
      return
    }

    navigation.navigate('order', { id: result.id })
  }, [shoppingCart, changeFor])

  const payment = shoppingCart.payments
  const paymentMethods = [
    {
      id: 1,
      image: card,
      name: "Cartão de crédito",
      isActive: storeData.allowCreditPayment,
      method: 'CREDIT',
      type: "OFFLINE"
    },
    {
      id: 2,
      image: card,
      name: "Cartão de débito",
      isActive: storeData.allowDebitPayment,
      method: 'DEBIT',
      type: "OFFLINE"
    },
    {
      id: 3,
      image: cash,
      name: "Dinheiro",
      isActive: storeData.allowCashPayment,
      method: 'CASH',
      type: "OFFLINE"
    },
    {
      id: 4,
      image: Pix,
      name: "Pix",
      isActive: storeData.allowPixPayment,
      method: 'PIX',
      type: "OFFLINE"
    }
  ]

  const checkHavePaymentMethod = paymentMethods.some(item => item.isActive)

  const onlinePaymentMethods = paymentMethods.filter(item => item.type === "ONLINE")
  const offlinePaymentMethods = paymentMethods.filter(item => item.type === "OFFLINE")

  return (
    <>
      <SafeAreaScrollView>
        <View style={{ alignItems: 'center' }}>

          <TopPriceIndicator>Pedido {ConvertToBRL(totalValue + deliveryFee)}</TopPriceIndicator>
          {/*In App Payment Methods */}
          {/* <PaymentMethodGroupTitle>Pagamento pelo app</PaymentMethodGroupTitle>
                    <ContainerPaymentInApp>

                    </ContainerPaymentInApp> */}

          {/*In Door Payment Methods */}

          {
            !checkHavePaymentMethod ?
              <View style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                paddingHorizontal: 10
              }}>
                <Image
                  source={noPayments}
                  style={{
                    width: 200,
                    height: 240,
                    resizeMode: 'contain',
                    marginBottom: 10
                  }}
                />
                <Text style={{
                  fontSize: 16,
                  fontFamily: "Inter-Regular",
                  textAlign: 'center',
                  color: "#bbbbbb",
                }}>
                  Nenhuma forma de entrega disponível para essa loja
                </Text>

              </View>
              :
              <>
                <PaymentMethodGroupTitle>Pagar na entrega</PaymentMethodGroupTitle>

                <ContainerPaymentIndoor>
                  {!checkHavePaymentMethod ?
                    <View style={{
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingHorizontal: 10
                    }}>
                      <Image
                        source={noPayments}
                        style={{
                          width: 200,
                          height: 240,
                          resizeMode: 'contain',
                          marginBottom: 10
                        }}
                      />
                      <Text style={{
                        fontSize: 16,
                        fontFamily: "Inter-Regular",
                        textAlign: 'center',
                        color: "#bbbbbb",
                      }}>
                        Nenhuma forma de entrega disponível para essa loja
                      </Text>

                    </View>
                    :
                    offlinePaymentMethods.map((item) => {

                      if (!item.isActive) return

                      if (item.method === "CASH") {
                        return (
                          <PaymentMethodContainer key={item.id}>
                            <Modal
                              isCancelable
                              style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 20,
                                backgroundColor: secondary,
                                borderRadius: 16,
                                borderWidth: 2,
                                borderColor: `${payment[0]?.method === item.method ? primary : 'rgb(255, 247, 245)'}`,
                              }}
                              modalStyle={{
                                width: 400
                              }}
                              isVisible={modalVisible}
                              setIsVisible={setModalVisible}
                              onPress={() => handleSelectPaymentMethod(item)}
                              title='Pagamento em dinheiro'
                              component={
                                <ModalCashTradeoff
                                  setModalVisibile={setModalVisible}
                                  changeFor={changeFor}
                                  setChangeFor={setChangeFor}
                                />
                              }
                            >
                              <PaymentMethodImage
                                source={item.image}
                                style={{ tintColor: primary }}
                              />
                            </Modal>
                            <PaymentMethodName>{item.name}</PaymentMethodName>
                          </PaymentMethodContainer>
                        )
                      }



                      return (
                        <PaymentMethodContainer key={item.id}>
                          <PaymentMethodImageContainer
                            onPress={() => handleSelectPaymentMethod(item)}
                            selected={payment[0]?.method === item.method}
                          >
                            <PaymentMethodImage
                              source={item.image}
                              style={{ tintColor: primary }}
                            />
                          </PaymentMethodImageContainer>
                          <PaymentMethodName>{item.name}</PaymentMethodName>
                        </PaymentMethodContainer>

                      )
                    })}
                </ContainerPaymentIndoor>
                {/* <PaymentMethodGroupTitle>Pagamento pelo app</PaymentMethodGroupTitle>
                                <ContainerPaymentInApp>

                                    


                                </ContainerPaymentInApp> */}

              </>
          }

        </View>
      </SafeAreaScrollView>
      <FooterButton
        title='Finalizar pedido'
        onPress={handlePay}
        disabled={!shoppingCart.payments[0]?.method}
        loading={loading}
      />
    </>

  )

};


export default PaymentMethod;


