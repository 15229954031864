import styled from "styled-components/native";

interface IFormGroup {
    invalid: boolean | undefined | string
}

interface ICheckProps {
    check: boolean;
}


export const Scroll = styled.ScrollView.attrs({
    contentContainerStyle: {
        paddingVertical: 20,
        paddingHorizontal: 20,
        justifyContent: 'space-between'
    }
})`
    display: flex;
    flex: 1;
    flex-direction: column;
    background: transparent;
`;

export const FormGroup = styled.View<IFormGroup>`
    border: 2px solid; 
    border-color: ${props => props.invalid ? '#ff6f6f' : 'rgb(240, 243, 245)'};
    border-radius: 12px;
    padding: 6.5px 20px;
    margin-top: 20px;
    margin-bottom: 3px;
`

export const FormTitle = styled.Text`
    font-size: 12px;
    font-family: 'Inter-SemiBold';
`
export const ErrorMessageText = styled.Text`
    font-size: 13px;
    font-family: 'Inter-Regular';
    color: rgb(248, 54, 54);
`

export const DualComponentContainer = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 100px;
`;

export const CheckButton = styled.TouchableOpacity<ICheckProps>`
  border-radius: 6px;
  padding: 2px;
  margin-left: 24px;
  margin-right: 12px;
  background: ${({ check, theme }) => (check ? theme.colors.primary : '#F0F3F5')};
`;

export const CheckTitle = styled.Text`
  font-family: 'Inter-Medium';
  font-size: 14px;
  align-items: flex-end;

  color: #3d4347;
`;

export const DualFeedback = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`