import React from 'react';
import { ActivityIndicator, Text } from 'react-native';
import { useTheme } from 'styled-components/native';
import { LargeButtonContainer, LargeButtonStyled, LargeButtonText } from './styles';

interface IFooterButtonProps {
  title?: string;
  disabled?: boolean;
  loading?: boolean;
  backgroundColor?: string;
  onPress?: () => any;
  style?: any,
  errorMessage?: string
}

const FooterButton: React.FunctionComponent<IFooterButtonProps> = ({ children, ...props }) => {

  const { colors: { primary } } = useTheme()

  return (
    <LargeButtonContainer>
      {props.disabled &&
        <Text style={{ alignSelf: 'center', color: primary, textAlign: 'center' }}>{props?.errorMessage}</Text>
      }
      <LargeButtonStyled
        {...props}
        disabled={(props.disabled || props.loading)}
        onPress={(props.disabled || props.loading) ? "" : props.onPress}
      >
        {props.loading ?
          <ActivityIndicator color={primary} />
          :
          props.title ?
            <LargeButtonText>
              {props.title}
            </LargeButtonText>
            :
            <>
              {children}
            </>
        }
      </LargeButtonStyled>
    </LargeButtonContainer>

  )
};

export default FooterButton;