import { Platform } from "react-native"
import styled from "styled-components/native"
import { height } from "@src/utils"

interface TabActiveProps {
  active: boolean
}

interface IDecrementButton {
  disabled?: boolean
}

interface IStoreStatus {
  isOpen: boolean
}

export const ProductItem = styled.View`
  margin-left: 20px;
  margin-right: 20px;
`
export const Product = styled.View`
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 12px;
  background: #f7f9fa;
  border-radius: 18px;
  z-index: 1;
`

export const ProductContainerCenter = styled.Pressable`
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 74px;
  padding-left: 16px;
`

export const ProductTitle = styled.Text`
  font-family: 'Inter-Bold';
  font-size: 12px;
  color: #3d4347;
`

export const ProductDescriptionRow = styled.View`
  flex-direction: row;
  padding-right: 10px;
`

export const ProductDescription = styled.Text`
  font-family: 'Inter-Regular';
  font-size: 10px;
  color: #676f75;
  margin-top: 6px;
  padding-right: 10px;
`
export const SectionCategory = styled.View`

`


export const SectionTitle = styled.Text`
    font-weight: bold;
    margin-left: 20px;
    font-size: 18px;
    margin-bottom: 10px;
`

export const ProductAction = styled.View`
  /* flex: 1; */
  align-items: center;
  justify-content: space-between;
  height: 74px;
  padding-right: 4px;
`;

export const ProductionPriceText = styled.Text`
  font-family: 'Inter-Bold';
  font-size: 12px;
  color: #053459;
  opacity: 0.88;
`;

export const ProductButtonDecrement = styled.TouchableOpacity<IDecrementButton>`
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: #eee;
  border-radius: 6px;
`;

export const ProductButtonPlus = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: #eee;
  border-radius: 6px;
`;

export const ProductActionsComplex = styled.View`
  /* flex: 1; */
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
`;

export const ProductButtonPrice = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: ${props => props.theme.colors.primary};
  border-radius: 6px;
  margin-right: -2px;
`;

export const ProductItemText = styled.Text`
  font-family: 'Inter-SemiBold';
  color: #676f75;
  margin-right: 12px;
  margin-left: 12px;
  font-size: 12px;
`;

export const ProductButtonPreciText = styled.Text`
  font-family: 'Inter-SemiBold';
  color: #ffffff;
`;
// BAG
export const Container = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: 65px;

  bottom: 0px;
  padding: 21px 32px;

  background-color: ${props => props.theme.colors.primary};
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
`;

export const LeftContent = styled.View`
 
  flex-direction: row;
  width:100%;
  justify-content: center;
`;

export const TitleText = styled.Text`
  
  flex:1;
  font-family: 'Inter-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff;
`;

export const AmountItens = styled.Text`
  flex:1;
  font-family: 'Inter-Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  text-align:center;
  color: #ffffff;

  opacity: 0.8;
`;

export const TotalText = styled.Text`
  flex:1;
  font-family: 'Inter-Bold';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  align-items: center;
  text-align: right;

  color: #ffffff;
`;

export const HeaderContainer = styled.View`
  background-color: transparent;
`

export const SubHeader = styled.View`
  position: relative;
  bottom: 30px;
  min-height: 200px;
  background-color: white;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
`

export const StoreLogoImageBackground = styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  height: 124px;
  width: 124px;
  border: 1px solid #e4e8eb;
  border-radius: 28px;
`;

export const StoreLogoImage = styled.Image`
  width: 104px;
  height: 104px;
  border-radius: 15px;
`;

export const StoreInfoContainer = styled.View`
  margin-top: -62px;
  justify-content: space-around;
  background-color: transparent;
`
export const StoreName = styled.Text`
  font-size: 19px;
  align-self: center;
  margin-top: 10px;
  font-family: 'Inter-Bold';
`

export const StoreAdress = styled.Text`
  font-family: 'Inter-Regular';
  font-size: 12px;
  line-height: 18px;
  color: #676f75;
  width: 90%;

  text-align: center;
  flex: none;
  align-self: center;
  flex-grow: 0;
  margin: 0px 8px;
`;

export const StoreDetails = styled.View`
  position:absolute;
  bottom: 30px;
  width: 90%;
  height: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  align-self: center;
  margin: 0px;
`;

export const DetailButton = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center; 
  justify-content: center;
  text-align: center;
`

export const StoreTimeText = styled.Text`
  color: #535b61;
  font-family: 'Inter-Regular';
  font-size: 12px;
  line-height: 18px;
`;

export const StoreIsOpen = styled.Text<IStoreStatus>`
  font-family: 'Inter-SemiBold';
  font-size: 12px;
  line-height: 15px;
  color: ${(props: any) => (props.isOpen ? '#2FBF77' : '#FC4C4C')};
  background-color: ${(props: any) => (props.isOpen ? '#F2FCF7' : '#FFF5F5')};
  padding: 2px 10px;
  border-radius: 6px;
`;

export const StoreInformationsButtonText = styled.Text`
  color: #535b61;
  font-family: 'Inter-Regular';
  font-size: 12px;
`;

export const HeaderActionButton = styled.TouchableOpacity`
  flex-direction: row;
  padding: 10px 17px;
`

export const TabItem = styled.TouchableOpacity`
  margin-left: 20px;
  margin-right: 20px;
  justify-content: center;
`
export const TabItemText = styled.Text<TabActiveProps>`
  font-size: 19px;
  color: ${props => props.active ? props.theme.colors.primary : "#d9dadc"};
  font-weight: 500;
`

export const ButtonExpandInput = styled.TouchableOpacity`
  justify-content: center;
  padding: 10px 16px;
  background-color: white;
`
export const ButtonCleanSearch = styled.TouchableOpacity.attrs({
  activeOpacity: 0.4,
})`
  align-items: center;
  justify-content: center;
  background: transparent;
  padding: 12px 0px 12px 16px;
`;

export const SearchInputText = styled.TextInput`
  flex: 1;
  align-items: flex-end;
  height: auto;
  width: auto;
  max-width: 99% ;
  padding: 4px 16px;

  font-family: 'Inter-SemiBold';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #3d4347;
`;