import LoadingSpinner from '@components/LoadingSpinner';
import React from 'react';
import { StyleSheet } from 'react-native';
import MapView from 'react-native-maps';

interface IMapComponentNativeProps {
    initialPosition: Array<number>
    loading: boolean
}

const MapComponentNative: React.FC<IMapComponentNativeProps> = ({ initialPosition, children, loading, ...props }) => {

    if (loading) return null

    return (
        <>
            <MapView
                // onRegionChange={(e) => console.log(e)}
                initialRegion={{
                    latitude: initialPosition[0],
                    longitude: initialPosition[1],
                    latitudeDelta: 0.014,
                    longitudeDelta: 0.014,
                }}
                style={styles.map}
                {...props}
            />
        </>
    )
};

export default MapComponentNative;

const styles = StyleSheet.create({
    map: {
        width: '100%',
        height: '100%'
    }
})