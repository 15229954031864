import React, { useContext, useMemo } from 'react';
import { StyleSheet, Text } from 'react-native';
import { useTheme } from 'styled-components/native';
import SweetAlert from '../../components/SweetAlert';
import { ShoppingCartContext } from '../../contexts/shoppingCartContext';

interface CleanShoppingBagButtonProps {
}

const CleanShoppingBagButton: React.FunctionComponent<CleanShoppingBagButtonProps> = (props) => {

  const { dispatchShoppingCart } = useContext(ShoppingCartContext)
  const { colors: { primary } } = useTheme()

  const handleCleanShoppingBag = () => {
    dispatchShoppingCart({ type: 'SHOPPING_BAG/CLEAN', data: {} })
  }

  return useMemo(() => {
    return (
      <SweetAlert
        icon="error"
        title='Tem certeza disso?'
        text='Você vai excluir todos os itens da sua sacola!'
        buttons={[
          {
            text: 'Cancelar',
            type: 'cancel',
          },
          {
            text: 'Apagar',
            type: 'confirm',
            onPress: handleCleanShoppingBag
          }
        ]}
      >
        <Text style={[styles.text, { color: primary }]}>
          Limpar
        </Text>
      </SweetAlert>
    )
  }, [])
};

const styles = StyleSheet.create({
  text: {
    fontFamily: 'Inter-Bold',
    fontSize: 16,
    marginRight: 10
  }

})

export default CleanShoppingBagButton;
