import { api } from "../config/api"

const storeService = {}

storeService.getData = async (slug) => {

    try {
        const result = (await api.get(`/store/app/store/${slug}`)).data
        return result
    } catch (err) {

    }

}

storeService.addVisualizationOnStore = async (storeId) => {
    try {
        const result = (await api.post(`/store/app/store/add-visualization/${storeId}`)).data
        return result
    } catch (err) {

    }
}


export default storeService