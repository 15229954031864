import "react-native-gesture-handler";
import { StatusBar } from "expo-status-bar";
import React from "react";

import useCachedResources from "./src/hooks/cachedResource/useCachedResources";
import useColorScheme from "./src/hooks/colorScheme/useColorScheme";
import { Platform } from "react-native";
import ContextProvider from "./src/contexts";
import { ToastContainer } from "./src/contexts/toastContext";
import { SafeAreaView } from "react-native-safe-area-context";
import Navigation from "@src/navigation";

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <ContextProvider>
        <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
          <ToastContainer>
            <Navigation colorScheme={colorScheme} />
            <StatusBar
              hidden={Platform.OS === "web"}
              backgroundColor="#F95828"
            />
          </ToastContainer>
        </SafeAreaView>
      </ContextProvider>
    );
  }
}
