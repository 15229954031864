import { NavigationProp, useNavigation } from "@react-navigation/native";
import React, { useContext, useEffect, useState } from "react";
import { FlatList, Platform, StyleSheet } from "react-native";
import {
    DateDescription,
    OrderDescriptionRow,
    OrderItem,
    OrderStatusContainer,
    OrderUserContainer,
    SimpleTextOrder,
} from "./styles";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { IUserOrderHistory } from "@src/interfaces/followOrderInterfaces";
import { UserContext } from "@contexts/userContext";
import { STORE_ID } from "@src/constants/Constants";
import orderService from "@src/services/orderService";
import { HomeStackParamList } from "types";
import { ConvertToBRL } from "@src/utils";

const UserOrders: React.FC = () => {

    const [userOrders, setUserOrders] = useState<Array<IUserOrderHistory>>([])
    const { phone } = useContext(UserContext)

    const navigation = useNavigation<NavigationProp<HomeStackParamList>>()

    useEffect(() => {
        if (!phone) return
        const getUserOrders = async () => {
            const storeId = await AsyncStorage.getItem(STORE_ID)
            const userOrders = await orderService.getUserOrders(storeId, phone)
            setUserOrders(userOrders)
        }
        getUserOrders()
    }, [])

    const goToOrder = (id: number) => {
        if(Platform.OS === "web"){
            window.location.href = window.location.href + `?order=${id}`
            return
        }
        navigation.navigate('order', { id })
    }

    return (
        <FlatList
            showsVerticalScrollIndicator={false}
            numColumns={1}
            data={userOrders.sort((a, b) => b.id - a.id)}
            renderItem={({ item }) => {

                const date = new Date(item.createdAt).toLocaleString([], { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })
                const status = statusList.find(x => x.status === item.status)
                const orderType = orderTypeList.find(x => x.orderType === item.orderType)

                return (
                    <OrderUserContainer>
                        <DateDescription>
                            {`${date.charAt(0).toUpperCase()}${date.slice(1)}`}
                        </DateDescription>
                        <OrderItem
                            onPress={() => goToOrder(item.id)}
                            style={styles.item}
                        >
                            <OrderDescriptionRow>
                                <SimpleTextOrder
                                    style={{ fontFamily: "Inter-SemiBold" }}
                                >Pedido #{item.id}</SimpleTextOrder>
                                <OrderStatusContainer>
                                    <SimpleTextOrder
                                        style={{ color: status?.color }}
                                    >{status?.statusText}</SimpleTextOrder>
                                </OrderStatusContainer>
                            </OrderDescriptionRow>

                            <OrderDescriptionRow>
                                <SimpleTextOrder>
                                    {item.store.storeName} | {orderType?.orderTypeText}
                                </SimpleTextOrder>
                            </OrderDescriptionRow>

                            <OrderDescriptionRow>


                                <SimpleTextOrder numberOfLines={1}>
                                    {ConvertToBRL(item.total.subTotal)}
                                </SimpleTextOrder>

                            </OrderDescriptionRow>

                        </OrderItem>
                    </OrderUserContainer>
                )
            }}
            style={{ flex: 1 }}
        />
    )
}
const statusList = [
    {
        id: 1,
        status: 'PENDING',
        color: '#ffb400',
        statusText: 'Aguardando confirmação'
    },
    {
        id: 2,
        status: 'PREPARING',
        color: '#ffb400',
        statusText: 'Em produção'
    },
    {
        id: 3,
        status: 'READY',
        color: '#5bff81',
        statusText: 'Pronto'
    },
    {
        id: 4,
        status: 'DELIVERY',
        color: '#5dff65',
        statusText: 'Entregue'
    },
    {
        id: 5,
        status: 'CONCLUDED',
        color: '#56ff72',
        statusText: 'Concluído'
    },
    {
        id: 6,
        status: 'CANCELLED',
        color: '#ff0000',
        statusText: 'Cancelado'
    },
]

const orderTypeList = [
    {
        id: 1,
        orderType: 'DELIVERY',
        orderTypeText: "Receber em casa"
    },
    {
        id: 2,
        orderType: 'TAKEOUT',
        orderTypeText: "Retirar na loja"
    },
]


const styles = StyleSheet.create({
    item: {
        elevation: 1,
        shadowOffset: {
            width: 3,
            height: 2
        },
        shadowColor: '#49494942'
    }
})

export default UserOrders