import { MaterialIcons } from '@expo/vector-icons';
import { IProductComplementsStore } from '@src/interfaces/shoppingBagInterfaces';
import { ConvertToBRL } from '@src/utils';
import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { Text, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { ProductButtonDecrement, ProductItemText } from '../../Store/styles';
import { ProductAmountHandleContainer, ProductDetailsSubTotalContainer, ProductDetailsSubTotalText } from '../styles';

interface IProductHandleControllerProps {
    setAmount: Dispatch<SetStateAction<number>>,
    amount: number,
    product: any,
    complementsOnProduct: Array<IProductComplementsStore>,
    productValue: number
}

const ProductHandleController: React.FC<IProductHandleControllerProps> = ({ amount, setAmount, product, complementsOnProduct, productValue }) => {

    const { colors: { primary } } = useTheme()

    const handleDecrementProduct = useCallback(() => {
        if (amount === 1) return
        setAmount(x => x - 1)
    }, [amount])

    const handleIncrementProduct = useCallback(() => {
        setAmount(x => x + 1)
    }, [amount])

    // const productValue = useCallback(() => {

    //     const productBaseValue = product.ignoreBaseValue ? 0 : product.price;

    //     const complementsValue = complementsOnProduct.reduce((total, item) => {

    //         switch (item.complement.totalizer) {
    //             case "MAX": {
    //                 const expensivestOption = [...item.complement?.options]
    //                     ?.sort((a, b) => (a?.amount * a?.value) - (b?.amount * b?.value))
    //                     ?.reverse()[0]?.value || 0
    //                 return total + expensivestOption
    //             }
    //             case "SUM": {
    //                 return total + item.complement.options
    //                     .reduce((totalOption, option) => totalOption + (option.value * option.amount), 0)
    //             }
    //             default: return total
    //         }
    //     }, 0)


    //     return (productBaseValue + complementsValue)
    // }, [complementsOnProduct])

    return (
        <ProductAmountHandleContainer>
            <View style={{ flexDirection: 'row', alignSelf: 'flex-end', alignItems: 'center' }}>
                <ProductButtonDecrement onPress={handleDecrementProduct}>
                    <MaterialIcons
                        name="remove"
                        size={20}
                        color={amount <= 1 ? '#CBD2D6' : primary}
                    />
                </ProductButtonDecrement>
                <ProductItemText>{amount}</ProductItemText>
                <ProductButtonDecrement onPress={handleIncrementProduct}>
                    <MaterialIcons
                        name="add"
                        size={20}
                        color={primary}
                    />
                </ProductButtonDecrement>
            </View>
            <ProductDetailsSubTotalContainer>
                <Text>subTotal</Text>
                <ProductDetailsSubTotalText>
                    {ConvertToBRL(productValue * amount)}
                </ProductDetailsSubTotalText>
            </ProductDetailsSubTotalContainer>
        </ProductAmountHandleContainer>
    )
};

export default ProductHandleController;
