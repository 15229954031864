import React from "react"
import { Image } from "react-native"
import { TouchableOpacity } from "react-native-gesture-handler"
import { useTheme } from "styled-components/native"
import swal from "sweetalert2"

export interface UPImage {
    placeholder?: any,
    source: any,
    style?: any
    preview?: boolean
}

const UPImage: React.FC<UPImage> = ({ source, style, preview }) => {

    const { colors: { primary } } = useTheme()

    const previewImage = () => {
        swal.fire({
            imageUrl: source?.uri || "",
            imageWidth: "85%",
            imageHeight: "auto",
            imageAlt: 'Custom image',
            confirmButtonColor: primary,
            confirmButtonText: "Fechar"
        })
    }

    if (preview) {
        return (
            <TouchableOpacity
            onPress={previewImage}
            >
                <Image
                    source={source}
                    style={style}
                />
            </TouchableOpacity>
        )
    }

    return (
        <Image
            source={source}
            style={style}
        />
    )

}

export default UPImage