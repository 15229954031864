import React, { useCallback, useContext, useMemo } from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import {
    Product,
    ProductItem,
    ProductContainerCenter,
    ProductTitle,
    ProductDescription,
    ProductDescriptionRow,
    ProductAction,
    ProductionPriceText,
    ProductActionsComplex,
    ProductButtonDecrement,
    ProductItemText,
    ProductButtonPlus,
    ProductButtonPrice,
} from '../styles';
import PlaceHolderImage from '@src/assets/images/Mocks/Hamburguer-bag.png'
import { MaterialIcons } from '@expo/vector-icons';
import { ShoppingCartContext } from '@contexts/shoppingCartContext';
import IStore from '@src/interfaces/storeInterface';
import ImageWithPlaceholder from '@components/ImageWithPlaceholder';
import { ConvertToBRL } from '@src/utils';
import { HomeStackParamList } from 'types';
import { useTheme } from 'styled-components/native';
import { View } from 'react-native';

interface IProductSimple {
    item: IStore["category"][0]["products"][0]
}

const ProductSimple: React.FC<IProductSimple> = ({ item }) => {

    const { dispatchShoppingCart, shoppingCart } = useContext(ShoppingCartContext)

    const navigation = useNavigation<NavigationProp<HomeStackParamList>>()
    const { colors } = useTheme()

    const handleNavigateToProductDetailsScreen = useCallback(() => {
        navigation.navigate('product-details', { productId: item.id, categoryId: item.categoryId })
    }, [])

    const handleIncrementProduct = () => {
        dispatchShoppingCart({ type: 'INCREMENT_PRODUCT', data: item })
    }

    const handleDecrementProduct = () => {
        dispatchShoppingCart({ type: "DECREMENT_PRODUCT", data: { id: item.id } })
    }
    const productAmount = shoppingCart.products.find(x => x.id === item.id)?.amount || 0

    return useMemo(() => {
        return (
            <>
                <ProductItem>
                    <Product>
                        <ImageWithPlaceholder
                            preview
                            style={{
                                width: 74,
                                height: 74,
                                overflow: 'hidden',
                                borderRadius: 12
                            }}
                            image={item?.picture}
                            placeholder={PlaceHolderImage}
                        />

                        <ProductContainerCenter onPress={item?.complementsOnProduct?.length > 0 ? handleNavigateToProductDetailsScreen : () => { }}>
                            <ProductTitle>{item.name}</ProductTitle>
                            <ProductDescriptionRow>
                                <ProductDescription>{item.description}</ProductDescription>
                            </ProductDescriptionRow>
                        </ProductContainerCenter>

                        <ProductAction>
                            <ProductionPriceText>
                                {item.price > 0 ? ConvertToBRL(item.price) : <br />}
                            </ProductionPriceText>
                            {item?.complementsOnProduct?.length === 0 ? (
                                <View style={{ justifyContent: "flex-end", height: 74 }}>
                                    <ProductActionsComplex>
                                        <ProductButtonDecrement
                                            onPress={handleDecrementProduct}
                                        >
                                            <MaterialIcons
                                                name="remove"
                                                size={20}
                                                color={productAmount <= 0 ? '#CBD2D6' : colors.primary}
                                            />
                                        </ProductButtonDecrement>

                                        <ProductItemText>{productAmount}</ProductItemText>

                                        <ProductButtonPlus
                                            onPress={handleIncrementProduct}
                                        >
                                            <MaterialIcons name="add" size={20} color={colors.primary} />
                                        </ProductButtonPlus>
                                    </ProductActionsComplex>
                                </View>
                            ) : (
                                <>
                                    {
                                        item.price <= 0 && (
                                            <View />
                                        )
                                    }
                                    <ProductButtonPrice
                                        style={{ alignSelf: "flex-end" }}
                                        onPress={handleNavigateToProductDetailsScreen}
                                    >
                                        <MaterialIcons name="add" size={20} color="#fff" />
                                    </ProductButtonPrice>
                                </>
                            )}
                        </ProductAction>
                    </Product>
                </ProductItem>
            </>

        );
    }, [productAmount])


};

export default ProductSimple