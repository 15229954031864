import React, { useContext, useEffect, useState } from 'react';
import { Field } from 'formik';
import { TextInput } from 'react-native-gesture-handler';
import { StyleSheet, Text, TextStyle, TouchableOpacity, View } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { StoreContext } from '../../contexts/storeContext';

interface IInputDataListFormikProps {
    name: string
    type?: string
    placeholder?: string
    style?: TextStyle,
    inputFunction?: (v: any) => any,
    outputFunction?: (v: any) => any,
    mask?: string,
    keyboardType?: any,
    editable?: boolean
}

interface IInputDataListFormikState {
    id: number
    name: string
    value: number
}

const InputDataListFormik: React.FunctionComponent<IInputDataListFormikProps> = ({
    name, type, style,
    inputFunction, mask,
    outputFunction, ...props }) => {

    const [hasFocus, setHasFocus] = useState(false);
    const [filteredData, setFilteredData] = useState<Array<IInputDataListFormikState>>([]);
    const [search, setSearch] = useState('');
    const { storeData } = useContext(StoreContext)

    useEffect(() => {
        setFilteredData(storeData?.neighborhood)
    }, [])

    useEffect(() => {
        if(!search) return setFilteredData(storeData?.neighborhood)

        const filtered = storeData?.neighborhood.filter(item => item.name.toLowerCase().includes(search.toLowerCase()))
        setFilteredData(filtered)

    }, [search])

    const handleToggleFocus = () => {
        setHasFocus(!hasFocus);
    }

    return (
        <Field name={name} type={type} {...props}>
            {
                ({
                    field,
                    form,
                    meta
                }: any) => {

                    const handleSelectItem = (item: any) => {
                        form.setFieldValue(name, item.name);
                        handleToggleFocus();
                    }

                    const handleCancelFieldAssignment = () => {
                        if (!storeData?.neighborhood.includes(field.value)) {
                            form.setFieldValue(name, "");
                        }
                    }

                    return (
                        <View style={styles.container} >
                            <View style={styles.containerTextInput}>
                                <TextInput
                                    value={!inputFunction ? field.value :
                                        inputFunction(field.value)
                                    }
                                    onChangeText={(v) => {
                                        setSearch(v)
                                        if (!outputFunction) {
                                            field.onChange(name)(v)
                                        } else {
                                            if (!inputFunction) return
                                            form.setFieldValue(
                                                name,
                                                outputFunction(v)
                                            )
                                        }
                                    }}
                                    style={[styles.TextInput, style]}
                                    onFocus={() => setHasFocus(true)}
                                    onBlur={handleCancelFieldAssignment}
                                    {...props}
                                />
                                <TouchableOpacity
                                    onPress={handleToggleFocus}
                                >
                                    {hasFocus ?
                                        <AntDesign name="close" size={20} color="rgb(45, 50, 54)" />
                                        :
                                        <AntDesign name="down" color="#000" size={15} />
                                    }
                                </TouchableOpacity>
                            </View>
                            {hasFocus &&
                                filteredData.map((item: any) => (
                                    <TouchableOpacity
                                        key={item.id}
                                        style={styles.listItem}
                                        onPress={() => handleSelectItem(item)}
                                    >
                                        <Text
                                            style={[styles.listItemText, { color: item.name === form.values[name] ? '#F85628' : '#000' }]}
                                        >
                                            {item.name}
                                        </Text>
                                    </TouchableOpacity>
                                ))
                            }
                        </View>
                    )
                }
            }
        </Field>
    )
};

export default InputDataListFormik;

const styles = StyleSheet.create({
    ScrollContainer: {
        height: 100,
        marginBottom: 10
    },
    TextInput: {
        fontSize: 16,
        color: 'rgb(45, 50, 54)',
        flex: 1,
    },
    container: {
        flex: 1,
        flexDirection: 'column',
    },
    containerTextInput: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 5,
        marginVertical: 10
    },
    listItem: {
        padding: 5,
        marginHorizontal: 5,
        backgroundColor: "#f9f9f9",
        borderRadius: 10,
        marginBottom: 5,
        // borderBottomColor: '#000',
        // borderBottomWidth: 1
    },
    listItemText: {
        fontSize: 16,
        color: 'rgb(45, 50, 54)',
        padding: 5,
        paddingLeft: 0,
        flex: 1,
        marginLeft: 20
    }
})
