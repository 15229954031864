import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { ColorSchemeName } from "react-native";
import { RootStackParamList } from "../../types";

import Home from "@src/screens/Home";
import MarketplaceRoutes from "@src/screens/MarketplaceScreens";
import { StoreProvider } from "@contexts/storeContext";

const Navigation: React.FC<{ colorScheme: NonNullable<ColorSchemeName> }> = ({ colorScheme }) => {

  const Stack = createNativeStackNavigator<RootStackParamList>();

  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="home">
        <Stack.Group
          screenOptions={{
            headerShown: false,
          }}
        >
          <Stack.Screen name="marketplace" component={MarketplaceRoutes} />
          <Stack.Screen name="home" component={HomeStoreProvider} />
        </Stack.Group>
      </Stack.Navigator>
    </NavigationContainer>
  )
}

const HomeStoreProvider: React.FC = ({ children }) => {
  return (
    <StoreProvider>
      <Home />
    </StoreProvider>
  )
}

export default Navigation;