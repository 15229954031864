import { Entypo } from '@expo/vector-icons';
import { Link, NavigationProp, useNavigation } from '@react-navigation/native';
import React, { useCallback, useContext, useEffect } from 'react';
import { OrderTypeTakeOutContainer, StoreAdressTakeout, StoreInfoTakeOut, StoreNameTakeOut, TakeOutChangeAddress, TakeOutChangeAddressImage, TakeOutChangeAddressText } from '../styles';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { IOrderType } from './OrderTypeTakeOut';
import { ShoppingCartContext } from '@contexts/shoppingCartContext';
import { StoreContext } from '@contexts/storeContext';
import { HomeStackParamList } from 'types';

import Bike from "@src/assets/images/General/bike.png"
import { IPointsMap } from '../../DeliveryAddressMap';
import { useTheme } from 'styled-components/native';

const OrderTypeDelivery: React.FC<IOrderType> = ({ removeOrderType, addressError, setAddressError }) => {

    const { shoppingCart } = useContext(ShoppingCartContext)
    const { storeData } = useContext(StoreContext)
    const { colors: { primary } } = useTheme()

    const navigation = useNavigation<NavigationProp<HomeStackParamList>>()

    const address = shoppingCart?.orderInfo?.address

    const streetAndNumber = useCallback(() => {
        const hasStreetNumber = address?.streetNumber !== undefined
        const streetNumber = `, ${address?.streetNumber}`

        return `${address?.streetName}${hasStreetNumber && streetNumber}`

    }, [shoppingCart])

    useEffect(() => {

        (async () => {
            try {
                // if(!address) return removeOrderType()
                const data = await AsyncStorage.getItem('recent_address')
                if (!data && storeData.deliveryType === 'NEIGHBORHOOD') {
                    navigation.navigate('add-new-address', {} as IPointsMap)
                    return
                }
                if (!data) {
                    navigation.navigate('delivery-address-screen')
                    setAddressError({ hasError: true, message: 'Você precisa selecionar um endereço para continuar' })
                    return
                }
                const recent_address = JSON.parse(data)
                const result = recent_address.filter((x: { neighborhood: string }) => {
                    return storeData.neighborhood.filter((neighborhood, i) => {
                        // console.log(neighborhood.name === x.neighborhood)
                        return neighborhood.name?.toLowerCase() === x.neighborhood?.toLowerCase()
                    })
                })
                if (!result.length && storeData.deliveryType === "NEIGHBORHOOD") {
                    setAddressError({ hasError: true, message: 'A loja não realiza entregas para esse bairro!' })
                    navigation.navigate('add-new-address', {} as IPointsMap)
                    return
                }
            } catch {

            }
        })()
    }, [shoppingCart])

    return (

        <OrderTypeTakeOutContainer>

            <TakeOutChangeAddress
                onPress={removeOrderType}
            >
                <TakeOutChangeAddressImage
                    resizeMode='contain'
                    source={Bike}
                    style={{ tintColor: primary }}
                />
                <TakeOutChangeAddressText>Receber em casa</TakeOutChangeAddressText>
                <Entypo name="chevron-thin-down" size={16} color={primary} />
            </TakeOutChangeAddress>
            {
                address ?
                    <StoreInfoTakeOut>
                        <StoreNameTakeOut>{streetAndNumber()}</StoreNameTakeOut>
                        <StoreAdressTakeout>
                            {`${address?.complement}, ${address?.neighborhood}`}
                        </StoreAdressTakeout>
                    </StoreInfoTakeOut>
                    :
                    <StoreInfoTakeOut>

                    </StoreInfoTakeOut>
            }

            <Link style={{
                color: 'rgb(5, 52, 89)',
                fontFamily: 'Inter-SemiBold',
                fontSize: 14,
                marginTop: 30
                //@ts-ignore
            }} to={{ screen: 'address-list' }}>
                Alterar endereço de entrega
            </Link>

        </OrderTypeTakeOutContainer>


    )
};

export default OrderTypeDelivery;
