import React from "react";
import { Image, Text, View } from "react-native";
import ImageNotFound from "../../assets/images/General/404.svg"
import FooterButton from "../LargeButton";

interface INotFoundProps {
    title: string
    description?: string
}

const NotFound: React.FC<INotFoundProps> = ({ title, description }) => {

    return (
        <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "#fff" }}>
            <Text style={{ fontSize: 24, fontWeight: "600" }}>{title}</Text>
            <Image
                source={ImageNotFound}
                style={{
                    width: '100%',
                    height: 300,
                    borderRadius: 10,
                    overflow: 'hidden',
                    resizeMode: 'contain',
                }}
            />
            <Text style={{ fontSize: 18, textAlign: "center", padding: 20 }}>
                {description}
            </Text>
            <FooterButton
                title="Voltar"
                
            />
        </View>
    )
}

export default NotFound