import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import * as yup from 'yup'
import { Formik } from 'formik'
import {
  CheckButton,
  CheckTitle,
  DualComponentContainer,
  FormGroup,
  FormTitle,
  Scroll
} from './styles';
import { AntDesign } from '@expo/vector-icons';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { StatesData } from './statesData';
import { StoreContext } from '@contexts/storeContext';
import { UserContext } from '@contexts/userContext';
import { HomeStackParamList } from 'types';
import InputMask, { removeSpecialCharacters } from '@components/FormComponents/InputMask';
import Feedback from '@components/FormComponents/Feedback';
import InputTextFormik from '@components/FormComponents/InputTextFormik';
import InputDataListFormik from '@components/FormComponents/InputDataListFormik';
import InputPickerFormik from '@components/FormComponents/InputPickerFormik';
import FooterButton from '@components/LargeButton';
import { IPointsMap } from '../DeliveryAddressMap';
import uuid from 'react-native-uuid';

interface IAddNewAddressProps {
}

const validationSchema = yup.object().shape({
  streetName: yup.string().required('A rua é um campo necessário'),
  streetNumber: yup.string().optional(),
  neighborhood: yup.string().required('O bairro é um campo necessário'),
  complement: yup.string().optional(),
  postalCode: yup.string().optional(),
  city: yup.string().required('Cidade é necessária'),
  state: yup.string().required('Estado é necessário')
})

const AddNewAddress: React.FunctionComponent<IAddNewAddressProps> = (props: any) => {

  const { storeData } = useContext(StoreContext)
  const { setRecentAddress } = useContext(UserContext)

  const formRef = useRef<any>()
  const navigation = useNavigation<NavigationProp<HomeStackParamList>>()

  const points: IPointsMap = props.route.params

  useEffect(() => {
    console.log(points)
  }, [points])

  const [hasNumber, setHasNumber] = useState<boolean>(false)

  const toggleHasNumber = () => {
    formRef.current.setFieldValue('streetNumber', '')
    setHasNumber(x => !x)
  }

  const handleSubmit = async (data: any) => {

    data = { ...data, postalCode: removeSpecialCharacters(data.postalCode), latitude: points.latitude, longitude: points.longitude, id: uuid.v4() }

    try {
      const addressList = await AsyncStorage.getItem('recent_address')
      let newData: any = JSON.stringify([data])

      if (addressList !== null && (JSON.parse(addressList).length > 0)) {
        newData = JSON.stringify([...JSON.parse(addressList), data])
      }
      //@ts-ignore
      setRecentAddress(prev => ([...prev, data]))
      await AsyncStorage.setItem('recent_address', newData)
    } catch {
      console.log('deu errado ;-;')
    }

    navigation.navigate('address-list')
  }

  return (
    <View style={{ display: 'flex', flex: 1, backgroundColor: '#fff' }}>
      <Scroll showsVerticalScrollIndicator={false}>
        <Formik
          innerRef={formRef}
          validationSchema={validationSchema}
          initialValues={{
            streetName: points?.street || '',
            streetNumber: points?.streetNumber || '',
            neighborhood: points?.district || '',
            reference: "",
            complement: "",
            postalCode: '',
            city: points?.subregion || storeData.city || '',
            state: points?.stateIsoCode || storeData.state || '',
            country: "BR"
          }}
          onSubmit={(data) => handleSubmit(data)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => {

            const [cityList, setCityList] = useState<Array<any>>([])

            const getAddressByCep = useCallback(async (cep: string) => {
              try {
                const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
                formRef.current.setFieldValue('city', data.localidade)
                formRef.current.setFieldValue('neighborhood', data.bairro)
                formRef.current.setFieldValue('state', data.uf)
                formRef.current.setFieldValue('streetName', data.logradouro)
              } catch {
                await new Promise(resolve => setTimeout(resolve, 500))
                getAddressByCep(cep)
              }
            }, [values.postalCode])

            const getCitiesByState = useCallback(async () => {
              const { data } = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${values.state}/municipios`)
              setCityList(data)
            }, [values.state])


            useEffect(() => {
              if (values.postalCode.length === 8) {
                getAddressByCep(values.postalCode)
              }
            }, [values.postalCode])

            useEffect(() => {
              getCitiesByState()
            }, [values.state])

            useEffect(() => {
              if (points?.postalCode) {
                formRef.current.setFieldValue('postalCode', points.postalCode)
                getAddressByCep(points.postalCode)
              }
            }, [])

            return (
              <View>
                <FormGroup
                  invalid={errors.postalCode && touched.postalCode}
                >
                  <FormTitle>CEP</FormTitle>
                  <InputMask
                    placeholder="CEP (Código Postal) (opcional)"
                    name='postalCode'
                    mask='99999-999'
                    keyboardType="numeric"
                  />
                </FormGroup>
                <Feedback name='postalCode' />

                <FormGroup
                  invalid={errors.streetName && touched.streetName}
                >
                  <FormTitle>Rua</FormTitle>
                  <InputTextFormik
                    name='streetName'
                    type='text'
                    placeholder='Rua'
                  />
                </FormGroup>
                <Feedback name='streetName' />

                <DualComponentContainer>
                  <FormGroup
                    style={{ flex: 6 }}
                    invalid={errors.streetNumber && touched.streetNumber}
                  >
                    <FormTitle>Número</FormTitle>
                    <InputTextFormik
                      name='streetNumber'
                      type='text'
                      placeholder='Número'
                      keyboardType="numeric"
                      editable={!hasNumber}
                    />
                  </FormGroup>
                  <CheckButton
                    check={hasNumber}
                    onPress={toggleHasNumber}
                  >
                    <AntDesign name="check" size={24} color="#F0F3F5" />
                  </CheckButton>
                  <CheckTitle>Sem número</CheckTitle>
                </DualComponentContainer>
                <Feedback name='number' />

                {storeData.deliveryType === 'NEIGHBORHOOD' ?
                  <>
                    <FormGroup
                      invalid={errors.neighborhood && touched.neighborhood}
                    >


                      <FormTitle>Bairro</FormTitle>
                      <InputDataListFormik
                        name='neighborhood'
                        type='text'
                        placeholder='Selecione um bairro'
                      />
                    </FormGroup>
                    <Feedback name='neighborhood' />
                  </>
                  :
                  <>
                    <FormGroup
                      invalid={errors.neighborhood && touched.neighborhood}
                    >


                      <FormTitle>Bairro</FormTitle>
                      <InputTextFormik
                        name='neighborhood'
                        type='text'
                        placeholder='Bairro'
                      />
                    </FormGroup>
                    <Feedback name='neighborhood' />
                  </>
                }

                <FormGroup
                  invalid={errors.reference && touched.reference}
                >
                  <FormTitle>Referência</FormTitle>
                  <InputTextFormik
                    name='reference'
                    type='text'
                    placeholder='Ponto de referência (opcional)'
                  />
                </FormGroup>
                <Feedback name='complement' />

                <FormGroup
                  invalid={errors.complement && touched.complement}
                >
                  <FormTitle>Complemento</FormTitle>
                  <InputTextFormik
                    name='complement'
                    type='text'
                    placeholder='Complemento (opcional)'
                  />
                </FormGroup>
                <Feedback name='complement' />

                <FormGroup
                  invalid={errors.state && touched.state}
                >
                  <FormTitle>UF</FormTitle>
                  {/* <InputMask
                      mask='aa'
                      name='state'
                      placeholder='MA'
                    /> */}
                  <InputPickerFormik
                    name='state'
                    data={StatesData}
                  />
                </FormGroup>
                <Feedback name='state' />

                <FormGroup
                  style={{ flex: 7 }}
                  invalid={errors.city && touched.city}
                >
                  <FormTitle>Cidade</FormTitle>
                  {/* <InputFormik
                    name='city'
                    type='text'
                    placeholder='Cidade'
                  /> */}
                  <InputPickerFormik
                    name="city"
                    data={cityList.map((item) => ({ id: item.id, label: item.nome, value: item.nome }))}
                    noDataMessage="Selecione um estado"
                  />
                </FormGroup>
                <Feedback name='city' />
              </View>
            )
          }}

        </Formik>
      </Scroll>
      <FooterButton
        title='Adicionar endereço'
        onPress={() => formRef.current.submitForm()}
      />
    </View>

  )
};

export default AddNewAddress;
