import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import React, { FC, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import LoadingSpinner from '../LoadingSpinner';
import {
    AlertBody,
    AlertButton,
    AlertButtonText,
    AlertContainer,
    AlertMessageText,
    AlertTitle,
    BackGround,
    ButtonContainer,
    CenteredView,
    Icon
} from './styles';

interface ISweetAlertProps {
    title?: string,
    text?: string,
    icon: "success" | "error" | "question",
    buttons: Array<{
        text: string,
        type?: "default" | "cancel" | "confirm",
        onPress?: () => any
    }>
}

const alertIcons = {
    success: <MaterialIcons name="done" size={80} color="#22ff59" />,
    error: <MaterialIcons name="error-outline" size={80} color="#ff534db3" />,
    question: <AntDesign name="question" size={80} color="black" />
}

const SweetAlert: FC<ISweetAlertProps> = ({ children, ...props }) => {


    const [visible, setVisible] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const toggle = () => setVisible(x => !x)

    const onPressFunction = (item: ISweetAlertProps["buttons"][0]) => {
        if (item.type === 'cancel') {
            toggle()
            return
        }
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            if (!item.onPress) {
                toggle()
                return
            }
            item.onPress()
            toggle()
        }, 500)
    }

    return (
        <>
            <TouchableOpacity
                onPress={toggle}
            >
                {children}
            </TouchableOpacity>
            <AlertContainer
                {...props}
                visible={visible}
                transparent
                animationType='fade'
            >
                <CenteredView>
                    <BackGround onPress={toggle} />
                    <AlertBody>
                        {loading ?
                            <LoadingSpinner />
                            :
                            <>
                                <Icon>
                                    {alertIcons[props.icon]}
                                </Icon>
                                <AlertTitle>
                                    {props.title}
                                </AlertTitle>
                                <AlertMessageText>
                                    {props.text}
                                </AlertMessageText>
                                <ButtonContainer>
                                    {props.buttons?.map(item => {
                                        return (
                                            <AlertButton
                                                type={item?.type || 'default'}
                                                key={item.text}
                                                onPress={() => onPressFunction(item)}
                                            >
                                                <AlertButtonText>{item.text}</AlertButtonText>
                                            </AlertButton>

                                        )
                                    })}
                                </ButtonContainer>
                            </>
                        }
                    </AlertBody>

                </CenteredView>

            </AlertContainer>
        </>

    )
};

export default SweetAlert;
