import React, { useMemo } from 'react';
import { View } from 'react-native';
import { SectionTitle } from '../styles';
import ProductSimple from './ProductSimple';

interface ISectionCategoryProps {
    category: any;
    onLayout: any;
}

const SectionCategory: React.FC<ISectionCategoryProps> = ({ category, onLayout }) => {

    return useMemo(() => {
        return (
            <View onLayout={onLayout}>
                <SectionTitle>{category.name}</SectionTitle>
                {
                    [...category.products].sort((a, b) => a.sort - b.sort).map((product: any) =>
                        <ProductSimple
                            item={product}
                            key={product.id}
                        />
                    )
                }
            </View>

        )

    }, [])
};

export default SectionCategory;
