import UPImage, { UPImage as UPImageInterface } from '@components/Image';
import React, { useCallback, useMemo } from 'react';

interface IImageWithPlaceholderProps extends Partial<UPImageInterface> {
    image: any,
    placeholder: any,
    style?: any
}

const ImageWithPlaceholder: React.FunctionComponent<IImageWithPlaceholderProps> = ({ image, placeholder, ...props }) => {

    const ConvertImageWithPlaceHolder = useCallback((placeholder: any, image: any) => {

        if (!image) {
            return placeholder
        }
        return {
            uri: image
        }

    }, [])


    return (
        <UPImage
            {...props}
            source={ConvertImageWithPlaceHolder(placeholder, image)}
        />
    )
};

export default ImageWithPlaceholder;
