import { AntDesign } from '@expo/vector-icons';
import React, { Dispatch, SetStateAction, useCallback, useMemo, useRef, useState } from 'react';
import { Platform, StyleSheet, TouchableOpacity, useWindowDimensions, View } from 'react-native';
import Animated, {
  Easing,
  useAnimatedStyle,
  withTiming
} from 'react-native-reanimated';
import { HeaderActionButton, SearchInputText } from '../styles';


interface ISearchInputProps {
  setSearch: Dispatch<SetStateAction<string>>,
  search: string
}

const SearchInput: React.FunctionComponent<ISearchInputProps> = ({ setSearch, search }) => {

  const { width } = useWindowDimensions()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const config = {
    duration: 500,
    easing: Easing.bezier(0.5, 0.01, 0, 1),
  };

  const toggleSearchInput = () => {
    setIsOpen(x => !x)
    setSearch('')
  }

  const handleChangeInput = useCallback((e) => {
    setSearch(e)
  }, [])


  const toggleSearchInputStyle = useAnimatedStyle(() => {

    return {
      width: withTiming(isOpen ? 0.5 * width : 0.1, config)
    }
  })

  return useMemo(() => {
    return (
      <View style={styles.container}>
        <Animated.View style={[styles.inputView, toggleSearchInputStyle]}>
          {search.length >= 1 &&
            <TouchableOpacity style={{ marginLeft: 10 }}
              onPress={() => setSearch('')}
            >
              <AntDesign name="close" color="#000" size={20} />
            </TouchableOpacity>
          }
          <SearchInputText
            placeholder='Busque por itens'
            onChangeText={handleChangeInput}
            value={search}
          />

        </Animated.View>

        <HeaderActionButton onPress={toggleSearchInput}>
          <AntDesign name='search1' color="#000" size={20} />
        </HeaderActionButton>
      </View>
    )
  }, [search])

};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: Platform.OS === 'android' ? 70 : 40,
    right: 10,
    flexDirection: 'row',
    borderRadius: 10,
    backgroundColor: '#fff'
  },
  inputView: {
    width: 50,
    overflow: 'hidden',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },

})

export default SearchInput;
