import { AntDesign } from "@expo/vector-icons";
import styled from "styled-components/native";


export const OrderUserContainer = styled.View`
    margin: 20px;
    margin-bottom: 0px;
`
export const OrderItem = styled.TouchableOpacity`
    display: flex;
    border-radius: 10px;
    background-color: #fff;
    padding: 10px 15px;
`

export const Icon = styled(AntDesign)``;

export const OrderDescriptionRow = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 7px 5px;
`
export const TextWithIconContainer = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
`

export const SimpleTextOrder = styled.Text`
    font-family: 'Inter-Regular';
    color: black;
    font-size: 14px;
    align-items: center;
    justify-content: center;
`

export const OrderStatusContainer = styled.View`
    /* background-color: #50ff5089;
    border-radius: 5px;
    padding: 5px; */
    align-items: center;
    justify-content: center;

`

export const DateDescription = styled.Text`
    font-size: 13px;
    font-family: 'Inter-Regular';
    color: #000;
    margin-bottom: 10px;
    margin-left: 10px;

`