import styled from "styled-components/native";
import { height, width } from "../../utils";

interface IAlertButton {
    type?: string
}

export const AlertContainer = styled.Modal`
    display: flex;
    flex: 1;
`
export const CenteredView = styled.View`
    display: flex;
    position: relative;
    align-items: center;
    justify-content:center;
    flex: 1;
`

export const BackGround = styled.Pressable`
    width: 100%;
    height: 100%;
    background-color: #00000068;
    position: absolute;
    z-index: 1;
`

export const AlertBody = styled.View`
    display: flex;
    background-color: white;
    padding: 30px 8%;
    border-radius: 5px;
    max-width: ${width * 0.9}px;
    min-width: ${width / 2}px;
    min-height: ${0.6 * height}px;
    position: absolute;
    z-index: 2;
`
export const Icon = styled.View`
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 120px;
    align-self: center;
`

export const AlertTitle = styled.Text`
    font-size: 28px;
    font-family: "Inter-Regular";
    color: #4b4b5a;
    text-align: center;
`

export const AlertMessageText = styled.Text`
    font-size: 16px;
    font-family: 'Inter-Regular';
    color: #545454;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 30px;
`
export const ButtonContainer = styled.View`
    position: absolute;
    bottom: 20px;
    align-self: center;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
`
export const AlertButton = styled.Pressable<IAlertButton>`
    background-color: ${props => props.type === "default" ? '#2B77C0' : props.type === "confirm" ? props.theme.colors.primary : '#cbd2d6'};
    max-width: 45%;
    border-radius: 5px;
    padding: 12px;

`
export const AlertButtonText = styled.Text`
    font-size: 16px;
    font-family: 'Inter-Regular';
    color: #fff;
    text-align: center;

`