import React, { useRef } from 'react';
import { Animated, PanResponder, StyleSheet } from 'react-native';
import { height } from '../../utils';


const cardHeight = height * 0.7
export const finalPosition = cardHeight / 1.5

const SwipeableBottomCard: React.FC = ({children}) => {

    let panValue = { x: 0, y: finalPosition }

    const pan = useRef(new Animated.ValueXY({ x: 0, y: finalPosition })).current;

    pan.addListener(value => {
        panValue = value
    })

    const translateBtn = pan.y.interpolate({
        inputRange: [0, finalPosition],
        outputRange: [0, finalPosition],
        extrapolate: 'clamp'
    })

    const panResponder = useRef(
        PanResponder.create({
            onMoveShouldSetPanResponder: () => true,
            onPanResponderGrant: () => {
                pan.setValue({ x: panValue.x, y: panValue.y })
                pan.setOffset({ x: panValue.x, y: panValue.y })
            },
            onPanResponderMove: Animated.event([null, { dy: pan.y, dx: pan.x }], {
                useNativeDriver: false,
            }),
            onPanResponderRelease: (e, g) => {
                pan.flattenOffset()
                if (g.vy > 1 || g.dy > finalPosition / 2) {
                    Animated.spring(pan, {
                        toValue: { x: 0, y: finalPosition },
                        useNativeDriver: true,
                        bounciness: 0
                    }).start()
                    return
                }
                Animated.spring(pan, {
                    toValue: { x: 0, y: 0 },
                    useNativeDriver: true,
                    bounciness: 0
                }).start()
            }
        }),
    ).current;

    return (
        <Animated.View style={[styles.bar,
        { transform: [{ translateY: translateBtn }] }
        ]} {...panResponder.panHandlers}>
            {children}
        </Animated.View>
    )
}

const styles = StyleSheet.create({
    bar: {
        position: 'absolute',
        minHeight: cardHeight,
        width: '100%',
        backgroundColor: 'red',
        elevation: 3,
        bottom: 0,
        alignItems: 'center',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        shadowOffset: {
            width: 1,
            height: 1
        },
        shadowOpacity: 0.5,
        shadowRadius: 20
    }
})

export default SwipeableBottomCard