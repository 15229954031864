import { useNavigation } from '@react-navigation/native';
import React, { useContext, useMemo } from 'react';
import { ShoppingCartContext } from '../../contexts/shoppingCartContext';
import { ConvertToBRL } from '../../utils';
import {
    AmountItens,
    LeftContent,
    TitleText,
    TotalText,
    Container
} from '@src/screens/Home/Store/styles'

interface IBagProduct {
    target: any
}

const BagProduct: React.FC<IBagProduct> = ({ target }) => {

    const navigation = useNavigation()
    const { shoppingCart, totalValue } = useContext(ShoppingCartContext)

    return useMemo(() => {
        return (
            shoppingCart.products?.length ?
                <Container
                    onPress={() => navigation.navigate(target, target === 'home' && { screen: 'shoppingbag' })}
                    activeOpacity={0.8}>
                    <LeftContent >

                        <TitleText>Ver Sacola</TitleText>

                        <AmountItens>{shoppingCart.products.reduce((total, item) => {
                            return total + item.amount
                        }, 0)} itens</AmountItens>
                        <TotalText>
                            {ConvertToBRL(totalValue)}
                        </TotalText>

                    </LeftContent>

                </Container>
                :
                <>
                </>
        )
    }, [totalValue, shoppingCart])
};

export default BagProduct;