import LoadingSpinner from '@components/LoadingSpinner';
import MapComponent from '@components/Map';
import Marker from '@components/Map/Marker';
import { StoreContext } from '@contexts/storeContext';
import React, { useContext } from 'react';

interface IEstablishmentMapViewProps {
}

const EstablishmentMapView: React.FC<IEstablishmentMapViewProps> = (props) => {

  const { storeData } = useContext(StoreContext)

  const initialPosition = [storeData?.latitude, storeData?.longitude]

  return (
    <>
      {!initialPosition ?
        <LoadingSpinner />
        :
        <MapComponent initialPosition={initialPosition}>
          <Marker latitude={storeData?.latitude} longitude={storeData?.longitude} />
        </MapComponent>
      }
    </>
  )
};

export default EstablishmentMapView;