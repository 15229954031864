import { NavigationProp, useNavigation } from '@react-navigation/native';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Alert, StyleSheet } from 'react-native';
import * as Location from 'expo-location';
import {
    AddressLocationText,
    ButtonCurrentLocation,
    ButtonCurrentLocationText,
    ConsistentMarker,
    ContainerButton
} from './styles';
import MapComponent from '@components/Map';
import { HomeStackParamList } from 'types';
import PinMarker from '@src/assets/images/others/pin-marker.png'
import LoadingSpinner from '@components/LoadingSpinner';
import { StoreContext } from '@contexts/storeContext';
import axios from 'axios';

type IInitialPosition = {
    latLng: {
        lat: Function,
        lng: Function,
    }
    LatLng: any
};

export interface IPointsMap {
    id: number,
    latitude: number,
    longitude: number,
    subregion: string,
    region: string,
    postalCode: string,
    district: string,
    street: string,
    streetNumber: string,
    complement?: string,
    reference?: string,
    state: string,
    stateIsoCode?: string;
    city: string,
}

interface IDeliveryAddressMapProps {
}

const DeliveryAddressMap: React.FunctionComponent<IDeliveryAddressMapProps> = (props) => {

    const { storeData } = useContext(StoreContext)

    const navigation = useNavigation<NavigationProp<HomeStackParamList>>()

    const [points, setPoints] = useState<IPointsMap>({} as IPointsMap);
    const [initialPosition, setInitialPosition] = useState<[number, number]>([
        0,
        0,
    ]);
    const [loading, setLoading] = useState(true);

    const reservesGeocoderAsync = useCallback(
        async (latitude: number, longitude: number) => {
            const result = (await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${latitude},${longitude}&key=AIzaSyDOb_w1U2pY3Io0b60Wk5frHIeYhy3ETBs`)).data

            return {
                country: result.results[0].address_components.find((item: any) => item.types.includes("country"))?.long_name,
                district: result.results[0].address_components.find((item: any) => item.types.includes("sublocality_level_1"))?.long_name,
                isoCountryCode: result.results[0].address_components.find((item: any) => item.types.includes("country"))?.short_name,
                name: result.results[0].address_components.find((item: any) => item.types.includes("route"))?.long_name,
                postalCode: result.results[0].address_components.find((item: any) => item.types.includes("postal_code"))?.long_name,
                region: result.results[0].address_components.find((item: any) => item.types.includes("administrative_area_level_1"))?.long_name,
                street: result.results[0].address_components.find((item: any) => item.types.includes("route"))?.long_name,
                subregion: result.results[0].address_components.find((item: any) => item.types.includes("administrative_area_level_2"))?.long_name,
                streetNumber: result.results[0].address_components.find((item: any) => item.types.includes("street_number"))?.long_name,
                stateIsoCode: result.results[0].address_components.find((item: any) => item.types.includes("administrative_area_level_1"))?.short_name
            };
        }
        , []
    );

    const getPosition = useCallback((latitude: number, longitude: number) => {

        const point: any = {
            id: 1,
            latitude,
            longitude
        };

        setPoints(point)
        setInitialPosition([latitude, longitude]);
        setLoading(false);
    }, [])

    const getStoreAddress = useCallback(async () => {
        if (!storeData?.latitude || !storeData?.longitude) {
            navigation.navigate('add-new-address', {} as IPointsMap)
            return
        }
        getPosition(storeData.latitude, storeData.longitude)
    }, [storeData])

    useEffect(() => {
        async function loadPosition(): Promise<void> {

            const { status } = await Location.requestForegroundPermissionsAsync();

            if (status !== "granted") {
                await getStoreAddress()
                return
            }

            try {
                const location = await Location.getCurrentPositionAsync({
                    accuracy: 6,
                    mayShowUserSettingsDialog: true,
                    timeInterval: 300,
                    distanceInterval: 20,
                });

                const { latitude, longitude } = location.coords;
                getPosition(latitude, longitude)
            } catch (error) {
                getStoreAddress()
            }
        }

        loadPosition();
    }, []);

    // const onDragEnd = useCallback(
    //     async (coords: IInitialPosition) => {



    //         if (!coords) return
    //         const latitude = coords.LatLng.lat()
    //         const longitude = coords.LatLng.lng()

    //         setInitialPosition([latitude, longitude]);
    //         const addressGoogle = await reservesGeocoderAsync(latitude, longitude);

    //         const point: any = {
    //             id: 1,
    //             latitude,
    //             longitude,
    //             ...addressGoogle,
    //         };

    //         setPoints(point);
    //     },
    //     [],
    // );

    const handleNavigationAddAddress = useCallback(() => {
        if (!points || !points.subregion) {
            return Alert.alert(
                'Atenção',
                'Selecione um endereço de entrega no Maps',
                [
                    {
                        onPress: () => console.log('ok'),
                        text: 'Ok',
                        style: 'default',
                    },
                ],
            );
        }


        navigation.navigate('add-new-address', { ...points });
    }, [navigation, points]);

    const HandlePositionOnChangeMap = async (e: { latitude: number, longitude: number }) => {

        // const correctCenter = 0.005
        const addressGoogle = await reservesGeocoderAsync(e.latitude, e.longitude);

        const point: any = {
            id: 1,
            latitude: e.latitude,
            longitude: e.longitude,
            ...addressGoogle,
        };

        setPoints(point)
    }

    if (loading) return <LoadingSpinner />

    return (
        <>
            <ConsistentMarker
                source={PinMarker}
                resizeMode="contain"
            />
            <MapComponent
                loading={loading}
                initialPosition={initialPosition}
                onRegionChangeComplete={HandlePositionOnChangeMap}
            />


            {points && (
                <ContainerButton>
                    <AddressLocationText style={styles.street}>
                        {points.subregion} -{' '}
                        {points.region?.slice(0, 2).toUpperCase()},{' '}
                        {points.postalCode}
                    </AddressLocationText>
                    <AddressLocationText style={styles.description}>
                        {`${points.street}, ${points.district}`}
                    </AddressLocationText>

                    <ButtonCurrentLocation onPress={handleNavigationAddAddress}>
                        <ButtonCurrentLocationText>
                            Estou neste local
                        </ButtonCurrentLocationText>
                    </ButtonCurrentLocation>
                </ContainerButton>
            )}
        </>

    )
};

const styles = StyleSheet.create({
    street: {
        fontFamily: 'Inter-SemiBold',
        fontSize: 16,
    },
    description: {
        fontFamily: 'Inter-Regular',
        fontSize: 16,
    }
});

export default DeliveryAddressMap;
