import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { MarketPlaceStackParamList } from "types";
import { MarketPlace } from "./Marketplace";
import SearchStoreScreen from "./SearchStoreScreen";

const MarketplaceRoutes: React.FC = () => {

    const Stack = createNativeStackNavigator<MarketPlaceStackParamList>();

    return (
        <Stack.Navigator>
            <Stack.Screen
                name="index"
                component={MarketPlace}
                options={{
                    headerShown: false,
                    title: "Upanda - Compre com segurança e qualidade",
                }}
            />
            <Stack.Screen
                options={{
                    title: "Pesquisar lojas"
                }}
                name="search"
                component={SearchStoreScreen}
            />
        </Stack.Navigator>
    )
}

export default MarketplaceRoutes