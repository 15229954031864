import React, { useContext } from "react";
import { StoreContext, StoreProvider } from "@contexts/storeContext";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import CleanShoppingBagButton from "@src/navigation/components/CleanShoppingBagButton";
import { Platform } from "react-native";
import { DrawerList, HomeStackParamList } from "../../../types";
import AddContactData from "./AddContactData";
import AddNewAddress from "./AddNewAddress";
import AddressList from "./AddressList";
import DeliveryAddressMap from "./DeliveryAddressMap";
import EstablishmentMapView from "./EstablishmentMap";
import FollowOrder from "./FollowOrder";
import PaymentMethod from "./PaymentMethod";
import ProductDetails from "./ProductDetails";
import ShoppingBag from "./ShoppingBag";
import Store from "./Store";
import UserOrders from "./UserOrders";

let slug: string = "Loja"
if (Platform.OS === "web") {
    slug = localStorage.getItem("STORE_SLUG") as string
}

const HomeStack: React.FC = () => {

    const Stack = createNativeStackNavigator<HomeStackParamList>();
    const { storeData } = useContext(StoreContext)
    
    return (
        <Stack.Navigator initialRouteName="store">
            <Stack.Screen
                name="index"
                component={Store}
                options={{
                    headerShown: false,
                    title: storeData?.storeName || "Upanda",
                }}
            />
            <Stack.Screen
                name="shoppingbag"
                component={ShoppingBag}
                options={{
                    title: "Sua mochila",
                    headerRight: CleanShoppingBagButton,
                    headerTitleAlign: "center"
                }}
            />
            <Stack.Screen
                name="product-details"
                component={ProductDetails}
                options={{
                    title: "Detalhes do produto",
                }}
            />
            <Stack.Screen
                name="address-list"
                component={AddressList}
                options={{
                    title: "Selecione um endereço",
                }}
            />
            <Stack.Screen
                name="add-new-address"
                component={AddNewAddress}
                options={{
                    title: "Adicionar novo endereço",
                }}
            />
            <Stack.Screen
                name="establishment-map-screen"
                component={EstablishmentMapView}
                options={{
                    title: "Loja no mapa",
                }}
            />
            <Stack.Screen
                name="delivery-address-screen"
                component={DeliveryAddressMap}
                options={{
                    title: "Selecione um endereço",
                }}
            />

            <Stack.Screen
                name="payment-method"
                component={PaymentMethod}
                options={{
                    title: "Selecione um método de pagamento",
                }}
            />
            <Stack.Screen
                name="add-contact-data"
                component={AddContactData}
                options={{
                    title: "Adicione seus dados de contato",
                }}
            />
            <Stack.Screen
                name="order"
                component={FollowOrder}
                options={{
                    title: "Acompanhe seu pedido",
                    headerShown: false
                }}
            />
        </Stack.Navigator>
    )
}

const Home: React.FC = () => {

    const DrawerNav = createDrawerNavigator<DrawerList>();

    return (
        <DrawerNav.Navigator initialRouteName="store">
            <DrawerNav.Screen
                name="store"
                component={HomeStack}
                options={{
                    title: "Página da loja",
                    headerShown: false
                }}
            />
            <DrawerNav.Screen
                name="orders"
                component={UserOrders}
                options={{
                    title: "Meus pedidos",
                    headerTitleAlign: "center"
                }}
            />

        </DrawerNav.Navigator>
    )
}

export default Home