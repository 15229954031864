import React from 'react';
import { Field } from 'formik';

import { StyleSheet, TextStyle, TextInput } from 'react-native';

interface IInputTextFormikProps {
    name: string
    type?: string
    placeholder?: string
    style?: TextStyle,
    inputFunction?: (v: any) => any,
    outputFunction?: (v: any) => any,
    mask?: string,
    keyboardType?: any,
    editable?: boolean
}

const InputTextFormik: React.FunctionComponent<IInputTextFormikProps> = ({
    name, type, style,
    inputFunction, mask,
    outputFunction, ...props }) => {

    return (
        <Field name={name} type={type} {...props}>
            {
                ({
                    field,
                    form,
                    meta
                }: any) =>
                    <TextInput
                        value={!inputFunction ? field.value :
                            inputFunction(field.value)
                        }
                        onChangeText={(v) => {
                            if (!outputFunction) {
                                field.onChange(name)(v)
                            } else {
                                if (!inputFunction) return
                                form.setFieldValue(
                                    name,
                                    outputFunction(v)
                                )
                            }
                        }}
                        style={[styles.TextInput, style]}
                        {...props}
                    />
            }
        </Field>
    )
};

export default InputTextFormik;

const styles = StyleSheet.create({
    TextInput: {
        fontSize: 16,
        color: 'rgb(45, 50, 54)',
        padding: 5,
        paddingLeft: 0
    }
})
