import styled from "styled-components/native";

export const ScrollFollowOrder = styled.ScrollView.attrs({
  contentContainerStyle: {
    paddingVertical: 0,
    paddingHorizontal: 0,
  }
})`
    display: flex;
    flex: 1;
    flex-direction: column;
    background: transparent;
`;

export const TopIndicatorProductId = styled.Text`
    color: rgb(5, 52, 89);
    font-size: 12px;
    font-family: 'Inter-SemiBold';
    padding: 8.5px 10px;
    background-color: rgb(245, 249, 252);
    margin-top: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    align-self: center;
`

export const OrderProductListContainer = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
`
export const OrderProductItem = styled.View`
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
`
export const OrderProductDetails = styled.View`
    display: flex;
    flex: 1;
    flex-direction: row;
`

export const OrderProductImage = styled.Image`
    width: 48px;
    height: 48px;
    border-radius: 10px;
    margin-right: 20px;
`
export const OrderProductDescription = styled.View`
    display: flex;
    flex-direction: column;
`
export const OrderProductName = styled.Text`
    color: rgb(45, 50, 54);
    font-size: 14px;
    font-family: 'Inter-SemiBold';
`

export const OrderProductPrice = styled.Text`
    color: rgb(5, 52, 89);
    font-size: 12px;
    font-family: 'Inter-SemiBold';
`

export const AmountIndicator = styled.Text`
    color: rgb(61, 67, 71);
    padding: 10px;
    background-color: rgb(247, 249, 250);
    font-size: 14px;
    font-family: 'Inter-Regular';
    border-radius: 10px;
    align-self: center;
`

export const PaymentMethodCard = styled.View`
    border: solid 1px rgb(240, 243, 245);
    align-items: flex-start;
    width: 90%;
    padding: 30px 20px;
    border-radius: 10px;
    align-self: center;
`
export const PaymentMethodTitle = styled.Text`
    font-size: 14px;
    font-family: 'Inter-SemiBold';
    color: ${props => props.theme.colors.primary};
`

export const PaymentMethodMoment = styled.Text`
    color: rgb(61, 67, 71);
    font-size: 14px;
    font-family: 'Inter-SemiBold';
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const PaymentPricesContainer = styled.View`
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-top: 40px;
    margin-bottom: 20px;
    align-self: center;
`
export const PaymentPriceRow = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2px;
`

export const PaymentPriceTypeText = styled.Text`
    font-family: 'Inter-Regular';
    color: rgb(167, 176, 184);
    font-size: 14px;
`

export const SubPricesText = styled.Text`
    color: rgb(103, 111, 117);
    font-family: 'Inter-Regular';
    font-size: 14px;

`
export const TotalPriceText = styled.Text`
    font-family: 'Inter-SemiBold';
    color: rgb(61, 67, 71);
    font-size: 18px;
`

export const SwipeableHook = styled.View`
    padding: 3px 25px;
    background-color: rgb(203, 210, 214);
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 30px;
    align-self: center;
`
export const SwipeableStatusTitle = styled.Text`
    font-size: 20px;
    font-family: 'Inter-Bold';
    color: rgb(61, 67, 71);
    align-self: center;

`
export const SwipeableStatusDescription = styled.Text`
    font-size: 14px;
    font-family: 'Inter-Regular';
    color: rgb(61, 67, 71);
    align-self: center;
    margin-bottom: 20px;
`

export const SwipeableStatusList = styled.View`
    
`
export const SwipeableItemStatus = styled.View`
    flex-direction: row;
    margin: 20px;
    margin-bottom: 0px;
    align-items: center;
`
export const SwipeableIconStatus = styled.Image`
    width: 56px;
    height: 56px;
    margin-right: 10px;
`
export const SwipeableStatusInfo = styled.View`

`

export const SwipeableStatusItemTitle = styled.Text`
    color: rgb(61, 67, 71);
    font-family: 'Inter-SemiBold';
    font-size: 14px;
`
export const SwipeableStatusItemText = styled.Text`
    color: rgb(167, 176, 184);
    font-family: "Inter-Regular";
    font-size: 12px;
`
export const OrderHeader = styled.View`
    flex-direction: row;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-bottom-color: rgb(240, 243, 245);
    border-bottom-width: 1px;
`