import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { HeaderActionButton } from '../styles';

interface IUserMenuProps {
}

const UserMenu: React.FunctionComponent<IUserMenuProps> = () => {

    const navigation = useNavigation<any>()
    
    return (
        <View style={styles.container}>

            <HeaderActionButton onPress={() => navigation.toggleDrawer()}>
                <AntDesign name='user' color="#000" size={20} />
            </HeaderActionButton>
        </View>

    )
};

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        top: Platform.OS === 'android' ? 70 : 40,
        left: 10,
        flexDirection: 'row',
        borderRadius: 10,
        backgroundColor: '#fff'
    }
})
export default UserMenu;
