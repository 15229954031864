export const StatesData = [
  {
    "id": 11,
    "value": "RO",
    "label": "Rondônia",
    "regiao": {
      "id": 1,
      "value": "N",
      "label": "Norte"
    }
  },
  {
    "id": 12,
    "value": "AC",
    "label": "Acre",
    "regiao": {
      "id": 1,
      "value": "N",
      "label": "Norte"
    }
  },
  {
    "id": 13,
    "value": "AM",
    "label": "Amazonas",
    "regiao": {
      "id": 1,
      "value": "N",
      "label": "Norte"
    }
  },
  {
    "id": 14,
    "value": "RR",
    "label": "Roraima",
    "regiao": {
      "id": 1,
      "value": "N",
      "label": "Norte"
    }
  },
  {
    "id": 15,
    "value": "PA",
    "label": "Pará",
    "regiao": {
      "id": 1,
      "value": "N",
      "label": "Norte"
    }
  },
  {
    "id": 16,
    "value": "AP",
    "label": "Amapá",
    "regiao": {
      "id": 1,
      "value": "N",
      "label": "Norte"
    }
  },
  {
    "id": 17,
    "value": "TO",
    "label": "Tocantins",
    "regiao": {
      "id": 1,
      "value": "N",
      "label": "Norte"
    }
  },
  {
    "id": 21,
    "value": "MA",
    "label": "Maranhão",
    "regiao": {
      "id": 2,
      "value": "NE",
      "label": "Nordeste"
    }
  },
  {
    "id": 22,
    "value": "PI",
    "label": "Piauí",
    "regiao": {
      "id": 2,
      "value": "NE",
      "label": "Nordeste"
    }
  },
  {
    "id": 23,
    "value": "CE",
    "label": "Ceará",
    "regiao": {
      "id": 2,
      "value": "NE",
      "label": "Nordeste"
    }
  },
  {
    "id": 24,
    "value": "RN",
    "label": "Rio Grande do Norte",
    "regiao": {
      "id": 2,
      "value": "NE",
      "label": "Nordeste"
    }
  },
  {
    "id": 25,
    "value": "PB",
    "label": "Paraíba",
    "regiao": {
      "id": 2,
      "value": "NE",
      "label": "Nordeste"
    }
  },
  {
    "id": 26,
    "value": "PE",
    "label": "Pernambuco",
    "regiao": {
      "id": 2,
      "value": "NE",
      "label": "Nordeste"
    }
  },
  {
    "id": 27,
    "value": "AL",
    "label": "Alagoas",
    "regiao": {
      "id": 2,
      "value": "NE",
      "label": "Nordeste"
    }
  },
  {
    "id": 28,
    "value": "SE",
    "label": "Sergipe",
    "regiao": {
      "id": 2,
      "value": "NE",
      "label": "Nordeste"
    }
  },
  {
    "id": 29,
    "value": "BA",
    "label": "Bahia",
    "regiao": {
      "id": 2,
      "value": "NE",
      "label": "Nordeste"
    }
  },
  {
    "id": 31,
    "value": "MG",
    "label": "Minas Gerais",
    "regiao": {
      "id": 3,
      "value": "SE",
      "label": "Sudeste"
    }
  },
  {
    "id": 32,
    "value": "ES",
    "label": "Espírito Santo",
    "regiao": {
      "id": 3,
      "value": "SE",
      "label": "Sudeste"
    }
  },
  {
    "id": 33,
    "value": "RJ",
    "label": "Rio de Janeiro",
    "regiao": {
      "id": 3,
      "value": "SE",
      "label": "Sudeste"
    }
  },
  {
    "id": 35,
    "value": "SP",
    "label": "São Paulo",
    "regiao": {
      "id": 3,
      "value": "SE",
      "label": "Sudeste"
    }
  },
  {
    "id": 41,
    "value": "PR",
    "label": "Paraná",
    "regiao": {
      "id": 4,
      "value": "S",
      "label": "Sul"
    }
  },
  {
    "id": 42,
    "value": "SC",
    "label": "Santa Catarina",
    "regiao": {
      "id": 4,
      "value": "S",
      "label": "Sul"
    }
  },
  {
    "id": 43,
    "value": "RS",
    "label": "Rio Grande do Sul",
    "regiao": {
      "id": 4,
      "value": "S",
      "label": "Sul"
    }
  },
  {
    "id": 50,
    "value": "MS",
    "label": "Mato Grosso do Sul",
    "regiao": {
      "id": 5,
      "value": "CO",
      "label": "Centro-Oeste"
    }
  },
  {
    "id": 51,
    "value": "MT",
    "label": "Mato Grosso",
    "regiao": {
      "id": 5,
      "value": "CO",
      "label": "Centro-Oeste"
    }
  },
  {
    "id": 52,
    "value": "GO",
    "label": "Goiás",
    "regiao": {
      "id": 5,
      "value": "CO",
      "label": "Centro-Oeste"
    }
  },
  {
    "id": 53,
    "value": "DF",
    "label": "Distrito Federal",
    "regiao": {
      "id": 5,
      "value": "CO",
      "label": "Centro-Oeste"
    }
  }
]