import { NavigationProp, useNavigation } from '@react-navigation/native';
import React, { useContext } from 'react';
import { Image, Text, View } from 'react-native';
import { DeliveryTypeButton, DeliveryTypeButtonContainer, DeliveryTypeImage, DeliveryTypes, DeliveryTypeText, DeliveryTypeTitle, SelectDeliveryType } from '../styles';
import acessDenied from '@src/assets/images/General/acess-denied.svg';
import establishmentIcon from "@src/assets/images/General/establishment.png"
import eatinIcon from "@src/assets/images/General/eatin.png"
import deliveryIcon from "@src/assets/images/General/bike.png"
import { ShoppingCartContext } from '@contexts/shoppingCartContext';
import { StoreContext } from '@contexts/storeContext';
import { HomeStackParamList } from 'types';
import { useTheme } from 'styled-components/native';
import Modal from '@components/Modal';
import TableModal from './TableModal';


const BagProductDeliveryType: React.FC = () => {

    //ADD INDOOR ORDER TYPE
    const { dispatchShoppingCart } = useContext(ShoppingCartContext)
    const { storeData } = useContext(StoreContext)
    const { colors: { primary } } = useTheme()
    const [modalVisible, setModalVisible] = React.useState(false);

    const navigation = useNavigation<NavigationProp<HomeStackParamList>>()

    const handleSelectDeliveryType = (deliveryType: string) => {
        dispatchShoppingCart({
            type: 'SELECT_ORDER_TYPE',
            data: deliveryType
        })
    }

    const handleCancelSelectTable = () => {
        dispatchShoppingCart({
            type: 'SET_ORDER_INFO',
            data: {
                mode: "DEFAULT",
            }
        })
        dispatchShoppingCart({
            type: 'SELECT_ORDER_TYPE',
            data: "EATIN"
        })
    }

    const handleAddAddress = () => {
        navigation.navigate('address-list')
    }

    const deliveryTypes = [
        {
            id: 1,
            text: "Retirar no estabelecimento",
            image: establishmentIcon,
            isActive: storeData.allowTakeOutOrder,
            onPress: () => handleSelectDeliveryType("TAKEOUT"),
            orderType: "TAKEOUT"
        },
        {
            id: 2,
            text: "Receber em casa (delivery)",
            image: deliveryIcon,
            isActive: storeData.allowDeliveryOrder,
            onPress: () => handleAddAddress(),
            orderType: "DELIVERY"
        },
        {
            id: 3,
            text: "Consumir no local",
            image: eatinIcon,
            isActive: storeData.allowEatInOrder,
            onPress: () => handleSelectDeliveryType("EATIN"),
            orderType: "EATIN"
        },

    ]

    const checkHaveDeliveryType = deliveryTypes.some(item => item.isActive)

    return (
        <SelectDeliveryType>
            <DeliveryTypeTitle>Como você quer receber seu pedido?</DeliveryTypeTitle>
            {!checkHaveDeliveryType ?
                <View style={{
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingHorizontal: 10
                }}>
                    <Image
                        source={acessDenied}
                        style={{
                            width: 200,
                            height: 240,
                            resizeMode: 'contain',
                            marginVertical: 20
                        }}
                    />
                    <Text style={{
                        fontSize: 16,
                        fontFamily: "Inter-Regular",
                        textAlign: 'center',
                        color: "#bbbbbb",
                    }}>
                        Nenhuma forma de entrega disponível para essa loja
                    </Text>

                </View>
                :
                <DeliveryTypes>
                    {deliveryTypes.map(item => {
                        if (!item.isActive) return

                        if (item.orderType === "EATIN") {
                            return (
                                <Modal
                                    key={item.id}
                                    isVisible={modalVisible}
                                    setIsVisible={setModalVisible}
                                    title='Número da mesa'
                                    component={<TableModal setIsVisible={setModalVisible} />}
                                    onClose={handleCancelSelectTable}
                                >
                                    <DeliveryTypeButtonContainer key={item.id}>
                                        <DeliveryTypeButton onPress={() => setModalVisible(true)}>
                                            <DeliveryTypeImage
                                                resizeMode='contain'
                                                source={item.image}
                                                style={{
                                                    tintColor: primary
                                                }}
                                            />
                                        </DeliveryTypeButton>
                                        <DeliveryTypeText>{item.text}</DeliveryTypeText>
                                    </DeliveryTypeButtonContainer>
                                </Modal>
                            )
                        }

                        return (
                            <DeliveryTypeButtonContainer key={item.id}>
                                <DeliveryTypeButton onPress={item.onPress}>
                                    <DeliveryTypeImage
                                        resizeMode='contain'
                                        source={item.image}
                                        style={{
                                            tintColor: primary
                                        }}
                                    />
                                </DeliveryTypeButton>
                                <DeliveryTypeText>{item.text}</DeliveryTypeText>
                            </DeliveryTypeButtonContainer>
                        )
                    })}
                </DeliveryTypes>
            }
        </SelectDeliveryType>
    )
};

export default BagProductDeliveryType;
