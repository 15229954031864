import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from "react";
import marketPlaceService from "../../services/marketPlaceService";

export interface IMarketplaceContext {
    search: string,
    setSearch: Dispatch<SetStateAction<string>>,
    filteredData: Array<{
        id: number | string,
        storeName: string,
        storeSlug: string,
        logo: string | null,
        allowTakeOutOrder: boolean,
        allowEatInOrder: boolean
    }>,
    filters: Array<any>,
    setFilters: Dispatch<SetStateAction<Array<any>>>,
    sortBy: sortByTypes,
    setSortBy: Dispatch<SetStateAction<sortByTypes>>
}

export type sortByTypes = "DEFAULT" | "DELIVERY_FEE" | "DELIVERY_TIME" | "PRICE"

const MarketplaceContext = createContext<IMarketplaceContext>({} as IMarketplaceContext)

const MarkeplaceProvider: React.FC = ({ children }) => {

    const [data, setData] = useState<IMarketplaceContext["filteredData"]>([])
    const [search, setSearch] = useState<string>('')
    const [filteredData, setFilteredData] = useState<IMarketplaceContext["filteredData"]>(data)
    const [filters, setFilters] = useState<Array<any>>([])
    const [sortBy, setSortBy] = useState<sortByTypes>('DEFAULT')

    useEffect(() => {
        if (sortBy === 'DEFAULT' && filteredData === data) return

        let sortedData = data

        switch (sortBy) {
            case "PRICE": {
                break
            }
            default: {
                console.log(sortedData)
            }
        }


    }, [sortBy])

    useEffect(() => {
        async function getStores() {
            const result = await marketPlaceService.getStores()
            setData(result)
        }
        getStores()
    }, [])

    useEffect(() => {
        if (filters.length === 0) {
            setFilteredData(data)
            return
        }
        let newData = data
        if (!newData?.length) return

        filters.forEach(filter => {
            switch (filter.objectName) {
                case 'allowTakeOutOrder': {
                    newData = newData.filter(x => x.allowTakeOutOrder)
                    break
                }
                case "allowEatInOrder": {
                    newData = newData.filter(x => x.allowEatInOrder)
                    break
                }
                case "search": {
                    newData = newData.filter(x => x.storeName.includes(filter.value))
                    break
                }
                default: {
                    console.log(filter)
                }
            }
        })
        setFilteredData(newData)
    }, [filters, data])

    return (
        <MarketplaceContext.Provider
            value={{
                search,
                setSearch,
                filteredData,
                filters,
                setFilters,
                setSortBy,
                sortBy
            }}
        >
            {children}
        </MarketplaceContext.Provider>
    )

}

export { MarketplaceContext, MarkeplaceProvider }