import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo } from "react";
import { ImageBackground as HeaderBackGround } from "react-native";
import HeaderImageBackGround from '@src/assets/images/General/header-background.png'
import {
    SubHeader,
    StoreLogoImageBackground,
    StoreInfoContainer,
    StoreName,
    StoreAdress,
    StoreDetails,
    DetailButton,
    StoreTimeText,
    StoreIsOpen,
    StoreInformationsButtonText,
    HeaderContainer,
} from '../styles'

import LogoStoreImage from '@src/assets/images/Mocks/small-icon.png'
import { AntDesign } from "@expo/vector-icons";
import SearchInput from "./SearchInput";
import UserMenu from "./UserMenu";
import ModalInfoStore from "./ModalInfoStore";
import { StoreContext } from "@contexts/storeContext";
import IStore from "@src/interfaces/storeInterface";
import ImageWithPlaceholder from "@components/ImageWithPlaceholder";
import Modal from "@components/Modal";

export interface IHeader {
    data: IStore,
    search: string,
    setSearch: Dispatch<SetStateAction<string>>
}

const HeaderComponent: React.FC<IHeader> = ({ data, search, setSearch }: IHeader) => {

    const { storeData, setStoreIsOpen, storeIsOpen } = useContext(StoreContext);

    const storeAddress = () => {
        const address = []

        if (data.streetAddress) address.push(data.streetAddress)
        if (data.numberAddress) address.push(`nº ${data.numberAddress}`)
        if (data.neighborhoodAddress) address.push(data.neighborhoodAddress)
        if (data.complementAddress) address.push(data.complementAddress)
        if (data.state) address.push(data.state)
        if (data.city) address.push(`\n${data.city}`)

        return address.join(', ')
    }

    useEffect(() => {

        const handleIsOpen = () => {

            if (storeData.status !== "ACTIVE") return

            if (!storeData.openingHours) return

            const now = new Date()
            const today: any = now.getDay()

            //@ts-ignore
            const openingHourInDay = storeData?.openingHours[today]

            if (!openingHourInDay || openingHourInDay?.length === 0) return

            for (const hoursId of openingHourInDay) {
                const operationTime = storeData?.openingHours?.operationTimes.find((x) => String(x.id) === String(hoursId))
                // openingHourInDay
                if (!operationTime) continue

                const start = operationTime.start.split(':')

                const end = operationTime.end.split(':')

                const startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), Number(start[0]), Number(start[1]))
                const endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), Number(end[0]), Number(end[1]))

                if (endDate <= startDate) {
                    endDate.setDate(endDate.getDate() + 1)
                }

                if (startDate <= now && endDate >= now) {
                    return true
                }
            }

            return false
        }

        handleIsOpen()

        setStoreIsOpen(handleIsOpen() || false)
    }, [storeData?.openingHours])


    return useMemo(() => {
        return (
            <HeaderContainer>

                <HeaderBackGround
                    style={{
                        justifyContent: 'space-between',
                        width: '100%',
                        height: 227,
                        zIndex: 0
                    }}
                    source={data?.background?.length ? { uri: data?.background } : HeaderImageBackGround}
                >
                    <UserMenu />

                    <SearchInput search={search} setSearch={setSearch} />

                </HeaderBackGround>

                <SubHeader>
                    <StoreInfoContainer>
                        <StoreLogoImageBackground>
                            <ImageWithPlaceholder
                                style={{
                                    width: 104,
                                    height: 104,
                                    borderRadius: 15
                                }}
                                image={data?.logo}
                                placeholder={LogoStoreImage}
                            />
                        </StoreLogoImageBackground>
                        <StoreName>
                            {data?.storeName}
                        </StoreName>
                        <StoreAdress>
                            {data.showStoreAddress && storeAddress()}
                        </StoreAdress>
                    </StoreInfoContainer>
                </SubHeader>
                <StoreDetails>
                        <DetailButton>
                            <AntDesign
                                name="infocirlceo"
                                size={14}
                                color="#535b61"
                                style={{ marginRight: 5 }}
                            />
                            <StoreTimeText>
                                {`${data?.minPreparingTime ? `de ${data?.minPreparingTime} a ${data?.maxPreparingTime} ${data.preparingTimeInterval === 'MINUTES' ? 'minutos' : data.preparingTimeInterval === 'HOURS' ? 'horas' : 'dias'}` : 'Tempo de preparo não definido.'}`}
                            </StoreTimeText>
                        </DetailButton>
                        <DetailButton>
                            <StoreIsOpen
                                numberOfLines={1}
                                isOpen={storeIsOpen}>
                                {storeIsOpen ? "Aberto" : "Fechado"}
                            </StoreIsOpen>
                        </DetailButton>
                        <DetailButton>
                            <Modal title="Informações da loja" component={<ModalInfoStore />}>
                                <StoreInformationsButtonText>Informações</StoreInformationsButtonText>
                            </Modal>
                        </DetailButton>
                    </StoreDetails>
            </HeaderContainer >
        )
    }, [search, storeIsOpen])

}

export default HeaderComponent