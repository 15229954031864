import { StoreContext } from '@contexts/storeContext';
import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';

interface IModalInfoStoreProps {

}

const operationDays: Array<{ day: string, id: number }> = [
  { day: "SEGUNDA", id: 1 },
  { day: "TERÇA", id: 2 },
  { day: "QUARTA", id: 3 },
  { day: "QUINTA", id: 4 },
  { day: "SEXTA", id: 5 },
  { day: "SÁBADO", id: 6 },
  { day: "DOMINGO", id: 0 },
]

const ModalInfoStore: React.FunctionComponent<IModalInfoStoreProps> = (props) => {

  const { storeData } = useContext(StoreContext)
  const openingHours = storeData.openingHours
  const [allowedPaymentMethods, setAllowedPaymentMethods] = useState<Array<string>>([])

  useEffect(() => {
    if (storeData.allowCashPayment) setAllowedPaymentMethods(prev => [...prev, 'Dinheiro'])
    if (storeData.allowCreditPayment) setAllowedPaymentMethods(prev => [...prev, 'Crédito'])
    if (storeData.allowDebitPayment) setAllowedPaymentMethods(prev => [...prev, 'Débito'])
    if(storeData.allowPixPayment) setAllowedPaymentMethods(prev => [...prev, 'Pix'])
  }, [])

  const OperationTimesRender = () => {

    if (!openingHours?.operationTimes?.length) return (
      <Text style={styles.minimumValueText}>
        A loja não possui horários de atendimento
      </Text>
    )

    return operationDays.map(item => {
      //@ts-ignore
      if (!openingHours[item.id]?.length) return

      //@ts-ignore
      const thisDayOperationTimes: Array<number> = openingHours[item.id]

      return (
        <View style={{ flexDirection: 'row', marginVertical: 5, alignItems: 'center' }} key={item.id}>
          <Text style={styles.operationTimesDay}>
            {item.day}
          </Text>
          <View>
            {thisDayOperationTimes.map(hourIntervalId => {
              return (
                <Text key={hourIntervalId} style={styles.operationsTimesText}>
                  {openingHours?.operationTimes.find(hourInterval => hourInterval.id === hourIntervalId)?.start}
                  {' - '}
                  {openingHours?.operationTimes.find(hourInterval => hourInterval.id === hourIntervalId)?.end}
                </Text>
              )
            })}
          </View>
        </View>
      )
    })
  }

  return (
    <View style={styles.Container}>
      <Text style={styles.InfoTitle}>
        Horários de funcionamento
      </Text>

      {OperationTimesRender()}

      <Text style={styles.InfoTitle}>
        Forma de pagamento
      </Text>

      {!allowedPaymentMethods.length ?
        <Text style={styles.minimumValueText}>
          Essa loja não possui formas de pagamento configuradas
        </Text>
        :
        <View>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            {allowedPaymentMethods.map((item) =>
              <Text key={item} style={styles.allowedPaymentMethodsText}>
                {item}
              </Text>
            )}
          </View>
        </View>
      }



      <Text style={[styles.minimumValueText, {marginTop: 40, width: '120%'}]}>
        {`O pedido mínimo para essa loja é R$ ${storeData?.minimumOrderValue || "0,00"}`}
      </Text>

    </View>
  )
};


const styles = StyleSheet.create({
  Container: {
    flex: 1,
    alignItems: 'center'
  },
  InfoTitle: {
    color: 'rgb(61, 67, 71)',
    fontSize: 14,
    fontFamily: 'Inter-SemiBold',
    width: '100%',
    textAlign: 'center',
    marginVertical: 20
  },
  operationTimesDay: {
    color: 'rgb(103, 111, 117)',
    fontSize: 12,
    fontFamily: "Inter-SemiBold",
    marginRight: 15,
  },
  operationsTimesText: {
    color: 'rgb(103, 111, 117)',
    fontSize: 12,
    fontFamily: "Inter-Medium",
  },
  infoSubTitle: {
    color: "rgb(167, 176, 184)",
    fontSize: 13,
    fontFamily: "Inter-Regular"
  },
  orderTypeText: {
    color: "rgb(167, 176, 184)",
    fontSize: 12,
    fontFamily: 'Inter-Regular',
    textAlign: 'center'
  },
  allowedPaymentMethodsText: {
    color: 'rgb(61, 67, 71)',
    fontSize: 12,
    fontFamily: 'Inter-Regular',
    marginHorizontal: 5
  },
  minimumValueText: {
    color: 'rgb(167, 176, 184)',
    fontSize: 12,
    fontFamily: "Inter-Regular",
    textAlign: 'center',
  }
})

export default ModalInfoStore;
