import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { AntDesign } from '@expo/vector-icons';
import { Text } from 'react-native';
import {
    BagProductAmount,
    BagProductConfigContainer,
    BagProductImage,
    BagProductItem,
    BagProductComplementsContainer,
    BagProductItemContent,
    BagProductItemName,
    BagProductItemPriceText,
    BagProductItemTextContainer,
    BagProductsContainer,
    BagProductComplementText,
    BagProductComplementItem,
    BagProductComplementAmount
} from '../styles';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/native';
import ImageBackground from '@components/ImageWithPlaceholder';
import placeholder from '@src/assets/images/Mocks/Hamburguer-bag.png'
import { IShoppingBag } from '@src/interfaces/shoppingBagInterfaces';
import { ShoppingCartContext } from '@contexts/shoppingCartContext';
import { ConvertToBRL } from '@src/utils';

interface IBagProductListProps {
}

const BagProductList: React.FunctionComponent<IBagProductListProps> = (props) => {

    const { shoppingCart, dispatchShoppingCart } = useContext(ShoppingCartContext)

    const navigation = useNavigation()

    useEffect(() => {
        if (shoppingCart.products.length === 0) navigation.goBack()
    }, [shoppingCart])

    const handleRemoveProduct = async (productId: number) => {
        dispatchShoppingCart({ type: 'SHOPPING_BAG/REMOVE_PRODUCT', data: productId })
    }

    return useMemo(() => {
        return (
            <BagProductsContainer>
                {shoppingCart.products.map((item) => {
                    const itemValue = item.price + item?.complementsOnProduct?.reduce((complementTotal: any, complement: any) => {
                        return complementTotal + complement?.options?.reduce((optionTotal: any, option: any) => {
                            return optionTotal + (option?.value * option?.amount)
                        }, 0)
                    }, 0)

                    const productValue = (product: IShoppingBag["products"][0]) => {
                        if(!product.complementsOnProduct?.length) return ((product?.price || 0))

                        const baseValueProduct = product.ignoreBaseValue ? 0 : product.price || 0
                        const complementsValue = product.complementsOnProduct.reduce((totalComplement: number, complement: any) => {

                            if (complement.totalizer === "MAX") {
                                const optionBiggerMontant = [...complement.options].sort((a, b) => (a.value * a.amount) - (b.value * b.amount)).reverse()[0]
                                return totalComplement + (optionBiggerMontant.value * optionBiggerMontant.amount)
                            }
                
                            if (complement.totalizer === "SUM") {
                
                                return totalComplement + complement.options.reduce((totalOption: number, option: any) => totalOption + (option.value * option.amount), 0)
                            }

                            if(complement.totalizer === "MEAN") {
                                const totalOptions = complement.options.reduce((totalOption: number, option: any) => totalOption + (option.value * option.amount), 0)
                                return totalComplement + (totalOptions / complement.options.length)
                            }
                        }, 0)

                        return baseValueProduct + complementsValue
                    }

                    return (
                        <BagProductItem key={item.id}>
                            <BagProductItemContent>
                                <ImageBackground
                                    style={{
                                        height: 45,
                                        width: 45,
                                        borderRadius: 12,
                                        marginRight: 10
                                    }}
                                    placeholder={placeholder}
                                    image={item.picture}
                                />

                                <BagProductItemTextContainer>
                                    <BagProductItemName>{item.name}</BagProductItemName>
                                    <BagProductComplementsContainer>
                                        {item.complementsOnProduct &&
                                            item.complementsOnProduct.map((complement) => {
                                                return complement.options.map((option) => {
                                                    return (
                                                        <BagProductComplementItem key={option.id}>
                                                            <BagProductComplementAmount>
                                                                {option.amount}
                                                            </BagProductComplementAmount>
                                                            <BagProductComplementText>
                                                                {option.name}
                                                            </BagProductComplementText>
                                                        </BagProductComplementItem>
                                                    )
                                                })
                                            })
                                        }
                                    </BagProductComplementsContainer>
                                    <BagProductItemPriceText>{ConvertToBRL(productValue(item) * item.amount)}</BagProductItemPriceText>
                                </BagProductItemTextContainer>
                            </BagProductItemContent>

                            <BagProductConfigContainer>
                                <BagProductAmount>
                                    <Text>x{item.amount}</Text>
                                </BagProductAmount>
                                <TouchableOpacity
                                    onPress={() => handleRemoveProduct(item.id)}
                                >
                                    <AntDesign name='delete' size={16} color="#A7B0B8" />
                                </TouchableOpacity>
                            </BagProductConfigContainer>
                        </BagProductItem>
                    )
                })}
            </BagProductsContainer>
        )
    }, [shoppingCart.products])

};

export default BagProductList;
