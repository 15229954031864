import { AntDesign } from "@expo/vector-icons";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import React, { useMemo } from "react";
import logo from "@src/assets/images/General/logoV1.png";
import { HeaderImage, SearchButton } from "../styles";
import MarketPlaceTopTabs from "./MarketPlaceTopTabs";
import { Flex } from "@src/styles";
import { MarketPlaceStackParamList } from "types";

const MarketPlaceHeader: React.FC = () => {

  const navigation = useNavigation<NavigationProp<MarketPlaceStackParamList>>();

  return (
    <Flex width="100%" column justifyBetween>
      {useMemo(() => {
        return (
          <Flex row width="100%" bg="#fff" justifyBetween py={5} alignCenter bBottom="1px #6e6b6b45">
            <HeaderImage source={logo} resizeMode="contain" />
            <SearchButton onPress={() => navigation.navigate("search")}>
              <AntDesign name="search1" size={23} />
            </SearchButton>
          </Flex>
        );
      }, [])}

      <MarketPlaceTopTabs />
    </Flex>
  );
};

export default MarketPlaceHeader;
