import styled from "styled-components/native";

interface ICleanSearchText {
    active: boolean;
}

export const InputSearchStore = styled.TextInput`
    color: black;
    font-family: 'Inter-Regular';
    margin-left: 10px;
    width: 70%;
`
export const CleanSearchButton = styled.TouchableOpacity`
    flex: 1;
    align-items: center;
    justify-content: center;
`

export const CleanSearchText = styled.Text<ICleanSearchText>`
    color: ${props => props.active ? '#F85628' : '#d4d4d4'};
    font-family: 'Inter-Regular';
`

export const SubTitleSearchScreen = styled.Text`
    color: black;
    font-size: 16px;
    margin: 20px;
    margin-top: 0px;
    font-family: 'Inter-SemiBold';
`

export const SearchList = styled.View`
    flex: 1;
`