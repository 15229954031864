import styled from "styled-components/native";
interface IAddressSelect {
    selected: boolean
}

export const AddressListItem = styled.Pressable<IAddressSelect>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    margin-top: 20px;
    background-color: white;
    width: 90%;
    padding: 14px 20px;
    border-radius: 12px;
    border: 1px solid ${props => props.selected ? props.theme.colors.primary : '#b3b3b368'};
`

export const AddressTypeIcon = styled.View`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
`
export const AddressDescriptionText = styled.Text`
    color: rgb(61, 67, 71);
    font-family: 'Inter-Regular';
    font-size: 14px;
`

export const AddressTitle = styled.Text`
    font-family: 'Inter-SemiBold';
    font-size: 16px;
    color: rgb(33, 37, 41);
`

export const AddressDescriptionContainer = styled.View`
    display: flex;
    flex: 1;
`

export const AddNewAddressButton = styled.TouchableOpacity`
    align-self: center;
    margin: 15px;
`

export const AddNewAddressText = styled.Text`
    font-size: 16px;    
    font-family: 'Inter-SemiBold';
    color: ${props => props.theme.colors.primary};
`

export const AddressOptionsContainer = styled.View`
    display: flex;
    align-self: flex-start;
    align-items: center;
    height: 100%;
`

export const RemoveAddressButton = styled.View`
    
`