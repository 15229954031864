import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { FlatList } from "react-native-gesture-handler";
import { SafeAreaView } from "react-native-safe-area-context";
import HeaderComponent from "./components/HeaderComponent";
import SectionTabs from "./components/SectionTabs";
import {
  Animated,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  ScrollView,
  Text,
  View,
} from "react-native";
import SectionCategory from "./components/SectionCategory";
import { StoreContext } from "@contexts/storeContext";
import LoadingSpinner from "@components/LoadingSpinner";
import BagProduct from "@components/BagProduct/BagProduct";
import IStore from "@src/interfaces/storeInterface";
import { useRoute } from "@react-navigation/native";

const Store: React.FC = () => {
  const sectionRef = useRef<ScrollView>(null);
  const tabRef = useRef<FlatList>(null);

  const { storeData } = useContext(StoreContext);
  const [search, setSearch] = useState<string>("");
  const [categories, setCategories] = useState<IStore["category"]>([]);
  const [atualSection, setAtualSection] = useState<number>(0);
  const [handleSectionNavigation, setHandleSectionNavigation] =
    useState<boolean>(false);

  const itemsYPosition = useRef<Array<number>>([]);
  const filterTimeOut = useRef<any>();

  const onLayout = useCallback(
    (e) => {
      if (itemsYPosition.current.length === categories.length) return;
      itemsYPosition.current = [
        ...itemsYPosition.current,
        e.nativeEvent.layout.y,
      ];
    },
    [categories]
  );

  useEffect(() => {
    setCategories(storeData?.category || []);
  }, [storeData]);

  useEffect(() => {
    clearTimeout(filterTimeOut.current);

    if (!storeData?.category) return;

    filterTimeOut.current = setTimeout(() => {
      const filteredCategories = storeData?.category?.map((item) => {
        const filteredProducts = item.products.filter((product) => {
          return product.name.toUpperCase().includes(search.toUpperCase());
        });
        return {
          ...item,
          products: filteredProducts,
        };
      });

      const filteredTabs = filteredCategories?.filter(
        (x) => x.products.length !== 0
      );
      setCategories(filteredTabs);
      itemsYPosition.current = [];
    }, 500);
  }, [search]);

  const ScrollToSection = (index: any) => {
    setHandleSectionNavigation(true);
    sectionRef.current?.scrollTo({
      x: 0,
      y: itemsYPosition.current[index] - 40,
      animated: true,
    });
    tabRef.current?.scrollToIndex({
      animated: true,
      index: index,
      viewPosition: 0,
    });
    setAtualSection(index);
    setTimeout(() => {
      setHandleSectionNavigation(false);
    }, 500);
  };

  const scrollEvent = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    if (handleSectionNavigation) return;
    let contentOffset = e.nativeEvent.contentOffset.y;

    if (contentOffset < itemsYPosition.current[0]) {
      setAtualSection(0);
      return;
    }

    itemsYPosition.current.forEach((itemPosition, index) => {
      if (
        contentOffset >= itemPosition - 100 &&
        (contentOffset <= itemsYPosition.current[index + 1]
          ? itemsYPosition.current[index + 1] - 100
          : contentOffset)
      ) {
        setAtualSection(index);
      }
    });
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
      {!storeData?.category ? (
        <LoadingSpinner />
      ) : (
        <Animated.ScrollView
          onScroll={scrollEvent}
          showsVerticalScrollIndicator={false}
          stickyHeaderIndices={[1]}
          ref={sectionRef}
          removeClippedSubviews
        >
          <HeaderComponent
            search={search}
            setSearch={setSearch}
            data={storeData}
          />
          {!categories.length ? (
            <View>
              <Text
                style={{
                  marginTop: 100,
                  textAlign: "center",
                  alignSelf: "center",
                  fontSize: 18,
                  color: "#7e7e7eae",
                  fontFamily: "Inter-Regular",
                }}
              >
                A loja não possui cardápio
              </Text>
            </View>
          ) : (
            <SectionTabs
              tabRef={tabRef}
              scrollToSection={ScrollToSection}
              sectionRef={sectionRef}
              data={[...categories].sort((a, b) => a.sort - b.sort)}
              atualSection={atualSection}
              setAtualSection={setAtualSection}
            />
          )}

          {[...categories]
            .sort((a, b) => a.sort - b.sort)
            .map((categorySection) => (
              <SectionCategory
                category={categorySection}
                onLayout={onLayout}
                key={categorySection.id}
              />
            ))}
        </Animated.ScrollView>
      )}
      <BagProduct target={"shoppingbag"} />
    </SafeAreaView>
  );
};

export default Store;
