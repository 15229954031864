import { useLinkProps } from "@react-navigation/native";
import React, { useMemo } from "react";

import placeHolder from "@src/assets/images/Mocks/small-icon.png";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ImageWithPlaceholder from "@components/ImageWithPlaceholder";
import { Flex, ButtonOpacity, TextView } from "@src/styles";

interface IStoreSimple {
  item: {
    storeName: string;
    shipping_price?: number;
    logo: string | null;
    id: number | string;
    storeSlug: string;
    preparing_time?: string;
  };
}

const StoreSimple: React.FC<IStoreSimple> = ({ item }) => {
  const { onPress } = useLinkProps({ to: `/marketplace/${item.storeSlug}` });

  const handleNavigateToStore = async () => {
    await AsyncStorage.setItem("STORE_SLUG", item.storeSlug);
    onPress();
  };

  return useMemo(() => {
    return (
      <Flex column mx={15}>
        <ButtonOpacity
          alignCenter
          justifyBetween
          mb={16}
          p={12}
          bg="#f7f9fa"
          br={18}
          row
          onPress={handleNavigateToStore}
        >
          <ImageWithPlaceholder
            style={{
              width: 74,
              height: 74,
              overflow: "hidden",
              borderRadius: 12,
            }}
            placeholder={placeHolder}
            image={item.logo}
          />
          <Flex
            minHeight="74px"
            justify="flex-start"
            align="flex-start"
            flex={1}
            pl={16}
          >
            <TextView fontFamily="Inter-Bold" fontSize={12} color="#3d4347">
              {item.storeName}
            </TextView>
            {/* <Flex row>
              <StoreDescription>
                R$ {item.shipping_price || 0},00
              </StoreDescription>
            </Flex>
            <Flex row>
              <StoreDescription>{item.preparing_time}</StoreDescription>
            </Flex> */}
          </Flex>
        </ButtonOpacity>
      </Flex>
    );
  }, [item]);
};

export default StoreSimple;
