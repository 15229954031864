import styled from "styled-components/native";

export const ModalView = styled.Modal`

`

export const ModalBackGround = styled.Pressable`
    width: 100%;
    height: 100%;
    background-color: #00000068;
    position: absolute;
    z-index: 1;
`

export const CenteredViewModal = styled.View`
    display: flex;
    position: relative;
    align-items: center;
    justify-content:center;
    flex: 1;
`
export const ModalContainer = styled.View`
    display: flex;
    background-color: white;
    padding: 20px 30px;
    /* padding-bottom: 2%; */
    border-radius: 5px;
    max-width: 95%;
    min-width: 30%;
    position: absolute;
    z-index: 2;
`
export const ModalBody = styled.View`
    padding: 0px 8%;

`

export const ModalHeader = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

export const ModalTitle = styled.Text`
    font-family: 'Inter-Regular';
    font-size: 16px;
    color: #000;
    margin-left: 10%;
`

export const ButtonCloseModal = styled.TouchableOpacity`
    margin-right: 5%;
`