import { Dimensions } from "react-native";

const { height, width } = Dimensions.get('window')

const ConvertToBRL = (v: number | undefined) => {

    let value =
        v?.toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    // .replace('.', ',')

    return `R$ ${value}`
}

export default function convertToMoneyString(value: string | number): string {
    let v = String(value)
    v = v.replace(/\D/g, '')
    v = v.replace(/(\d)(\d{2})$/, "$1,$2")
    v = v.replace(/(?=(\d{3})+(\D))\B/g, ".");
    return v
}

export { height, width, ConvertToBRL, convertToMoneyString }