import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Entypo, MaterialIcons } from '@expo/vector-icons';
import { View } from 'react-native';
import {
    ComplementItemContainer,
    ComplementListContainer,
    ComplementOptionsDescription,
    ComplementTitleText,
    ItemSeparator,
    OptionDescription,
    OptionImage,
    OptionItemContainer,
    OptionName,
    OptionPrice,
    OptionQuantityChange
} from '../styles';
import produce from 'immer';
import { IProductComplements } from '@src/interfaces/complementsOnProductInterface';
import { IProductComplementsStore } from '@src/interfaces/shoppingBagInterfaces';
import { ConvertToBRL } from '@src/utils';
import { ProductButtonDecrement, ProductItemText } from '../../Store/styles';
import { useTheme } from 'styled-components/native';
import placeholder from "@src/assets/images/Mocks/Hamburguer-bag.png";

interface IComplementListProps {
    complements: IProductComplements[]
    setComplementsSelected: Dispatch<SetStateAction<Array<IProductComplementsStore>>>
    complementsOnProduct: Array<IProductComplementsStore>
}

interface IComplementItemList {
    setComplementsSelected: Dispatch<SetStateAction<Array<IProductComplementsStore>>>
    complement: IProductComplements,
    complementsOnProduct: Array<IProductComplementsStore>
}

interface IOptionList {
    option: IProductComplements["complement"]["options"][0],
    setComplementsSelected: Dispatch<SetStateAction<Array<IProductComplementsStore>>>
    complement: IProductComplements
    complementsOnProduct: Array<IProductComplementsStore>
}

const ComplementList: React.FC<IComplementListProps> = ({ complements, setComplementsSelected, complementsOnProduct }) => {

    return (
        <ComplementListContainer>
            {[...complements].sort((a, b) => a.sort - b.sort).map((complement) =>
                <ComplementItem
                    complementsOnProduct={complementsOnProduct}
                    setComplementsSelected={setComplementsSelected}
                    key={complement.id}
                    complement={complement}
                />
            )}
        </ComplementListContainer>
    )
};

const ComplementItem: React.FC<IComplementItemList> = ({ complement, setComplementsSelected, complementsOnProduct }) => {

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [complementRule, setComplementRule] = useState<string>('')
    const { colors: { primary } } = useTheme()

    const activeOptions = complement?.complement.options.filter(option => option.isActive)
    
    useEffect(() => {
        if (complement?.max === 0) {
            setComplementRule('Escolha entre as opções')
            return
        }
        if (complement?.min === complement?.max) {
            setComplementRule(`Escolha ${complement?.max} opções (Obrigatório)`)
            return
        }
        setComplementRule(`Escolha entre ${complement?.min} e ${complement?.max} opções ${complement.min === 0 ? "(Opcional)" : "(Obrigatório)"}`)
    }, [complement])

    const handleOpenComplement = useCallback(() => {
        setIsOpen(!isOpen)
    }, [isOpen])

    return (
        <ComplementItemContainer>
            <ItemSeparator onPress={handleOpenComplement}>
                <View>
                    <ComplementTitleText>
                        {complement?.complement.name}
                    </ComplementTitleText>
                    <ComplementOptionsDescription>
                        {complementRule}
                    </ComplementOptionsDescription>
                </View>
                <Entypo name="chevron-down" size={20} color={primary} />
            </ItemSeparator>
            <View style={{ width: '100%' }}>
                {
                    isOpen &&
                    [...activeOptions].sort((a, b) => a.sort - b.sort).map((option) =>
                        <OptionList
                            complementsOnProduct={complementsOnProduct}
                            complement={complement}
                            setComplementsSelected={setComplementsSelected}
                            key={option.id}
                            option={option}
                        />
                    )
                }
            </View>
        </ComplementItemContainer>
    )
}

const OptionList: React.FC<IOptionList> = ({ option, setComplementsSelected, complement, complementsOnProduct }) => {

    const [optionAmount, setOptionAmount] = useState<number>(0)

    const { colors: { primary } } = useTheme()

    const index = complementsOnProduct.findIndex(item => item.complement.id === complement.id)

    const maxReached = index === -1 ? false : (optionAmount >= option.maximumAmount) || (complementsOnProduct[index]
        .complement.options.reduce((prevopt, curOpt) => prevopt + curOpt.amount, 0) >= complement.max)

    const handleIncrementOption = () => {
        if (maxReached) return
        const newData = {
            id: option.id,
            value: option.value,
            amount: optionAmount + 1,
            name: option.name
        }

        setOptionAmount(x => ++x)

        setComplementsSelected(prevState =>
            produce(prevState, (draft) => {
                const ComplementOnProduct = draft.find((x) => x.complement.id === complement.id)
                if (!ComplementOnProduct) {
                    draft.push({
                        complement: {
                            id: complement.id,
                            options: [newData],
                            idComplementOnProduct: complement.complementId,
                            totalizer: complement.complement.totalizer
                        }
                    })
                    return
                }

                const Option = ComplementOnProduct.complement.options.find((x: any) => x.id === option.id)
                if (!Option) {
                    ComplementOnProduct.complement.options.push(newData)
                    return
                }

                Option.amount = newData.amount
            })
        )

    }

    const handleDecrementOption = () => {
        if (optionAmount <= 0) return

        const amount = optionAmount - 1

        setOptionAmount(amount)

        setComplementsSelected((prevstate) =>
            produce(prevstate, (draft) => {
                const complementSelected = draft.find((x) => x.complement.id === complement.id)
                if (!complementSelected) return

                const optionSelected = complementSelected.complement.options.find((x: any) => x.id === option.id)

                if (!optionSelected) return

                optionSelected.amount = amount

                if (optionSelected.amount === 0) {
                    complementSelected.complement.options = complementSelected.complement.options.filter((x: any) => x.id !== option.id)
                }

                if (complementSelected.complement.options.length === 0) {
                    draft.splice(draft.findIndex((x: any) => x.complement.id === complement.id), 1)
                }
            })
        )
    }

    return (
        <OptionItemContainer>
            <View>
                <OptionImage
                    image={option.picture}
                    placeholder={placeholder}
                />
            </View>
            <View style={{ flex: 2, paddingRight: 5 }}>
                <OptionName>{option.name}</OptionName>
                <OptionDescription>{option.description}</OptionDescription>
            </View>

            <OptionQuantityChange>
                <View>
                    <OptionPrice>{ConvertToBRL(option?.value)}</OptionPrice>
                </View>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <ProductButtonDecrement onPress={handleDecrementOption}>
                        <MaterialIcons
                            name="remove"
                            size={20}
                            color={optionAmount <= 0 ? '#CBD2D6' : primary}
                        />
                    </ProductButtonDecrement>
                    <ProductItemText>{optionAmount}</ProductItemText>
                    <ProductButtonDecrement onPress={handleIncrementOption}>
                        <MaterialIcons
                            name="add"
                            size={20}
                            color={maxReached ? '#CBD2D6' : primary}
                        />
                    </ProductButtonDecrement>
                </View>
            </OptionQuantityChange>
        </OptionItemContainer>
        // <OptionItemContainer>
        //     <OptionName>{option?.name}</OptionName>
        //     <OptionQuantityChange>
        //         <View>
        //             <OptionPrice>{ConvertToBRL(option?.value)}</OptionPrice>
        //             <Text>{option.description}</Text>
        //         </View>
        //         <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: "center" }}>
        //             <ProductButtonDecrement
        //                 onPress={handleDecrementOption}
        //             >
        //                 <MaterialIcons
        //                     name="remove"
        //                     size={20}
        //                     color={optionAmount <= 0 ? '#CBD2D6' : '#F85628'}
        //                 />
        //             </ProductButtonDecrement>
        //             <ProductItemText>{optionAmount}</ProductItemText>
        //             <ProductButtonDecrement
        //                 onPress={handleIncrementOption}
        //             >
        //                 <MaterialIcons
        //                     name="add"
        //                     size={20}
        //                     color={maxReached ? '#CBD2D6' : '#F85628'}
        //                 />
        //             </ProductButtonDecrement>

        //         </View>

        //     </OptionQuantityChange>


        // </OptionItemContainer>
    )
}

export default ComplementList;