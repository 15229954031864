import { StyleProp, ViewStyle } from "react-native";
import styled from "styled-components/native";

export interface IStyledFlex {
  flex?: number;
  center?: boolean;
  alignCenter?: boolean;
  align?: string;
  justify?: string;
  justifyCenter?: boolean;
  justifyEnd?: boolean;
  justifyBetween?: boolean;
  justifyAround?: boolean;
  justifyEvenly?: boolean;
  row?: boolean;
  column?: boolean;
  wrap?: boolean;
  flexDirection?: "row" | "column" | "row-reverse" | "column-reverse";
  flexWrap?: "wrap" | "nowrap" | "wrap-reverse";
  alignSelf?: StyleProp<ViewStyle["alignSelf"]>;
  alignContent?: StyleProp<ViewStyle["alignContent"]>;
  m?: number;
  mr?: number;
  ml?: number;
  mt?: number;
  mb?: number;
  mx?: number;
  my?: number;
  p?: number;
  px?: number;
  py?: number;
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
  width?: number | string;
  height?: number | string;
  size?: number | string;
  bg?: string;

  bRight?: string;
  bTop?: string;
  bBottom?: string;
  bLeft?: string;
  border?: string;
  br?: number;
  bColor?: string;
  bStyle?: StyleProp<ViewStyle["borderStyle"]>;
  bWidth?: number;
  minWidth?: number | string;
  minHeight?: number | string;
}

export const Flex = styled.View<IStyledFlex & StyleProp<ViewStyle>>`
  /* flex */
  ${(props: IStyledFlex) => props.flex && `flex: ${props.flex};`}
  flex-wrap: ${(props: IStyledFlex) => props.flexWrap || "nowrap"};
  flex-direction: ${(props: IStyledFlex) => props.flexDirection || "row"};
  ${(props: IStyledFlex) => props.row && "flex-direction: row;"}
  ${(props: IStyledFlex) => props.column && "flex-direction: column;"}

  /* alignments */
  ${(props: IStyledFlex) => props.align && `align-items: ${props.align};`}
  ${(props: IStyledFlex) =>
    props.justify && `justify-content: ${props.justify};`}
  align-self: ${(props: IStyledFlex) => props.alignSelf || "auto"};
  ${(props: IStyledFlex) =>
    props.center && "align-items: center; justify-content: center;"};
  ${(props: IStyledFlex) => props.wrap && "flex-wrap: wrap;"}
  ${(props: IStyledFlex) => props.alignCenter && "align-items: center;"}
  ${(props: IStyledFlex) => props.justifyCenter && "justify-content: center;"}
  ${(props: IStyledFlex) => props.justifyEnd && "justify-content: flex-end;"}
  ${(props: IStyledFlex) =>
    props.justifyBetween && "justify-content: space-between;"}
  ${(props: IStyledFlex) =>
    props.justifyAround && "justify-content: space-around;"}
  ${(props: IStyledFlex) =>
    props.justifyEvenly && "justify-content: space-evenly;"}


  /* margin */
  ${(props: IStyledFlex) => props.m && `margin: ${props.m}px;`}
  ${(props: IStyledFlex) => props.mr && `margin-right: ${props.mr}px;`}
  ${(props: IStyledFlex) => props.ml && `margin-left: ${props.ml}px;`}
  ${(props: IStyledFlex) => props.mt && `margin-top: ${props.mt}px;`}
  ${(props: IStyledFlex) => props.mb && `margin-bottom: ${props.mb}px;`}

  ${(props: IStyledFlex) =>
    props.mx && `margin-right: ${props.mx}px; margin-left: ${props.mx}px;`};
  ${(props: IStyledFlex) =>
    props.my && `margin-top: ${props.my}px; margin-bottom: ${props.my}px;`};

  /* padding */
  ${(props: IStyledFlex) => props.p && `padding: ${props.p}px;`}
  ${(props: IStyledFlex) => props.pl && `padding-left: ${props.pl}px;`};
  ${(props: IStyledFlex) => props.pr && `padding-right: ${props.pr}px;`};
  ${(props: IStyledFlex) => props.pt && `padding-top: ${props.pt}px;`};
  ${(props: IStyledFlex) => props.pb && `padding-bottom: ${props.pb}px;`};

  ${(props: IStyledFlex) =>
    props.px && `padding-right: ${props.px}px; padding-left: ${props.px}px;`};
  ${(props: IStyledFlex) =>
    props.py && `padding-top: ${props.py}px; padding-bottom: ${props.py}px;`};

  /* measures */
  width: ${(props: IStyledFlex) => props.width || "auto"};
  height: ${(props: IStyledFlex) => props.height || "auto"};
  min-width: ${(props: IStyledFlex) => props.minWidth || "auto"};
  min-height: ${(props: IStyledFlex) => props.minHeight || "auto"};
  ${(props: IStyledFlex) =>
    props.size && `width: ${props.size}; height: ${props.size};`};

  /* colors */
  background-color: ${(props: IStyledFlex) => props.bg || "transparent"};

  /* borders */
  ${(props: IStyledFlex) => {
    const border = props.bRight?.split(" ");
    return (
      border &&
      `border-right-width: ${border[0]}px; border-right-color: ${border[1]};`
    );
  }}

  ${(props: IStyledFlex) => {
    const border = props.bTop?.split(" ");
    return (
      border &&
      `border-top-width: ${border[0]}; border-top-color: ${border[1]};`
    );
  }}

  ${(props: IStyledFlex) => {
    const border = props.bBottom?.split(" ");
    return (
      border &&
      `border-bottom-width: ${border[0]}; border-bottom-color: ${border[1]};`
    );
  }}

  ${(props: IStyledFlex) => {
    const border = props.bLeft?.split(" ");
    return (
      border &&
      `border-left-width: ${border[0]}; border-left-color: ${border[1]};`
    );
  }}

  ${(props: IStyledFlex) => props.bWidth && `border-width: ${props.bWidth}px;`}
  ${(props: IStyledFlex) => props.bColor && `border-color: ${props.bColor};`}
  

  ${(props: IStyledFlex) => props.br && `border-radius: ${props.br}px;`}
`;
