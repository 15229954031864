import React, { useCallback, useContext, useMemo } from "react";
import { FlatList, Pressable } from "react-native";
import { MarketplaceContext } from "@contexts/marketPlaceContext";
import { TabContainer } from "../styles";
import { Flex, TextView } from "@src/styles";

const MarketPlaceTopTabs: React.FC = () => {
  const { setFilters, filters } = useContext(MarketplaceContext);

  const data = [
    // { id: 1, name: 'Ordenar' },
    { id: 2, name: "Pra retirar", objectName: "allowTakeOutOrder" },
    { id: 3, name: "Consumir no local", objectName: "allowEatInOrder" }
    // { id: 3, name: 'Entrega grátis', objectName: 'delivery_fee' }
  ];
  const handleToggleFilterOption = useCallback(
    (filter) => {
      const selectedFilterTabs = filters.find((x) => x.name === filter.name);

      if (!selectedFilterTabs) {
        setFilters([...filters, filter]);
      } else {
        setFilters(filters.filter((x) => x.name !== filter.name));
      }
    },
    [filters]
  );

  const renderItem = ({ index, item }: any) => {
    // if (index === 0) {
    //     return (
    //         <Modal
    //             title="Ordenar por"
    //             component={<MarketPlaceFilterModal />}
    //         >
    //             <TabItem
    //                 style={{ flexDirection: 'row' }}
    //             >
    //                 <Text style={{ color: '#000' }}>
    //                     {item.name}
    //                 </Text>
    //                 <Entypo name="chevron-small-down" size={18} color="black" />
    //             </TabItem>
    //         </Modal >
    //     )
    // }

    return (
      <Pressable onPress={() => handleToggleFilterOption(item)}>
        <Flex
          bColor={filters.find((x) => x.name === item.name) ? "#F85628" : "#00000039"}
          py={3}
          px={10}
          mb={5}
          mr={5}
          br={15}
          bWidth={1}
          alignCenter
          justifyCenter
        >
          <TextView
            color={
              filters.find((x) => x.name === item.name) ? "#F85628" : "#000"
            }
          >
            {item.name}
          </TextView>
        </Flex>
      </Pressable>
    );
  };

  return (
    <TabContainer>
      {useMemo(() => {
        return (
          <FlatList
            data={data}
            renderItem={renderItem}
            horizontal
            style={{
              backgroundColor: "white",
              paddingTop: 10,
              paddingBottom: 10,
            }}
            showsHorizontalScrollIndicator={false}
          />
        );
      }, [filters])}
    </TabContainer>
  );
};

export default MarketPlaceTopTabs;
