import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import BagProductAddContact from './components/BagProductAddContact';
import BagProductDeliveryType from './components/BagProductSelectDeliveryType';
import BagProductList from './components/BagProductList';
import OrderTypeTakeOut from './components/OrderTypeTakeOut';
import OrderTypeDelivery from './components/OrderTypeDelivery';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { ShoppingCartContext } from '@contexts/shoppingCartContext';
import { StoreContext } from '@contexts/storeContext';
import { UserContext } from '@contexts/userContext';
import { HomeStackParamList } from 'types';
import SafeAreaScrollView from '@components/SafeAreaScrollView';
import FooterButton from '@components/LargeButton';
import { ConvertToBRL } from '@src/utils';

const ShoppingBag: React.FC = () => {

  const navigation = useNavigation<NavigationProp<HomeStackParamList>>()

  const { shoppingCart, dispatchShoppingCart, totalValue } = useContext(ShoppingCartContext)
  const { storeData, storeIsOpen } = useContext(StoreContext)
  const { recentAddress } = useContext(UserContext)

  const [addressError, setAddressError] = useState({ hasError: false, message: '' })

  const orderType = shoppingCart.orderType

  const handleRemoveOrderType = () => dispatchShoppingCart({ type: 'SELECT_ORDER_TYPE', data: "" })

  const handleNavigateToContactScreen = () => {
    navigation.navigate('add-contact-data')
  }

  useEffect(() => {
    if (!orderType) return setAddressError({ hasError: true, message: 'Selecione um método de entrega' })
    if (totalValue < (storeData?.minimumOrderValue || 0)) return setAddressError({ hasError: true, message: `O valor mínimo do pedido é ${ConvertToBRL(storeData?.minimumOrderValue || 0)}` })
    if (!storeIsOpen) return setAddressError({ hasError: true, message: 'A loja está fechada' })
    if (!shoppingCart.orderInfo.address && shoppingCart.orderType === "DELIVERY") return setAddressError({ hasError: true, message: 'Você precisa selecionar um endereço para continuar' })

    const result = storeData.neighborhood.find(item => {
      return item.name?.toLowerCase() === shoppingCart?.orderInfo?.address?.neighborhood?.toLowerCase() || ""
    })

    if (shoppingCart.orderType === "DELIVERY" && storeData.deliveryType === "NEIGHBORHOOD" && !result) return setAddressError({ hasError: true, message: 'Essa loja não realiza entregas para esse bairro!\nTente adicionar outro endereço' })
    setAddressError({ hasError: false, message: '' })

  }, [orderType, totalValue, recentAddress, shoppingCart.orderInfo])

  return (
    <View style={{ display: 'flex', flex: 1, backgroundColor: '#fff' }}>

      <SafeAreaScrollView>
        <BagProductList />
        {!orderType &&
          <BagProductDeliveryType />
        }
        {orderType === 'TAKEOUT' || orderType === "EATIN" ?
          <OrderTypeTakeOut
            addressError={addressError}
            setAddressError={setAddressError}
            removeOrderType={handleRemoveOrderType}
          />
          : orderType === 'DELIVERY' &&
          <OrderTypeDelivery
            addressError={addressError}
            setAddressError={setAddressError}
            removeOrderType={handleRemoveOrderType}
          />
        }
        <BagProductAddContact orderType={orderType} />

        <FooterButton
          disabled={addressError.hasError}
          onPress={handleNavigateToContactScreen}
          title='Adicionar dados de contato'
          errorMessage={addressError.message}
        />

      </SafeAreaScrollView>
    </View>
  )
};

export default ShoppingBag;
