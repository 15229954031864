import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Linking, Platform, Text, ToastAndroid, View } from 'react-native';
import OrderProductList from './components/OrderProductList';
import PaymentPrices from './components/PaymentPrices';
import SwipeableBottom from './components/SwipeableBottom';
import {
  OrderHeader,
  PaymentMethodCard,
  PaymentMethodMoment,
  PaymentMethodTitle,
  TopIndicatorProductId
} from './styles';
import { io } from "socket.io-client";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from 'react-native-toast-notifications'
import { StoreContext } from '@contexts/storeContext';
import * as Clipboard from 'expo-clipboard';
import { SOCKET_BASE_URL } from '@src/constants/Constants';
import { IFollowOrderProps, IOrderData } from '@src/interfaces/followOrderInterfaces';
import orderService from '@src/services/orderService';
import ButtonBackForHome from '@src/navigation/components/ButtonBackForHome';
import FooterButton from '@components/LargeButton';
import SafeAreaScrollView from '@components/SafeAreaScrollView';
import SwipeableBottomCard, { finalPosition } from '@components/SwipeableBottomCard';
import { ToastContainer } from '@contexts/toastContext';

const paymentMethods = {
  "CASH": "Dinheiro",
  "CREDIT": "Cartão de crédito",
  "DEBIT": "Cartão de débito",
  "PIX": "Pix"
}

const FollowOrder: React.FunctionComponent<IFollowOrderProps> = ({ ...props }) => {

  const { storeData } = useContext(StoreContext)
  const [orderData, setOrderData] = useState<IOrderData>({} as IOrderData)
  const [orderStatus, setOrderStatus] = useState<IOrderData["status"]>("PENDING")
  const orderId = props.route.params.id
  const [clipboard, setClipboard] = React.useState("")

  const socket = useRef<any>()
  const toast = useToast()


  async function handleSocketOrder() {

    socket.current = io(SOCKET_BASE_URL)

    const phoneCustomer = JSON.parse(await AsyncStorage.getItem('USER_DATA') || '')?.phone
    console.log(orderId, phoneCustomer)


    socket.current.emit('login-webapp', {
      orderId
    })

    socket.current.on('updateOrderStatusWebapp', (data: {
      orderId: number,
      status: IOrderData["status"]
    }) => {
      setOrderStatus(data.status)

      toast.show(`Seu pedido #${orderId} sofreu alterações!`, {
        type: "success",
        placement: "bottom",
        duration: 4000,
        animationType: "slide-in",
      })
      console.log(data)
    })

    socket.current.on('connectedWebapp', () => {
      console.log('connected')
    })

  }
  useEffect(() => {
    if (Platform.OS === "web") {
      const url = new URL(window.location.href)

      url.searchParams.append("order", String(orderId))
    }
    handleSocketOrder()

    return () => {
      socket.current.disconnect()
    }
  }, [])

  useEffect(() => {
    async function getOrderData() {
      if (storeData) {
        const result = await orderService.getOrderData(storeData?.id, orderId)
        if (!result) {
          setOrderData(orderData)
          return
        }
        setOrderData(result)
      }
    }
    getOrderData()
  }, [storeData])

  useEffect(() => {
    if (!orderData) return
    setOrderStatus(orderData.status)
  }, [orderData])


  const copyKeyToClipboard = async () => {
    if (!storeData?.pixKey) return

    await Clipboard.setStringAsync(storeData?.pixKey);
    setClipboard(await Clipboard.getStringAsync())

    switch (Platform.OS) {
      case "android": ToastAndroid.show("Chave copiada para a área de transferência", ToastAndroid.SHORT); break;
      case "ios": Alert.alert("Chave copiada para a área de transferência"); break;
      default: toast.show("Chave copiada para a área de transferência", {
        type: "success",
        placement: "bottom",
        duration: 4000,
        animationType: "slide-in",
      }); break;
    }

  }

  return (
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <OrderHeader>
        <ButtonBackForHome />
        <Text style={{ fontSize: 18 }}>Acompanhe seu pedido</Text>
      </OrderHeader>
      <SafeAreaScrollView style={{ flex: 7 }}>
        <TopIndicatorProductId>Pedido #{orderId}</TopIndicatorProductId>

        <OrderProductList products={orderData?.products} />

        <PaymentMethodCard>
          <View style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            width: '100%'
          }}>
            <PaymentMethodTitle>
              {paymentMethods[orderData?.payments?.methods[0]?.method]}
              {orderData?.payments?.methods[0]?.method === 'PIX' && ` - ${storeData?.pixKey}`}
            </PaymentMethodTitle>

            {
              orderData?.payments?.methods[0]?.method === 'PIX' && <>

                <FooterButton
                  title='Pagar com Pix'
                  style={{ marginBottom: 0 }}
                  onPress={copyKeyToClipboard}
                />
              </>
            }
          </View>
          <PaymentMethodMoment>



            {orderData.payments?.methods[0]?.type === "ONLINE" ?
              'Pagamento online'
              :
              orderData.payments?.methods[0]?.method === 'PIX' ?
                '' : 'Pagamento no local'
            }


          </PaymentMethodMoment>
        </PaymentMethodCard>

        <PaymentPrices total={orderData.total} />
        <FooterButton
          onPress={() => Linking.openURL(`https://api.whatsapp.com/send?phone=55${storeData?.phone}&text=Ol%C3%A1`)}
          title='Falar com o estabelecimento'
        />
      </SafeAreaScrollView>
      <View style={{ height: finalPosition / 2, backgroundColor: 'white' }} />

      <SwipeableBottomCard>
        <SwipeableBottom status={orderStatus} orderType={orderData?.orderType} />
      </SwipeableBottomCard>
    </View>

  )

}
export default FollowOrder