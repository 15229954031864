import styled from "styled-components/native";
import { height } from "@src/utils";

export const ContainerButton = styled.View`
  align-items: center;
  justify-content: space-between;
  position: absolute;

  width: 90%;
  height: 143px;
  background: #fff;

  padding: 16px 20px;
  margin: 20px 20px;
  bottom: 0px;
  border-radius: 8px;
`;

export const AddressLocationText = styled.Text`
  font-style: normal;
  line-height: 20px;
  /* identical to box height, or 125% */

  text-align: center;

  color: #000;
`;

export const ButtonCurrentLocation = styled.TouchableOpacity.attrs({
  activeOpacity: 0.7,
})`
    align-items: center;
    justify-content: center;
  
    width: 100%;
    height: 48px;
    background: ${props => props.theme.colors.primary};
    border-radius: 12px;
  `;

export const ButtonCurrentLocationText = styled.Text`
    font-family: 'Inter-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    text-align: center;

    color: #ffffff;
`;

export const ConsistentMarker = styled.Image`
    position: absolute;
    height: 40px;
    width: 40px;
    align-self: center;
    bottom: 50%;
    z-index: 2;
`