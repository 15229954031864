import { ErrorMessageText } from '@src/screens/Home/AddNewAddress/styles';
import { ErrorMessage } from 'formik';
import React from 'react';

interface IFeedbackProps {
    name: string
}

const Feedback: React.FunctionComponent<IFeedbackProps> = ({ children, name }) => {
    return (
        <>
            <ErrorMessage
                name={name}
                render={message => {
                    return (
                        <ErrorMessageText>
                            {children || message}
                        </ErrorMessageText>
                    )
                }}
            />
        </>

    )
};

export default Feedback;
