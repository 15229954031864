import React from "react";
import { ToastType, useToast } from "react-native-toast-notifications";
import { ToastOptions } from "react-native-toast-notifications/lib/typescript/toast";


let handleToast: ToastType["show"]

const ToastContainer: React.FC = ({ children }) => {

    const toast = useToast()

    handleToast = (message: string | JSX.Element, options: ToastOptions | undefined) => toast.show(message, options)

    return (
        <>
            {children}
        </>
    )
}

export { ToastContainer, handleToast }