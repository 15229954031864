import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Linking, Platform } from "react-native";
import { IStoreContext } from "../../interfaces/complementsOnProductInterface";
import AsyncStorage from "@react-native-async-storage/async-storage";
import IStore from "../../interfaces/storeInterface";
import storeService from "../../services/storeService";
import { ShoppingCartContext } from "../shoppingCartContext";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { STORE_ID } from "../../constants/Constants";
import NotFound from "../../components/NotFound";
import { HomeStackParamList, RootStackParamList } from "types";
import { ThemeProvider } from "styled-components/native";

const StoreContext = createContext({} as IStoreContext);
const StoreProvider: React.FC = ({ children }) => {
  const [storeData, setStoreData] = useState<IStore>({} as IStore);
  const [storeIsOpen, setStoreIsOpen] = useState<boolean>(false);
  const [storeNotFound, setStoreNotFound] = useState<boolean>(false);
  const [deliveryFee, setDeliveryFee] = useState<number>(0);

  const [theme, setTheme] = useState<any>({
    colors: {
      primary: "#F85628",
      secondary: "#FFF7F5",
    },
  });

  const { shoppingCart, totalValue } = useContext(ShoppingCartContext);

  const navigation = useNavigation<
    NavigationProp<HomeStackParamList> & NavigationProp<RootStackParamList>
  >();

  useEffect(() => {
    if (!storeData?.id) return;
    checkVisitDayStore();
  }, [storeData]);

  const checkVisitDayStore = async () => {
    const visitStoreJson = JSON.parse(
      localStorage.getItem("VISIT_DAY") || "{}"
    );
    const todayDate = new Date().toLocaleString("pt-br", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
    if (visitStoreJson[storeData.id as number] === todayDate) return;
    visitStoreJson[storeData.id as number] = todayDate;
    localStorage.setItem("VISIT_DAY", JSON.stringify(visitStoreJson));
    await storeService.addVisualizationOnStore(storeData.id);
  };

  const getSlugByUrl = useCallback(async () => {
    if (Platform.OS !== "web") return;
    const slug =
      window.location.href.split("/")[3]?.split("?")[0] || "404-storeNotFound";

    await AsyncStorage.setItem("STORE_SLUG", slug);
  }, [storeData]);

  useEffect(() => {
    async function getStoreData() {
      await getSlugByUrl();

      const slug = await AsyncStorage.getItem("STORE_SLUG");

      try {
        const response = await storeService.getData(slug || "");
        if (response.status === "BLOCKED") return setStoreNotFound(true);
        setStoreData(response);
        setTheme({
          colors: {
            primary: response.primaryColor,
            secondary: response.secondaryColor,
          },
        });
        await AsyncStorage.setItem(STORE_ID, String(response.id));
      } catch (error) {
        setStoreNotFound(true);
      }

      const url = await Linking.getInitialURL();
      const order = Number(url?.split("?order=")[1]);
      if (order) {
        navigation.navigate("order", { id: order });
      }
    }
    getStoreData();
  }, []);

  useEffect(() => {
    setDeliveryFee(calcDeliveryFee());
  }, [storeData, shoppingCart]);

  const calcDeliveryFee = () => {
    if (
      storeData?.deliveryTaxExemption &&
      totalValue >= storeData?.deliveryTaxExemption
    )
      return 0;

    if (
      shoppingCart.orderType === "TAKEOUT" ||
      shoppingCart.orderType === "EATIN"
    )
      return 0;

    if (storeData?.deliveryType === "FIXED") {
      return storeData?.deliveryValue || 0;
    }
    if (storeData?.deliveryType === "NEIGHBORHOOD") {
      const neighborhood = storeData?.neighborhood?.find(
        (x) =>
          x.name?.toLowerCase() ===
          shoppingCart?.orderInfo?.address?.neighborhood?.toLowerCase()
      );
      return neighborhood?.value || 0;
    }

    return deliveryFee;
  };

  useEffect(() => {
    getSlugByUrl();
  }, [storeData]);

  return (
    <StoreContext.Provider
      value={{
        storeData,
        setStoreData,
        deliveryFee,
        storeIsOpen,
        setStoreIsOpen,
        setDeliveryFee,
      }}
    >
      <ThemeProvider theme={theme}>
        {storeNotFound ? (
          <NotFound
            title="Ops..."
            description="Parece que a loja que você está procurando não existe ou está indisponível."
          />
        ) : (
          children
        )}
      </ThemeProvider>
    </StoreContext.Provider>
  );
};

export { StoreContext, StoreProvider };
