import React from 'react';
import { Platform } from 'react-native';
import MapMarkerNative from './components/MapMarkerNative';
import MapMarkerWeb from './components/MapMarkerWeb';

export interface IMarkerProps {
    latitude: number,
    longitude: number,
    draggable?: boolean,
    onDragEnd?: (coords: any) => Promise<void>;
    onMarkerDragEnd?: (coords: any) => Promise<void>;
}

const Marker: React.FC<IMarkerProps> = ({ children, ...props }) => {

    return (
        Platform.OS !== 'web' ?
            <MapMarkerNative
                {...props}
            />
            :
            <MapMarkerWeb
                {...props}
            />

    )

};

export default Marker;
