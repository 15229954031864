import React from 'react';
import { Field } from 'formik';
import { Platform, TextStyle } from 'react-native';
import { Picker } from '@react-native-community/picker';

interface IInputPickerFormikProps {
    name: string
    type?: string
    placeholder?: string
    style?: TextStyle,
    inputFunction?: (v: any) => any,
    outputFunction?: (v: any) => any,
    mask?: string,
    keyboardType?: any,
    editable?: boolean,
    data: Array<{
        label: string,
        value: string,
        id: number
    }>,
    noDataMessage?: string
}

const InputPickerFormik: React.FunctionComponent<IInputPickerFormikProps> = ({
    name, type, style,
    inputFunction, mask,
    outputFunction, data,
    noDataMessage, ...props }) => {

    return (
        <Field name={name} type={type} {...props}>
            {
                ({
                    field,
                    form,
                    meta
                }: any) => {

                    const handleSelectItem = (item: any) => {
                        form.setFieldValue(name, item);
                    }

                    return (
                        <Picker
                            style={{ borderColor: "transparent", fontSize: 14, height: 40, backgroundColor: "transparent", ...style }}
                            selectedValue={field.value || ''}
                            onValueChange={(item) => handleSelectItem(item)}
                        >
                            {
                                data.length <= 0 ?
                                    <Picker.Item label={noDataMessage || 'Nenhum dado encontrado'} value={''} />
                                    :
                                    [...data].map((item) => {
                                        return (
                                            <Picker.Item key={item.id} label={item.label} value={item.value} />
                                        )
                                    })
                            }
                        </Picker>
                    )
                }
            }
        </Field>
    )
};

export default InputPickerFormik;